import React, { useState, useEffect } from 'react';

import { Switch, Route, Redirect } from 'react-router-dom'
import Nav from '../../components/Organism/Nav.js';
import MobileNav from '../../components/Organism/MobileNav.js';
import Header from '../../components/Molecule/Header'
import Forums from '../forums';
import Inbox from '../inbox';
import Coordinators from '../coordinatorslist'
import Dashboard from '../dashboard'
import ForumPostPage from '../forumpostpage'
import InboxItemPage from '../inboxitempage'
import DailyLogs from '../dailylogs/dailylogs.js'
import HowTo from '../howto';
import Feedback from '../feedback/';
import Loading from '../../components/Organism/Loading.js';
import Chat from '../../screens/chat';
import ForumRepliesPage from '../forumrepliespage';

import DepartmentPrompt from '../../components/Organism/DepartmentPrompt';

const UserMainpage = (props) => {
    // collapse or expand navbar
    const [navStatus, setNavStatus] = useState(true);
    const [promptForDept, setPromptForDept] = useState(false);
    const mobileStatus = props.mobileStatus;

    const mySetNavStatus = () => {
        setNavStatus(!navStatus);
    };

    useEffect(() => {
        if (props.user.department === 'null') {
            setPromptForDept(true);
        }
    }, [props.mobileStatus]);

    // routes for UserMainpage
    return (
        <>
            {promptForDept ?
                <DepartmentPrompt
                    user={props.user}
                    setUser={props.setUser}
                    setPromptForDept={setPromptForDept}
                />
                :
                <div style={{ display: "flex", position: "relative", }}>
                    <Loading />
                    <Nav
                        user={props.user}
                        mobileStatus={mobileStatus}
                        navStatus={navStatus}
                        setNavStatus={mySetNavStatus}
                    />
                    <MobileNav
                        user={props.user}
                        mobileStatus={mobileStatus}
                    />
                    <div style={{ display: "flex", flexDirection: "column", maxWidth: "100vw", flexGrow: "1", position: "relative" }}>
                        <Header />
                        <Switch>
                            <Route exact path="/"><Redirect to={{ pathname: "/home" }} /></Route>
                            <Route exact path="/home"><Dashboard setUser={props.setUser} user={props.user} /></Route>
                            <Route exact path="/forums"><Forums user={props.user} key={"pub"} forumType="public-posts" mobileStatus={mobileStatus} navStatus={navStatus} /></Route>
                            <Route exact path="/inbox"><Inbox user={props.user} key={"inbox"} forumType="user-inbox" mobileStatus={mobileStatus} navStatus={navStatus} /></Route>
                            <Route exact path="/inbox/post/:id"><InboxItemPage type="user" user={props.user} mobileStatus={mobileStatus} navStatus={navStatus} /></Route>
                            <Route exact path="/coordinators"><Coordinators user={props.user} mobileStatus={mobileStatus} navStatus={navStatus} /></Route>
                            <Route exact path="/forums/post/:id"><ForumPostPage user={props.user} forumType="public-posts" mobileStatus={mobileStatus} navStatus={navStatus} /></Route>
                            <Route exact path="/forums/replies/:id"><ForumRepliesPage user={props.user} mobileStatus={mobileStatus}/></Route>
                            <Route exact path="/dailylogs"><DailyLogs user={props.user} mobileStatus={mobileStatus} navStatus={navStatus} /></Route>
                            <Route exact path="/howto"><HowTo /></Route>
                            <Route exact path="/feedback"><Feedback /></Route>
                            <Route exact path="/chat"><Chat user={props.user} navStatus={navStatus} /></Route>
                        </Switch>
                    </div>
                </div>
            }

        </>
    )
}

export default UserMainpage
