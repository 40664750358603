export const COLORS = {
    landingBackground: 'rgb(240, 239, 255)',
    signUpHover: 'rgb(225, 223, 255)',
    postBackground: "rgb(230, 232, 255)",
    hiPandaHeader: "rgb(148, 135, 250)",
    navBarButtons: "rgb(206, 147, 216)",
    postButtons: "rgba(0, 0, 0, 0.05)",
    adminReplyIcon: "rgb(66, 111, 245)",
    loadingScreenBackground: "rgba(245, 245, 245, 0.8)",
    pageButtons: 'rgb(232, 250, 255)',
    postButton: 'rgb(199, 236, 255)'
}