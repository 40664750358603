import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import EmailConfirmation from '../../components/Atom/emailconfirmation.js';
import PrivacyPolicy from '../../components/Atom/PrivacyPolicy.js';
import PhoneHelpLine from "../../components/Atom/phonehelpline.js";
import LoginForm from '../../components/Organism/LoginForm.js';
import PasswordReset from '../../components/Organism/PasswordReset';
import { withRouter } from 'react-router-dom'
import withWidth from '@material-ui/core/withWidth';

const Login = (props) => {
	const classes = useStyles();
	const [isEmailConfirmationOpen, setIsEmailConfirmationOpen] = useState(false);
	const [mobileStatus, setMobileStatus] = useState(false);
	const [emailMessage, setEmailMessage] = useState('accountCreateSuccess');
	const [resetPassOpen, setResetPassOpen] = useState(false);
	const { width } = props;

	const handleCloseEmailConfirmation = () => {
		setIsEmailConfirmationOpen(false);
	};
	const handleForgotPass = () => {
		setResetPassOpen(true);
	};
	const changeRoute = (e) => {
		e.preventDefault();
		props.history.push("/welcome");
	};

	useEffect(() => {
		if (width === 'sm' || width === 'xs') {
			setMobileStatus(true);
		} else {
			setMobileStatus(false);
		}
	}, [mobileStatus, width]);

	return (
		<div className={classes.container}>
			<h1 className={classes.title}>
				Login
			</h1>
			<LoginForm
				setEmailMessage={setEmailMessage}
				setIsEmailConfirmationOpen={setIsEmailConfirmationOpen}
				handleForgotPass={handleForgotPass}
			/>
			<div className={classes.bottomLinks}>
				<a onClick={handleForgotPass} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
					Setup password for existing accounts
				</a>
				<a href='/welcome' onClick={changeRoute}>
					New to HiPanda? Sign up here
				</a>
				<PrivacyPolicy
					currentSchool={props.currentSchool}
				/>
				<PhoneHelpLine />
				<div>
					HiPanda has now uses passwords for logging in. If you have used HiPanda in the past and have not yet chosen a password, click 'Setup password for existing accounts'.
				</div>
			</div>
			<EmailConfirmation
				open={isEmailConfirmationOpen}
				handleClose={handleCloseEmailConfirmation}
				classes={classes.appBar}
				message={emailMessage}
			/>
			<PasswordReset
				resetPassOpen={resetPassOpen}
				setResetPassOpen={setResetPassOpen}
				setIsEmailConfirmationOpen={setIsEmailConfirmationOpen}
				setEmailMessage={setEmailMessage}
			/>
		</div>
	)
};

export default withWidth()(withRouter(Login));