import React, { useEffect, useRef, useState } from 'react';
import { COLORS } from '../../constants/Colors';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MessageBox from '../../components/Organism/MessageBox';
import useFetch from '../../hooks/useFetch';

const Messaging = (props) => {
    /* -------------------------------------------------------- Props for Messaging -----------------------------------------------------------------
    user: userObject
    messageRecipient: obj  
    setMessageRecipient: fxn    
    */
    const [messagesArray, setMessagesArray] = useState([]);
    const [messageContent, setMessageContent] = useState('');
    const [olderMessagesTimestamp, setOlderMessagesTimestamp] = useState(new Date())
    const mostRecentMessageTime = useRef(null);

    const [newMsgFetchArgs, setNewMsgFetchArgs] = useState({});
    const { data: newMsgData, status: newMsgStatus } = useFetch(newMsgFetchArgs);

    const [oldMsgFetchArgs, setOldMsgFetchArgs] = useState({});
    const { data: oldMsgData, status: oldMsgStatus } = useFetch(oldMsgFetchArgs);

    const [sendMsgFetchArgs, setSendMsgFetchArgs] = useState({});
    const { status: sendMsgStatus } = useFetch(sendMsgFetchArgs);

    const fetchNewMessages = () => {
        // this will only fetch newer messages sent by 'recipient'. It doesn't fetch newer messages sent by the current user because 
        // new messages from the current user will be added to messageArray locally
        if (mostRecentMessageTime.current != null) {
            console.log('fetching new message');
            setNewMsgFetchArgs({
                URLquery: '/db/privateMessages/get/newerMessages/',
                body: {
                    userId: props.user.userId,
                    receiverId: props.messageRecipient.userId,
                    mostRecentMessageTime: mostRecentMessageTime.current,
                },
                loadingDisabled: true,
            });
        } 
    };

    const fetchOlderMessages = (oldMsgTimestamp) => {
        setOldMsgFetchArgs({
            URLquery: '/db/privateMessages/get/oldMessages/',
            body: {
                userId: props.user.userId,
                receiverId: props.messageRecipient.userId,
                datePublished: oldMsgTimestamp,
                limit: 3,
            },
            loadingDisabled: true,
        });
    };

    const sendMessage = () => {
        const msgTime = new Date();
        if (messageContent.length > 0) {
            console.log(messageContent);
            setSendMsgFetchArgs({
                URLquery: '/db/privateMessages/create/single/',
                body: {
                    senderId: props.user.userId,
                    receiverId: props.messageRecipient.userId,
                    datePublished: msgTime,
                    content: messageContent,
                },
            });
            const newMsgObject = {
                content: messageContent,
                datePublished: msgTime,
                firstName: props.user.firstName,
                lastName: props.user.lastName,
                senderId: props.user.userId,
            };
            const tempMsgArray = [...messagesArray];
            tempMsgArray.push(newMsgObject);
            setMessagesArray(tempMsgArray);
        }
        setMessageContent('');
    };

    useEffect(() => {
        fetchOlderMessages(olderMessagesTimestamp);
        const fetchInterval = setInterval(fetchNewMessages, 5000);
        return () => clearInterval(fetchInterval);
    }, []);

    useEffect(() => {
        if (oldMsgStatus === 'completed' || oldMsgStatus === 'empty') {
            const tempArray = [...messagesArray];
            if (oldMsgData  && oldMsgData.body) {
                oldMsgData.body.forEach(msgObject => {
                    tempArray.unshift(msgObject);
                });
                setMessagesArray(tempArray);
                mostRecentMessageTime.current = tempArray[tempArray.length - 1].datePublished;
            }
            setOldMsgFetchArgs({});
            // update OlderMsgTimestmap
        }
        if (newMsgStatus === 'completed' || newMsgStatus === 'empty') {
            const tempArray = [...messagesArray];
            if (newMsgData && newMsgData.body) {
                console.log(newMsgData);
                newMsgData.body.forEach(msgObject => {
                    tempArray.push(msgObject);
                });
                setMessagesArray(tempArray);
                mostRecentMessageTime.current = tempArray[tempArray.length - 1].datePublished;
            }
            setNewMsgFetchArgs({});
        }
        if (sendMsgStatus === 'completed') {
            setSendMsgFetchArgs({});
        }
    }, [oldMsgStatus, newMsgStatus, sendMsgStatus]);

    return (
        <div>
            <div
                onClick={() => props.setMessageRecipient({})}
                style={{ textAlign: 'center', cursor: 'pointer' }}
            >
                Back
            </div>
            <MessageBox
                user={props.user}
                messagesArray={messagesArray}
                messageRecipient={props.messageRecipient}
            />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <TextField
                    required
                    variant="outlined"
                    onChange={e => setMessageContent(e.target.value)}
                    value={messageContent}
                    style={{width:'50%'}}
                />
                <Button
                    variant='contained'
                    disableElevation
                    onClick={sendMessage}
                    style={{ backgroundColor: COLORS.hiPandaHeader, color: 'white' }}
                >
                    Send
                </Button>
            </div>
        </div>
    )
};

export default withRouter(Messaging);