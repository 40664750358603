import React, { useEffect, useState } from 'react';
import { COLORS } from '../../constants/Colors';
import { makeStyles } from '@material-ui/core/styles';

import useFetch from '../../hooks/useFetch.js';

import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import Button from '@material-ui/core/Button';


const DailyLog = (props) => {
        /* -------------------------------------------------------- Props for DailyLog -----------------------------------------------------------------

    PROPS:      
        user: obj, contians data representing the authenticated user
        setUser: fxn that changes state of user
    */

    const classes = useStyles();

    const [submittedDailyLog, setSubmittedDailyLog] = useState('initial')
    const [dailyStatus, setDailyStatus] = useState(null);

    const [fetchArgs, setFetchArgs] = useState({});
    const {status: DBStatus, data: DBData} = useFetch(fetchArgs);

    const checkIfSubmittedToday = () => {
        if (DBStatus === 'completed' && DBData) {
            setSubmittedDailyLog(true);
        } else if (DBStatus === 'empty') {
            setSubmittedDailyLog(false);
        }
    }

    const submitDailyLog = () => {
        if(dailyStatus !== null) {

            setFetchArgs({URLquery: '/db/dailyLogs/create/single/', 
                body: {
                    dailyStatus: dailyStatus,
                    userId: props.user.userId,
                    timeStamp: new Date()
                }
            });
            setSubmittedDailyLog(true);
        }
    }

    const getFeelingButtons = () => {
        const feelingIconMap = [
            null, 
            SentimentVeryDissatisfiedIcon, 
            SentimentDissatisfiedIcon, 
            SentimentSatisfiedIcon,
            SentimentSatisfiedAltIcon,
            SentimentVerySatisfiedIcon
        ];
    
        let buttonsArray = [];
        for (let i =1; i <= 5; i++) {
            let IconComponent = feelingIconMap[i];
            let button = 
            <Button onClick={() => setDailyStatus(i)} className={classes.feelingButton} key={i}>
                <IconComponent className={classes.feelingIcon} style={getButtonStyle(i)}/>
            </Button>

            buttonsArray.push(button);
        }
        return buttonsArray
    }

    // styling for the dailyStatus buttons, the selected value (between 1-5) has a different color
    const getButtonStyle = (val) => {
        if(val === dailyStatus) {
            return { color: COLORS.hiPandaHeader}
        } 
    }

    useEffect(() => {
        checkIfSubmittedToday()
    }, [DBStatus]);

    useEffect(() => {
        setFetchArgs({URLquery: '/db/dailyLogs/get/single', 
            body:{
                userId: props.user.userId,
                timeStamp: new Date()
            }
        });
    }, []);
    
    if (submittedDailyLog === true) {
        return <div className={classes.submittedLog}> 
            Thank you for your response! 
        </div>
    } else if (submittedDailyLog === 'initial') {
        return <div className={classes.submittedLog}> 
        </div>
    } else { 
        return (
            <div className={classes.dailyLogDiv}>
                <p className={classes.question}>
                    How are you feeling today?
                </p>
                <div value={dailyStatus} exclusive={"true"}
                    className={classes.feelingStatus}>
                    {getFeelingButtons()}
                </div>
                <Button onClick={submitDailyLog} className={classes.submitDailyLog}>
                    Submit
                </Button>
            </div>
        )
    }

}

const useStyles = makeStyles(() => ({ 
    container: {
        display:'flex', 
        flexWrap:'wrap',
        justifyContent:"center", 
        margin: "0 auto",
        width: "calc(400px + 30vw)",
        maxWidth:"100%", 
    },
    submittedLog: {
        display:"flex", 
        alignItems:"center", 
        justifyContent:"center",
        marginTop:"20px", 
        marginBottom:"10px", 
        padding: "0px 10px",
        borderRadius:"10px", 
        backgroundColor:"white", 
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", 
        height:"100px", 
        textAlign:"center", 
        fontSize:"30px",
        color: COLORS.hiPandaHeader,
    }, 
    question: {
        position:"absolute", 
        left:"20px", 
        top:"-7px", 
        fontSize:"20px", 
        color: COLORS.hiPandaHeader
    },
    dailyLogDiv: {
        position:"relative", 
        display:"flex",
        justifyContent:"center",
        margin: "20px 25px 0px",
        width:"calc(1000px)", 
        minWidth:"200px",
        height:"calc(145px + 1vw)", 
        backgroundColor:"white", 
        borderRadius:"30px", 
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    feelingStatus: {
        display:"flex", 
        position:"relative", 
        marginTop:"calc(48px + 0.7vw)", 
        marginLeft:"auto",
        marginRight:"auto", 
        height:"50px", 
        width:"80%",
        minWidth:"200px",
    },
    feelingButton: {
        color:"black", 
        borderRadius:"50px", 
        backgroundColor:"white", 
        padding:"0px", 
        border:"none", 
        margin:"0 auto", 
        minWidth:"10px", 
        minHeight:"10px",
    },
    feelingIcon: {
        fontSize:"calc(200% + 1vw)", 
        margin:"auto"
    },
    submitDailyLog: {
        position:"absolute", 
        right:"30px", 
        bottom:"12px", 
        height:"35px", 
        width:"2vw", 
        borderRadius:"30px",
        textTransform:"none", 
        backgroundColor:"#63b4ff", 
        padding: "0 17px",
        color:"white",
    },
}));

export default DailyLog;