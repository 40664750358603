import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 auto",
    },
    panda: {
        maxWidth: "50%",
        maxHeight: "50vh",
    },
    text: {
        marginTop: "5em",
        fontSize: "2em",
    }

}));

export default useStyles;