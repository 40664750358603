import React from 'react';
import { COLORS } from '../../constants/Colors';

import PhoneIcon from '@material-ui/icons/Phone';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';

import { SchoolContext } from '../../contexts/CurrentSchoolContext.js';
import { schools } from '../../constants/Constants.js';


const PhoneHelpLine = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const schoolCont = React.useContext(SchoolContext);
    const helpLineURL = schools[schoolCont.school].helplineLink
  
    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    
    return (
      <div onClick={() => {window.location.href = helpLineURL}}>
        <button
          className={classes.helplineButton}
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <PhoneIcon fontSize="large"/>
        </button>
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          disableRestoreFocus
        >
          <h1 className={classes.helplinePopover}>
            24/7 Helpline
          </h1>
        </Popover>
      </div>
    );
  }

  const useStyles = makeStyles((theme) => ({
    helplineButton: {
      width: "60px",
      height: "60px",
      backgroundColor: COLORS.landingBackground,
      opacity: "0.75",
      border: "none",
      borderRadius:"10px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      cursor: "pointer",
      zIndex: "9",
      '&:hover': {
        backgroundColor: COLORS.hiPandaHeader,
        color: "white",
      }
    },
    helplinePopover: {
      padding: "0px 10px",
      fontSize: "15px",
    },
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
  }));


  export default PhoneHelpLine;