import React from 'react'

import {withRouter} from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import InboxReply from './InboxReply.js';

const InboxRepliesList = (props) => {
    // This component is the main component of InboxRepliesList. it contians the parent post and the replies after
    /* -------------------------------------------------------- Props for InboxRepliesList -----------------------------------------------------------------

    PROPS:      
        mainPostDoc: obj, contains data from the database for the parent post
        user: obj ,contains the current users data
        mobileStatus
        navStatus
    */
    const classes = useStyles();

    // function for producing the inbox component
    const renderInboxPosts = () => {
        if(props.privateRepliesData.body) { // first check to see if data is retrieved from firebase
            // array for replies
            let replyArray = [];

            // names of the admin and the user
            let replies = props.privateRepliesData.body;
            for(let reply of replies) {
                let temp;
                const userName = `${reply.firstName} ${reply.lastName}`;

                if(reply.userType === "student") { // if the reply is of type "user"
                    temp = 
                        <InboxReply 
                            mobileStatus={props.mobileStatus}
                            replyType={"student"}
                            authorName={userName}
                            replyDoc={reply}
                            key={reply.id}
                        />
                } else { // if the reply is of type "admin" or 'coordinator'
                    temp = 
                        <InboxReply
                            mobileStatus={props.mobileStatus}
                            replyType={"admin"}
                            authorName={userName}
                            replyDoc={reply}
                            key={reply.id}
                        />
                }
                replyArray.push(temp);
            }

            // for each doc in replies, produce the following react component and push it into the replyArray
            return(
                <>
                    {replyArray}
                </>
            )
        }
    }

    return (
        <div className={classes.replyListContainer}>
            {renderInboxPosts()}
        </div>
    )
}

const useStyles = makeStyles(() => ({
    replyListContainer: {
        display: "flex",
        flexDirection: "column"
    }
}))

export default withRouter(InboxRepliesList);