//Routes to avoid after user has signed in 

import React from 'react'
import { Redirect, Route } from 'react-router-dom';

const SignedOutRoute = ({component: Component, user, ...rest}) => {

    // if the user prop is null, then proceed to the path, 
    // else, redirect to /home
    return (
        <Route 
            {...rest}
            render= { props => {
                if(user !== null) {
                    return <Redirect to={
                        {
                            pathname:"/home"
                        }
                    }/>
                } else {
                    return <Component/>
                }
            }}
        />
    )
}

export default SignedOutRoute
