import React, { useState, useEffect } from 'react'
import useFetch from '../../hooks/useFetch.js';

import { COLORS } from '../../constants/Colors.js';
import { withRouter } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import Pushpin from '../Atom/Pushpin';

import ForumItemButtons from './ForumItemButtons.js';



// Each invidual forum post under /forums
const ForumItem = (props) => {
    /* -------------------------------------------------------- Props for ForumItem -----------------------------------------------------------------

    PROPS:      
        mobileStatus: bool
        docdata: obj, contains data from the doc retrieved from the public posts collection
        user: obj, represents user data
        history: comes from a library
        pinned: bool, if true a pin icon will show up
    */
    const classes = useStyles(props.mobileStatus);

    // doc acquired from prop
    const doc = props.docdata;

    // information about the post 
    const [upvote, setUpvote] = useState(null);
    const [upvoteCount, setUpvoteCount] = useState(null);

    const [fetchArgs, setFetchArgs] = useState({});
    const { status: dbCallStatus, data: dbCallData, error: dbCallError } = useFetch(fetchArgs);

    // function for changing upvotes
    const changeUpvote = async (e) => {
        e.stopPropagation();
        if (upvote) {
            // if the user has already upvoted, then undo the upvote
            setFetchArgs({
                URLquery: '/db/upvotes/delete/deleteByPostIdUserId/',
                body: {
                    userId: props.user.userId,
                    postId: doc.id
                }
            })

            // update the upvote & upvoteCount states
            setUpvote(!upvote);
            setUpvoteCount(Number(upvoteCount) - 1);
        } else {
            // if the user hasn't upvoted, then add the upvote
            setFetchArgs({
                URLquery: '/db/upvotes/create/single/',
                body: {
                    userId: props.user.userId,
                    postId: doc.id
                }
            })

            // update the upvote & upvoteCount states
            setUpvote(!upvote);
            setUpvoteCount(Number(upvoteCount) + 1);
        }
    }

    // function executed when user clicks on a post (redirects to individual post page)
    const changeRoute = (e) => {
        e.stopPropagation();
        if (window.getSelection().toString().length === 0) {
            if (props.user.userType !== 'student') {
                props.history.push(`/coordinator_forums/post/${doc.postUrlPath}`)
            } else {
                props.history.push(`/forums/post/${doc.postUrlPath}`)
            }
        }
    }

    useEffect(() => {
        // get from the upvote table
        setUpvote(doc.likedByUser);
        setUpvoteCount(doc.totalLikes);
    }, [])

    return (
        <div className={classes.forumItemContainer} onClick={changeRoute}>
            <div className={classes.forumItemHeader}>
                <Avatar className={classes.avatar} alt={doc.firstName} src="/static/images/avatar/1.jpg" />
                <div style={{ flex: 1 }}>
                    <div className={classes.authorName}>
                        {`${doc.firstName}`}
                    </div>
                    <div className={classes.publishDate}>
                        {new Date(doc.dateVerified).toString().slice(4, 15)}
                    </div>
                </div>
                <div className={classes.adminReplyIcon}>
                    {props.pinned ? <Pushpin /> : <></>}
                    {doc.hasReply ? <AnnouncementIcon /> : <></>}
                </div>
            </div>
            <div className={classes.postContent}>
                <div className={classes.postTitle}>
                    {doc.title}
                </div>
                <div className={classes.postText}>
                    {doc.content}
                </div>
            </div>
            <div className={classes.footerButtons}>
                <ForumItemButtons
                    user={props.user}
                    mobileStatus={props.mobileStatus}
                    doc={doc}
                    changeUpvote={changeUpvote}
                    upvote={upvote}
                    upvoteCount={upvoteCount}
                />
            </div>
        </div>
    )
}

const useStyles = makeStyles(() => (
    {
        avatar: {
            width: "calc(45px + 1vw)",
            height: "calc(45px + 1vw)",
            backgroundColor: "white",
            color: "black",
        },
        adminReplyIcon: {
            color: COLORS.adminReplyIcon,
        },
        authorName: {
            fontSize: "clamp(18px, 2vw, 22px)",
        },
        footerButtons: {
            display: "flex",
            alignItems: "center",
            flex: 1
        },
        forumItemContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            position: "relative",
            padding: 10,
            margin: "15px auto",
            maxWidth: "95vw",
            backgroundColor: COLORS.postBackground,
            borderRadius: 5,
            boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19)",
            cursor: "pointer"
        },
        forumItemHeader: {
            display: "flex",
            flex: 1,
            alignItems: "center",
            gap: "10px",
        },
        postContent: {
            flex: 2,
            display: "flex",
            flexDirection: "column",
            gap: "10px"
        },
        postText: {
            fontSize: "clamp(12px, 2vw, 14px)",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        },
        postTitle: {
            fontSize: "clamp(16px, 2vw, 22px)"
        },
        publishDate: {
            fontSize: "clamp(17px, 2vw, 22px)",
        },
    }
))

export default withRouter(ForumItem)