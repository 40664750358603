import React from 'react';
import useStyles from './styles';
import STRINGS from '../../constants/Strings.js';

const HowTo = () => {
    const classes = useStyles();

    const howToStepsComponents = () => {
        let arrayOfComponents = [];
        STRINGS.howTo.steps.forEach((step, index) => {
            let component = 
                <div className={classes.stepSection} key={index}>
                    <h1 className={classes.stepTitle}>
                        <step.icon className={classes.stepIcons}/>
                        {step.title}
                    </h1>
                    <p>{step.text}</p>
                </div>;

            arrayOfComponents.push(component);
        })
        return arrayOfComponents
    }
    return (
        <div className={classes.howToContainer}>
            <h1 className={classes.welcomeHeader}>
                Welcome to <span className={classes.stepTitle}>HiPanda!</span>
            </h1>
            {howToStepsComponents()}
        </div>
    );
}

export default HowTo;