import React, { useState, useEffect } from 'react';
import ChatMessage from '../Molecule/ChatMessage';

const MessageBox = (props) => {
    /* -------------------------------------------------------- Props for MessageBox -----------------------------------------------------------------
    user: userObj
    messageRecipient: obj
    messagesArray: array of msgObjects
    */

    const [messages, setMessages] = useState([])

    const renderMessages = (messageDataArray) => {
        const tempMsgArray = [];
        messageDataArray.forEach((msgObj, index) => {
            const tempComponent =
                <ChatMessage
                    key={index}
                    msgObj={msgObj}
                    user={props.user}
                />
            tempMsgArray.push(tempComponent);
        });
        setMessages(tempMsgArray);
    };

    useEffect(() => {
        renderMessages(props.messagesArray);
    }, [props.messagesArray]);

    return (
        <div
            onClick={() => console.log(props.messageRecipient)}
            style={{ display: 'flex', flexDirection: 'column', margin: '0px auto', paddingBottom: '20px', width: '50%', minWidth: '320px' }}
        >
            <h3 style={{ textAlign: 'center' }}>Messages with {props.messageRecipient.firstName}</h3>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                {messages}
            </div>
        </div>
    )
}

export default MessageBox;
