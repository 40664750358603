import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VerifyIcon from '@material-ui/icons/VerifiedUser';
import { COLORS } from '../../constants/Colors';

const useStyles = makeStyles(() => ({
    changePostButton: {
        padding: "0px",
        height: "35px",
        width: "35px",
        minWidth: "0px",
        minHeight: "0px",
        borderStyle: "none",
        marginLeft: "7px",
        marginRight: "7px",
        borderRadius: "5px",
        backgroundColor: "rgba(0, 0, 0, 0.05)",
    },
}));

const PostChangeButton = (props) => {
    /* -------------------------------------------------------- Props for PrivacyPolicy -----------------------------------------------------------------
    PROPS: 
    onClick: function
    type: string
    */
    const classes = useStyles();

    const typeToIconMap = {
        'delete': DeleteIcon,
        'edit': EditIcon,
        'verify': VerifyIcon
    };

    const SpecificIcon = typeToIconMap[props.type]

    return (
        <Button onClick={props.onClick} className={classes.changePostButton}>
            <SpecificIcon style={{ color: COLORS.adminReplyIcon }} />
        </Button>
    );
}

export default PostChangeButton;