import React from 'react';
import { COLORS } from './Colors';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import ForumIcon from '@material-ui/icons/Forum';
import MailIcon from '@material-ui/icons/Mail';
import HelpIcon from '@material-ui/icons/Help';
import PeopleIcon from '@material-ui/icons/People';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg';
import SchoolIcon from '@material-ui/icons/School'
import DashboardIcon from '@material-ui/icons/Dashboard';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import MoodIcon from '@material-ui/icons/Mood';
import VerifyIcon from '@material-ui/icons/VerifiedUser';

const STRINGS = {
    createForumPost: {
        publicPost: "Thank you for posting! Your post will be published once an admin reviews and verifies it.",
        privateMessage: "Your message has been sent! The coordinator shall respond once they become available."
    },
    adminDashboard: {
        buttonLinks: [
            {
                label: "Public Forums",
                icon: ForumIcon,
                link: "/coordinator_forums"
            },
            {
                label: "Inbox",
                icon: MailIcon,
                link: "/coordinator_inbox"
            }
        ]
    },
    userDashboard: {
        submitDailyLogMessage: "Thank you for your response!",
        buttonLinks: [
            {
                label: "Visit the Public Forums",
                icon: ForumIcon,
                link: "/forums"
            },
            {
                label: "Contact Our Coordinators",
                icon: InsertInvitationIcon,
                link: "/coordinators"
            },
            {
                label: "View your inbox",
                icon: MailIcon,
                link: "/inbox"
            },
            {
                label: "How to use HiPanda",
                icon: HelpIcon,
                link: "/howto"
            },

        ],
    },
    howTo: {
        steps: [
            {
                icon: SentimentSatisfiedIcon,
                title: "Tell Us How You Feel",
                text: "Select the emoticon that best represents your overall mood each day. This tool can provide insightful metrics for Wellness Coordinators to help them understand how students from different faculties and departments are feeling during different times of the school year.",
            },
            {
                icon: PeopleIcon,
                title: "View Coordinators & Book Appointments",
                text: "On the Wellness Coordinators page, you can view all of the coordinators and have the option to either send them a private message or book an appointment. ",
            },
            {
                icon: MailIcon,
                title: "Your Inbox",
                text: "You can view all your messages and responses with Wellness Coordinators in your inbox.",
            },
            {
                icon: ForumIcon,
                title: "Public Forums",
                text: "All students can ask questions or voice any concerns regarding wellness on this public forum, with the option of posting anonymously. Each post is verified before being posted on the forum and students have the option to upvote posts they are interested in. Posts with the most votes will appear at the top of the forum, allowing coordinators to view common questions and concerns. Only Wellness Coordinators can reply to the posts and the answers can be viewed by everyone in the forum.",
            },
            {
                icon: PermPhoneMsgIcon,
                title: "Mental Health Resources",
                text: "Access additional mental health resources conveniently from your dashboard, as well contact information to a 24/7 helpline if you require immediate assistance.",
            },
        ],
    },
    emailConfirmation: {
        accountCreateSuccess: "Account successfully created. Please check your email and follow the verification link.",
        localhost: "Please check your email to log in for development!",
        passwordResetSent: 'We have received your request to setup a new password. Check your inbox for further instructions.',
        errorExistingEmail: "That email address has already been registered. We've sent you an email to login.",
        errorWrongSchool: "That is an invalid school email address.",
        errorOther: "An error has occured, please retry.",
        errorNotRegistered: <span>That email has not been registered.
                    <br /><a href="/welcome"> Please sign up here.</a>
        </span>,
    },
    feedback: {
        emailAddress: 'support@hiride.ca',
        mainText: 'Hello, send us your feedback to ',
    },
    forumsDisclaimer: {
        mainText: "While it is our hope that the Wellness Forum will help improve access to timely health information, please know that the Forum is monitored sporadically. We will endeavour to respond to posts with 2-3 business days. If you need more immediate support, please use the urgent supports found at ",
        helpLink: "https://uwaterloo.ca/campus-wellness/urgent-help-and-emergency-contacts",
        helpLinkText: "Campus Wellness Urgent Supports.",
    },
    navbar: {
        upper: {
            admin: [
                {
                    label: "Dashboard",
                    route: "/coordinator_dashboard",
                    icon: <DashboardIcon style={{ color: COLORS.navBarButtons }} />
                },
                {
                    label: "Inbox",
                    route: "/coordinator_inbox",
                    icon: <MailIcon style={{ color: COLORS.navBarButtons }} />
                },
                {
                    label: "Forums",
                    route: "/coordinator_forums",
                    icon: <ChatBubbleIcon style={{ color: COLORS.navBarButtons }} />
                },
                {
                    label: "Verify Posts",
                    route: "/verify_posts",
                    icon: <VerifyIcon style={{ color: COLORS.navBarButtons }} />
                },
                {
                    label: "Verify Replies",
                    route: "/verify_replies",
                    icon: <VerifyIcon style={{ color: COLORS.navBarButtons }} />
                },
                {
                    label: "Help",
                    route: "/howto",
                    icon: <HelpIcon style={{ color: COLORS.navBarButtons }} />
                },
                {
                    label: 'Chat',
                    route: 'coordinator_chat',
                }
            ],
            student: [
                {
                    label: "Dashboard",
                    route: "/home",
                    icon: <DashboardIcon style={{ color: COLORS.navBarButtons }} />
                },
                {
                    label: "Inbox",
                    route: "/inbox",
                    icon: <MailIcon style={{ color: COLORS.navBarButtons }} />
                },
                {
                    label: "Forums",
                    route: "/forums",
                    icon: <ChatBubbleIcon style={{ color: COLORS.navBarButtons }} />
                },
                {
                    label: "Coordinators",
                    route: "/coordinators",
                    icon: <SchoolIcon style={{ color: COLORS.navBarButtons }} />
                },
                {
                    label: "Feedback",
                    route: "/feedback",
                    icon: <MoodIcon style={{ color: COLORS.navBarButtons }} />
                },
                {
                    label: "Help",
                    route: "/howto",
                    icon: <HelpIcon style={{ color: COLORS.navBarButtons }} />
                },
                {
                    label: 'Chat',
                    route: 'chat',
                }
            ]
        },
        mobile: {
            admin: [
                {
                    label: "Home",
                    route: "/coordinator_dashboard",
                    icon: <DashboardIcon />
                },
                {
                    label: "Inbox",
                    route: "/coordinator_inbox",
                    icon: <MailIcon />,
                },
                {
                    label: "Forums",
                    route: "/coordinator_forums",
                    icon: <ChatBubbleIcon />
                },
                {
                    label: "Verify Posts",
                    route: "/verify_posts",
                    icon: <VerifyIcon />
                },
                {
                    label: "Verify Replies",
                    route: "/verify_replies",
                    icon: <VerifyIcon />
                },
            ],
            student: [
                {
                    label: "Home",
                    route: "/home",
                    icon: <DashboardIcon />
                },
                {
                    label: "Inbox",
                    route: "/inbox",
                    icon: <MailIcon />
                },
                {
                    label: "Forums",
                    route: "/forums",
                    icon: <ChatBubbleIcon />
                },
                {
                    label: "Coordinators",
                    route: "/coordinators",
                    icon: <SchoolIcon />,
                },
                {
                    label: "Feedback",
                    route: "/feedback",
                    icon: <MoodIcon />,
                }
            ]
        }
    },
    pageNotFound: {
        mainText: '404 Page not found.'
    },
    postTags: {
        wellness: 'General Wellness',
        tManagement: 'Time Management',
        stressPanic: 'Stress/Panic',
        productivity: 'Productivity/Motivation',
        social: 'Social Connections',
    },
    verifyPosts: {
        noPostsToVerify: "No posts to verify",
    }
}

export default STRINGS;