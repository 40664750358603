import React, {useEffect, useState } from 'react'

import {withRouter} from 'react-router-dom'
import SortForums from '../../components/Organism/SortForums.js';
import useStyles from './styles';
import MainPostList from '../../components/Organism/MainPostList.js';
import ForumsDisclaimer from '../../components/Atom/ForumsDisclaimer.js';
import { dateFilterToMillisecsMap } from '../../constants/Constants.js';
import useFetch from '../../hooks/useFetch.js';

const Inbox = (props) => {
    /* -------------------------------------------------------- Props for Inbox -----------------------------------------------------------------

    PROPS:      
    user: Object type from routes.js. containts the authenticated user's info from the db                                                      
    forumType: string that can be one of three values: "public-posts", "admin-inbox" and "user-inbox"
    mobileStatus: bool from mainpage.js. true if screensize is less than 'sm'
    navStatus:bool from adminMainPAge.js and UserMainPAge.js. 
    */

    // forums is an array of forumItem/inboxItem components that will be rendered
    const [forums, setForums] = useState([]);

    // types of sorting for Inbox
    const [dateFilter, setDateFilter] = useState("all");

    const classes = useStyles();

    // we can't conditionally use hooks, so we just change the query depending on the forumType
    const [fetchArgs, setFetchArgs] = useState({})
    const {status: DBStatus, data: DBData} = useFetch(fetchArgs);

    // width of component changes depending on if user has navbar expanded or is in mobile view
    const getWidth = () => {
        if(!props.mobileStatus) {
            if(props.navStatus) {
                return "calc(400px + 30vw)";
            } else {
                return "calc(400px + 38vw)";
            }
        } else {
            return "100%";
        }
    }

    const downloadPosts = () => {
        let tempArrayOfDocs = [];
            for (let doc of DBData.body) {
                tempArrayOfDocs.push(doc);                
            }
        return (tempArrayOfDocs)
    }

    useEffect(() => {
        let route;
        if (props.forumType === 'user-inbox') {
            route = '/db/privatePosts/get/byPublisherId/';
        } else if (props.forumType === "admin-inbox" && props.user.userType !== 'student') {
            route = '/db/privatePosts/get/byCoordinatorId/';
        }  
        setFetchArgs({URLquery: route, 
            body: {
                publisherId: props.user.userId,
                coordinatorId: props.user.userId,
                datePublished: new Date(new Date() - dateFilterToMillisecsMap[dateFilter])
            }
        });

    }, [dateFilter]);

    // downloads the forum post docs, sorts them and sets the state on page load
    // if nothing comes from the db, error pops up
    useEffect(()=>{
        if (DBStatus === "completed") {
            let arrayOfDocs = downloadPosts()
            setForums(arrayOfDocs);
            setFetchArgs({});
        } else if (DBStatus === "empty"){
            setForums([]);
            setFetchArgs({});
        }
    }, [DBStatus]);
    

    return (
        <div className={classes.container}>
            <div style={{width:getWidth()}}>
                <ForumsDisclaimer/>
                <SortForums 
                    user={props.user}
                    mobileStatus={props.mobileStatus}
                    forumType={props.forumType} 
                    dateSort={dateFilter} 
                    setDateSort={setDateFilter} 
                />
                <div className={classes.forumContainer}>
                    <MainPostList
                        user={props.user}
                        forumType={props.forumType} 
                        mobileStatus={props.mobileStatus}
                        arrayOfPostData={forums}
                    />
                </div>
            </div>
        </div>
    )
};

export default withRouter(Inbox)