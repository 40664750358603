import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import useFetch from '../../hooks/useFetch.js';
import useStyles from './styles';
import VerifyList from '../../components/Organism/VerifyList';
import VerifyPostsEmptyList from '../../components/Atom/VerifyPostsEmptyList.js';
import { SchoolContext } from '../../contexts/CurrentSchoolContext.js';

// This is effectively a copy of Forums and ForumItems (certain are allowed to access /verify_posts and )
const VerifyPost = (props) => {
    /*-------------------- Props for VerifyPost ---------------------
     PROPS:                           
     user: user Obj from routes.js
     */

    const classes = useStyles();
    const [downloadedPubReplies, setDownloadedPubReplies] = useState([]);

    const [fetchArgsReplies, setFetchArgsReplies] = useState({});
    const { status: dbCallStatusReplies, data: dbCallDataReplies } = useFetch(fetchArgsReplies);

    const schoolCont = useContext(SchoolContext);

    // kick out the non admins
    if (props.user.userType === 'student') {
        props.history.push("/home");
    };

    useEffect(() => {
        if (schoolCont.schoolId) {
            setFetchArgsReplies({
                URLquery: '/db/replies/get/unverified', body: {
                    organizationId: schoolCont.schoolId
                }
            });
        };
    }, [schoolCont.schoolId]);

    useEffect(() => {
        if (dbCallStatusReplies === 'completed') {
            setDownloadedPubReplies(dbCallDataReplies);
        };
    }, [dbCallStatusReplies]);

    return (
        <div className={classes.container}>
            {downloadedPubReplies.body ?
                <VerifyList
                    downloadedDocs={downloadedPubReplies}
                    setDownloadedDocs={setDownloadedPubReplies}
                    type='replies'
                />
                :
                <VerifyPostsEmptyList />
            }
        </div>
    );
};

export default withRouter(VerifyPost);