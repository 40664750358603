import React, { useState, useEffect } from 'react';
import useFetch from '../../hooks/useFetch';
import { COLORS } from '../../constants/Colors';
import ForumReply from '../../components/Organism/ForumReply';
import CreateReply from '../../components/Organism/CreateReply';
import ForumReplies from '../../components/Organism/ForumReplies';
import { withRouter, useLocation } from 'react-router-dom';

const ForumRepliesPage = (props) => {
    /* -------------------------------------------------------- Props for ForumRepliesPage -----------------------------------------------------------------
    user: obj
    mobileStatus: bool
    */
    const topReplyUUID = (useLocation().pathname).replace('/forums/replies/', '').replace('/coordinator_forums/replies/', '');

    const [replyDialog, setReplyDialog] = useState(false);
    const [refreshPage, setRefreshPage] = useState(false);
    const [parentReplyUUID, setParentReplyUUID] = useState(null);
    const [topReplyData, setTopReplyData] = useState(null);

    const [fetchArgsTopReply, setFetchArgsTopReply] = useState({});
    const { status: topDBStatus, data: topDBData } = useFetch(fetchArgsTopReply);

    const renderTopReply = (data) => {
        let component = <></>;
        if (data) {
            component =
                <ForumReply
                    user={props.user}
                    mobileStatus={props.mobileStatus}
                    replyData={data}
                    layer={0}
                    setParentReplyUUID={setParentReplyUUID}
                    setReplyDialog={setReplyDialog}
                    refreshPage={refreshPage}
                    setRefreshPage={setRefreshPage}
                />
        }
        return component
    };

    useEffect(() => {
        setFetchArgsTopReply({
            URLquery: '/db/replies/get/byUniqueId',
            body: {
                UUID: topReplyUUID,
            }
        });
    }, []);


    useEffect(() => {
        if (topDBStatus === 'completed') {
            setTopReplyData(topDBData.body[0]);
            setFetchArgsTopReply({});
        }
    }, [topDBStatus]);

    return (
        <div style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: "center",
            marginTop: "calc(2vh + 1vw)",
            marginBottom: "200px",
            backgroundColor: COLORS.postBackground,
            }}>
            {renderTopReply(topReplyData)}
            <ForumReplies
                user={props.user}
                mobileStatus={props.mobileStatus}
                refreshPage={refreshPage}
                setRefreshPage={setRefreshPage}
                setParentReplyUUID={setParentReplyUUID}
                setReplyDialog={setReplyDialog}
                topReplyUUID={topReplyUUID}
                isPost={false}
            />
            <CreateReply
                type="public-replies"
                user={props.user}
                parentPostId={topReplyData?.postId}
                open={replyDialog}
                setClosed={() => setReplyDialog(false)}
                refreshPage={refreshPage}
                setRefreshPage={setRefreshPage}
                parentReplyUUID={parentReplyUUID}
            />
        </div>
    )
};

export default withRouter(ForumRepliesPage);