import React from 'react';
import { COLORS } from '../../constants/Colors';
import { makeStyles } from '@material-ui/core/styles';

const WelcomeBackMessage = (props) => {
    /* -------------------------------------------------------- Props for WelcomeBackMessage -----------------------------------------------------------------

    PROPS:      
        firstName: string
    */
    const classes = useStyles();
    
    return (
        <div className={classes.displayMessageDiv}>
            <p>Welcome back, <span style={{color: COLORS.hiPandaHeader}}>{props.firstName}</span></p>
        </div>
    )
}
const useStyles = makeStyles(() => ({ 
    displayMessageDiv: {
        display:"flex", 
        justifyContent:"center", 
        margin:"auto", 
        paddingTop:"15px", 
        width:"100%", 
        fontWeight:"100", 
        fontSize:"44px", 
        textAlign:"center",
    },
}));

export default WelcomeBackMessage;