import React from 'react';
import { withRouter } from 'react-router-dom';
import useStyles from './styles';
import DailyLog from '../../components/Molecule/DailyLog.js';
import DashboardLinkButtons from '../../components/Organism/DashboardLinkButtons.js';
import AdminSetCalendlyLink from '../../components/Organism/AdminSetCalendlyLink.js';
import WelcomeBackMessage from '../../components/Atom/WelcomeBackMessage.js';

const Dashboard = (props) => {
    /* -------------------- Props for AdminDashboard ---------------------
    PROPS:                           
    user: obj, contians info about the user 
    setUser: fxn, sets the user State 
    */
    const classes = useStyles();

    if (props.user.userType === 'student') {
        return (
            <div className={classes.container}>
                <WelcomeBackMessage
                    firstName={props.user.firstName}
                />
                <DailyLog
                    user={props.user}
                    setUser={props.setUser}
                />
                <DashboardLinkButtons
                    userType={"student"}
                />
            </div>
        )
    } else {
        return (
            <div className={classes.container}>
                <WelcomeBackMessage
                    firstName={props.user.firstName}
                />
                <DashboardLinkButtons
                    userType="admin"
                    calendlyLink={props.user.calendlyLink}
                />
                <AdminSetCalendlyLink
                    user={props.user}
                    setUser={props.setUser}
                />
            </div>
        )
    }
};

export default withRouter(Dashboard);
