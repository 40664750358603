import React, { useEffect, useState } from 'react';
import TagSelect from './TagSelect';

import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FilterListIcon from '@material-ui/icons/FilterList';

// contains sorting features AND create post features
const SortForums = (props) => {
    /* -------------------------------------------------------- Props for SortForums -----------------------------------------------------------------
    PROPS:      
    forumType: string. if "public-posts", this component will render an option to sort by upvotes
    sortPostsBy: string from forums.js. can be "date" or "upvotes".
    setSort: function
    dateSort: string from forums.js. can be "all", "day", "week", "month", or "year"
    setDateSort: function
    mobileStatus: bool
    tagsSelected: obj of bools
    setTagsSelected: fxn
    setFilterButton: fxn to redo a fetch
    */

    const classes = useStyles()

    // React component which will be produced later
    const [sortComponent, setSortComponent] = useState();

    // dialog for sort options when user is in mobile view
    const [filterDialogue, setFilterDialogue] = useState(false);

    const updateFilter = () => {
        setFilterDialogue(false);
        props.setFilterButton(Math.random());
    };


    // React component for SortForums
    const sortingForm = (
        <>
            <div>
                {props.forumType === 'public-posts' ?
                    <FormControl className={classes.formControl}>
                        <InputLabel className={classes.inputLabel} id="demo-simple-select-label">Sort By</InputLabel>
                        <Select
                            className={classes.select}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.sortPostsBy}
                            onChange={e => props.setSort(e.target.value)}>
                            <MenuItem value={"date"}>Date</MenuItem>
                            <MenuItem value={"upvotes"}>Votes</MenuItem>
                        </Select>
                    </FormControl>
                    :
                    <></>
                }
                <FormControl className={classes.formControl}>
                    <InputLabel className={classes.inputLabel} id="demo-simple-select-label">Date Posted</InputLabel>
                    <Select
                        className={classes.select}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.dateSort}
                        onChange={e => props.setDateSort(e.target.value)}>
                        <MenuItem value={"all"}>All</MenuItem>
                        <MenuItem value={"day"}>Last 24 Hours</MenuItem>
                        <MenuItem value={"week"}>This week</MenuItem>
                        <MenuItem value={"month"}>This month</MenuItem>
                        <MenuItem value={"year"}>This year</MenuItem>
                    </Select>
                </FormControl>
            </div>
            {props.forumType === 'public-posts' ?
                <div style={{ display: 'flex', flexDirection: 'column'}}>
                    <TagSelect
                        tagsSelected={props.tagsSelected}
                        setTagsSelected={props.setTagsSelected}
                        mobileStatus={props.mobileStatus}
                    />
                    <Button
                        style={{ padding: "10px", color: "white", backgroundColor: "#9487FA" }}
                        onClick={updateFilter}
                    >
                        Apply Filters
                    </Button>
                </div>
                :
                <></>
            }
        </>
    )

    // Combine the SortForums component AND the CreateForum button component
    const getSortComponent = () => {
        if (!props.mobileStatus) {
            setSortComponent(
                <div className={classes.desktopButtonsWrapper}>
                    {sortingForm}
                </div>
            )
        } else {
            setSortComponent(
                <div className={classes.mobileButtonsWrapper}>
                    <Button
                        className={classes.mobileButton}
                        onClick={() => setFilterDialogue(true)}
                    >
                        Filters
                        <FilterListIcon style={{ marginLeft: "10px", }} />
                    </Button>
                    <Dialog
                        className={classes.applyFilterDialog}
                        open={filterDialogue}
                        aria-labelledby="max-width-dialog-title"
                        onClose={() => setFilterDialogue(false)}
                    >
                        <div className={classes.mobileFilterWrapper}>
                            <DialogTitle>Filters</DialogTitle>
                            {sortingForm}
                        </div>
                    </Dialog>
                </div>
            )
        }
    }

    useEffect(() => {
        getSortComponent();
    }, [props.dateSort, props.sortPostsBy, props.forumType, filterDialogue, props.mobileStatus, props.tagsSelected])

    return (
        <>
            {sortComponent}
        </>
    )
}

const useStyles = makeStyles(() => ({
    formControl: {
        position: "relative",
        marginTop: "20px",
        marginLeft: "25px",
        marginRight: "25px",
    },
    select: {
        width: "120px",
        fontSize: "calc(14px + 0.3vw)",
    },
    mobileFilterWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        flexDirection: 'column',
        padding: '20px',
    },
    desktopButtonsWrapper: {
        display: "flex",
        flexDirection: 'column',
        margin: "20px 0"
    },
    mobileButtonsWrapper: {
        display: "flex",
        justifyContent: "center",
        margin: "20px 0"
    },
    mobileButton: {
        padding: "15px",
        maxWidth: "200px",
        backgroundColor: "#9487FA",
        textTransform: "none",
        color: "white",
        fontWeight: "450"
    },
    inputLabel: {
        fontSize: "calc(14px + 0.2vw)"
    },
    applyFilterDialog: {
        margin: "15% auto 0%",
    },
    applyFilterButton: {
        marginTop: "45px",
        padding: "10px",
        color: "white",
        backgroundColor: "#9487FA"
    },
}))

export default SortForums