import React, { useEffect, useState } from 'react'

import { Switch, Route } from 'react-router-dom';
import withWidth from '@material-ui/core/withWidth';
import Nav from '../../components/Organism/Nav.js';
import MobileNav from '../../components/Organism/MobileNav.js';
import Header from '../../components/Molecule/Header';
import Dashboard from '../dashboard';
import Forums from '../forums';
import Inbox from '../inbox';
import HowTo from '../howto';
import ForumPostPage from '../forumpostpage';
import InboxItemPage from '../inboxitempage';
import VerifyPost from '../verifyposts';
import VerifyReplies from '../verifyreplies';
import Loading from '../../components/Organism/Loading.js';
import Chat from '../../screens/chat';
import ForumRepliesPage from '../forumrepliespage';
// import PostsToCSV from '../PostsToCSV.js';

const AdminMainpage = (props) => {
    // for whether or not the navbar is expanded or collapsed
    const [navStatus, setNavStatus] = useState(true);
    const mobileStatus = props.mobileStatus;

    const mySetNavStatus = () => {
        setNavStatus(!navStatus);
    }

    useEffect(() => { }, [props.mobileStatus])

    // routes for AdminMainPage
    return (
        <div style={{ display: "flex", position: "relative" }}>
            <Loading />
            <Nav
                user={props.user}
                mobileStatus={mobileStatus}
                navStatus={navStatus}
                setNavStatus={mySetNavStatus}
            />
            <MobileNav
                user={props.user}
                mobileStatus={mobileStatus}
            />
            <div style={{ display: "flex", flexDirection: "column", maxWidth: "100vw", flexGrow: "1", position: "relative" }}>
                <Header />
                <Switch>
                    <Route exact path="/coordinator_forums"><Forums user={props.user} key={"pub"} forumType="public-posts" mobileStatus={mobileStatus} navStatus={navStatus} /></Route>
                    <Route exact path="/coordinator_inbox"><Inbox user={props.user} key={"inbox"} forumType="admin-inbox" mobileStatus={mobileStatus} navStatus={navStatus} /></Route>
                    <Route exact path="/coordinator_inbox/post/:id"><InboxItemPage type="admin" user={props.user} mobileStatus={mobileStatus} navStatus={navStatus} /></Route>
                    <Route exact path="/coordinator_dashboard"><Dashboard user={props.user} setUser={props.setUser} /></Route>
                    <Route exact path="/verify_posts"><VerifyPost user={props.user} mobileStatus={mobileStatus} navStatus={navStatus} /></Route>
                    <Route exact path='/verify_replies'><VerifyReplies user={props.user} mobileStatus={mobileStatus} navStatus={navStatus} /></Route>
                    <Route exact path="/coordinator_forums/post/:id"><ForumPostPage user={props.user} forumType="public-posts" mobileStatus={mobileStatus} navStatus={navStatus} /></Route>
                    <Route exact path="/coordinator_forums/replies/:id"><ForumRepliesPage user={props.user} mobileStatus={mobileStatus}/></Route>
                    <Route exact path="/howto"><HowTo /></Route>
                    <Route exact path="/coordinator_chat"><Chat user={props.user} navStatus={navStatus}/></Route>
                </Switch>
            </div>
        </div>
    )
}
export default withWidth()(AdminMainpage);
