import React from 'react';
import Button from '@material-ui/core/Button';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const CopyButton = (props) => {
    return (
        <Button 
            variant="contained" 
            onClick={props.clickFunction} 
            style={{ width: "50%" }}
        >
            {props.text} &nbsp;
            <FileCopyIcon style={{ fontSize: "16px" }}/>
        </Button>
    )
};

export default CopyButton;