import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    container: {
        display:"flex", 
        flexWrap:"wrap",
        justifyContent: "center", 
        alignItems:"center",
        margin: "0 auto", 
    }, 
    formDiv: {
        position:"relative", 
        margin:"40px auto 0px", 
        width: "100%", 
        backgroundColor:"white", 
        borderRadius:"10px", 
        boxShadow:"0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19)",
    },
    mobileFormDiv: {
        position:"relative", 
        margin:"-50px auto 0px",
        width: "100%", 
        backgroundColor:"white", 
        borderRadius:"10px",
        boxShadow:"0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19)",
    },
    forumContainer: {
        margin: "0 auto",
        paddingBottom: "80px",
        width:"100%", 
        maxWidth: "95vw",
    },

}));

export default useStyles;