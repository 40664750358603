import React from 'react';

const AccessTokenContext = React.createContext({});

const AccessTokenProvider = (props) => {

    const [accessToken, setAccessToken] = React.useState();
    const accessTokenObj = {
        accessToken: accessToken,
        setAccessToken: setAccessToken
    };

    return (
        <AccessTokenContext.Provider value={accessTokenObj}>
            {props.children}
        </AccessTokenContext.Provider>
    )
}

export { AccessTokenContext, AccessTokenProvider }