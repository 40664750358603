import React, { useState } from 'react';
import app from "../../firebase/Firebase";
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/Colors';

import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';

const PasswordReset = (props) => {
    /* props:
    resetPassOpen: bool
    setIsEmailConfirmationOpen: fxn, bool
    setEmailMessage: fxn, str
    */
	const classes = useStyles();

    const [passResetEmail, setPassResetEmail] = useState('');
    const [errorMsg, setErrorMsg] = useState(null);

    const renderErrorMsg = () => {
        const messages = {
            'blank': 'Field is required',
            'auth/user-not-found': 'email not registered with HiPanda',
            'auth/invalid-email': 'The email address is badly formatted.'
        };
        if (errorMsg) {
            if (messages[errorMsg]) {
                return messages[errorMsg];
            }
            return 'Error, please double check your email'
        }
    };
    const sendPasswordReset = async () => {
        const actionCodeSettings = { url: window.location.href };
        try {
            if (passResetEmail.length > 0) {
                await app.auth().sendPasswordResetEmail(passResetEmail, actionCodeSettings);
                setPassResetEmail('');
                setErrorMsg(null);
                props.setEmailMessage('passwordResetSent');
                props.setIsEmailConfirmationOpen(true);
                props.setResetPassOpen(false);
            } else {
                setErrorMsg('blank')
            }
        } catch (err) {
            setErrorMsg(err.code);
        }
    };

    return (
        <Dialog
            maxWidth="xl"
            style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "0%", }}
            open={props.resetPassOpen}
            onClose={() => { 
                props.setResetPassOpen(false);
                setPassResetEmail(''); 
            }}
            aria-labelledby="max-width-dialog-title"
        >
            <div
                style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '20px' }}
            >
                <div style={{ color: 'red' }}>
                    {renderErrorMsg()}
                </div>
                <TextField
                    variant="outlined"
                    label="Email"
                    onChange={e => setPassResetEmail(e.target.value)}
                    defaultValue={passResetEmail}
                />
                <button
                    onClick={sendPasswordReset}
                    className={classes.resetButton}
                >
                    Reset Password
				</button>
            </div>
        </Dialog>
    )
};

const useStyles = makeStyles(() => ({
	resetButton: {
		padding: '10px 20px',
		width: '100%',
		border: '0px',
		borderRadius: '10px',
		backgroundColor: COLORS.landingBackground,
		boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
		outline: 'none',
		fontSize: '18px',
		'&:hover': {
			color: "white",
			backgroundColor: COLORS.hiPandaHeader,
			cursor: 'pointer'
		}
	},
}));

export default PasswordReset;