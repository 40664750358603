import React, { useState } from 'react';

const LoadContext = React.createContext({});

const LoadContextProvider = (props) => {
    // look at components/Organism/loading.js for more details
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("default");
    const loadObj = {
        isLoading: isLoading,
        setIsLoading: setIsLoading,
        loadingMessage: loadingMessage,
        setLoadingMessage: setLoadingMessage,
    }

    return (
        <LoadContext.Provider value={loadObj}>
            {props.children}
        </LoadContext.Provider>
    )
}

export { LoadContext, LoadContextProvider }