import React, { useContext, useState, useEffect } from 'react';
import useFetch from '../../hooks/useFetch.js';
import useStyles from './styles';
import { withRouter, useLocation } from 'react-router-dom'
import CreateReply from '../../components/Organism/CreateReply';
import ForumParentPost from '../../components/Organism/ForumParentPost.js';
import ReturnButton from '../../components/Atom/ReturnButton.js';
import ForumReplies from '../../components/Organism/ForumReplies';
import { SchoolContext } from '../../contexts/CurrentSchoolContext.js';

// React component which renders on /forums/:postID or /coordinator_forums/:postID
const ForumPostPage = (props) => {
    /* -------------------------------------------------------- Props for ForumsPostPage -----------------------------------------------------------------
    PROPS:      
    mobileStatus: bool
    navStatus: bool. seems to be unused.
    user: object from routes.js containing the user's info from the db
    forumType: string. can be either "public-posts", "admin-inbox", or "user-inbox"
    */

    const classes = useStyles();

    // document id is retrieved from the route
    const forumPostID = (useLocation().pathname).replace("/forums", "").replace("/coordinator_forums", "").replace("/post/", "");

    const [doc, setDoc] = useState();
    // dialog for creating a reply
    const [replyDialog, setReplyDialog] = useState(false);
    const [refreshPage, setRefreshPage] = useState(false);
    const [parentReplyUUID, setParentReplyUUID] = useState(null);

    const [fetchArgs, setFetchArgs] = useState({});
    const { status: DBStatus, data: DBData } = useFetch(fetchArgs);

    const schoolCont = useContext(SchoolContext);

    // width depending on whether the user is in mobile view OR if the navbar is expanded
    const getWidth = () => {
        if (!props.mobileStatus) {
            if (props.navStatus) {
                return "calc(400px + 30vw)";
            } else {
                return "calc(400px + 36vw)";
            }
        } else {
            return "calc(100% - 40px)";
        }
    };

    const getDoc = () => {
        if (DBStatus === "completed") {
            setDoc(DBData);
            setFetchArgs({});
        }
    };

    // ensure that the component is only rendered after all the necessary information is retrieved
    const renderContent = () => {
        if (doc) {
            return (
                <>
                    <ForumParentPost
                        user={props.user}
                        docdata={doc}
                        setReplyDialog={setReplyDialog}
                        mobileStatus={props.mobileStatus}
                        setParentReplyUUID={setParentReplyUUID}
                    />
                    <CreateReply
                        type="public-replies"
                        user={props.user}
                        parentPostId={doc.body.id}
                        open={replyDialog}
                        setClosed={() => setReplyDialog(false)}
                        refreshPage={refreshPage}
                        setRefreshPage={setRefreshPage}
                        parentReplyUUID={parentReplyUUID}
                    />
                </>
            )
        } else {
            return <></>
        }
    };

    useEffect(() => {
        if (schoolCont.schoolId) {
            setFetchArgs({
                URLquery: '/db/posts/get/single',
                body: {
                    postUrlPath: forumPostID,
                    userId: props.user.userId,
                    organizationId: schoolCont.schoolId
                }
            });
        }
    }, [schoolCont.schoolId, props.user.userId, refreshPage]);
    // refresh the component when states such as upvoteCount is changed
    useEffect(() => {
        getDoc();
    }, [doc, DBStatus]);

    return (
        <div className={classes.container}>
            <div style={{ width: getWidth(), marginTop: "calc(2vh + 1vw)", display: "flex" }}>
                {!props.mobileStatus ?
                    <ReturnButton
                        user={props.user}
                        forumType={props.forumType}
                        mobileStatus={props.mobileStatus}
                    /> : <></>
                }
                <div className={classes.componentContainer}>
                    {renderContent()}
                    <ForumReplies
                        user={props.user}
                        mobileStatus={props.mobileStatus}
                        refreshPage={refreshPage}
                        setRefreshPage={setRefreshPage}
                        setParentReplyUUID={setParentReplyUUID}
                        setReplyDialog={setReplyDialog}
                        topReplyUUID={forumPostID}
                        isPost={true}
                    />
                </div>
            </div>
        </div>
    )
};

export default withRouter(ForumPostPage);