import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';

// contains sorting features AND create post features
const SearchForums = (props) => {
    /* -------------------------------------------------------- Props for SearchForums -----------------------------------------------------------------
    PROPS:      
    searchFilter: string, the string in the search entry field
    setSearchFilter: function, sets searchFilter state.
    */
    const classes = useStyles();

    const handleInputChange = (e) => {
        props.setSearchFilter(e.target.value);
    }

    return (
        <div className={classes.searchWrapper}>
            <TextField onChange={handleInputChange} value={props.searchFilter} label="Search for Posts" className={classes.searchInput} fullWidth/>
            <button onClick={props.searchPosts}>
                <SearchIcon/>
            </button>
        </div>
    )

};

const useStyles = makeStyles(() => ({
    searchWrapper: {
        display: 'flex',
        width: "80%",
        margin: '0 auto'
    },
    searchInput: {
        display: "block", 
        margin: "5px",
    },
}))

export default SearchForums 