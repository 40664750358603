import React, { useContext, useState, useEffect } from 'react';
import useFetch from '../../hooks/useFetch.js';
import { SchoolContext } from '../../contexts/CurrentSchoolContext.js';

const UserList = (props) => {
    /* -------------------------------------------------------- Props for UserList -----------------------------------------------------------------
    user: userObject
    setMessageRecipient: fxn
    */
    const schoolCont = useContext(SchoolContext);

    // array containing list of users
    const [usersArray, setUsersArray] = useState([]);

    const [fetchArgs, setFetchArgs] = useState({});
    const { status: dbCallStatus, data: dbCallData } = useFetch(fetchArgs);

    const userListRender = (databaseBody) => {
        const tempArray = []
        databaseBody.forEach((user) => {
            const tempComponent = 
            <div 
                onClick={() => props.setMessageRecipient(user)}
                key={user.userId}
                style={{ padding: '10px', borderRadius: '10px', backgroundColor: 'white', cursor: 'pointer'}}
            >
                {`${user.firstName} ${user.lastName}`}
            </div>;
            tempArray.push(tempComponent);
        });
        setUsersArray(tempArray);
    };

    useEffect(() => {
        if (props.user.userType === 'coordinator') {
            setFetchArgs({
                URLquery: '/db/users/get/byMessageRecipient/',
                body: {
                    receiverId: props.user.userId,
                },
                loadingDisabled: true
            });
        } else {
            setFetchArgs({
                URLquery: '/db/coordinator/get/selectCoordinators/',
                body: {
                    organizationId: schoolCont.schoolId
                },
                loadingDisabled: true
            });
        }        
    }, [])

    useEffect(() => {
        if (dbCallStatus === 'completed') {
            userListRender(dbCallData.body)
        }
    }, [dbCallStatus])

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            {usersArray}
        </div>
    )
}

export default UserList
