import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';  
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

const ConfirmDialog = (props) => {
    /* dialog that appears for confirmation for deleting, verifying, and archiving posts
    -------------------------------------------------------- Props for ConfirmDialog -----------------------------------------------------------------
    PROPS:                                                            open, onClose, onConfirm, title, content
    open: this component will render if open === true
    onClose: a function that will be called when the user clicks on either cancel and confirm.
    ideally this function will set props.open to be false and close this component
    onConfirm: a function that will be called when user clicks on confirm. Ideally this function will achieve
    whatever task the user wants to confirm they want to do.
    title: the title of the confirmation dialog. eg: Delete Post
    content: a sentence that tells the user what they are going to either cancel or confirm. eg: Do you want to delete this post?
    */
    const classes = useStyles();

    return (
        <Dialog
            open={props.open}
            style={{marginTop:"-200px", boxShadow:"0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19)",}}
            PaperProps={{
                style: {
                    width:"26vw",
                    minWidth:"300px",
                    height:"240px",
                    minHeight:"240px",
                    boxShadow:"0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19)",
                }
            }}
            onClose={props.onClose}
        >
            <div className={classes.confirmTitle}>
                {props.title}
            </div>
            <Divider />
            <div className={classes.confirmText}>
                {props.content}
            </div>
            <div className={classes.confirmButtonWrapper}>
                <Button onClick={() => props.onClose()} style={{backgroundColor:"#ccf7ff", }} className={classes.buttons}>
                    Cancel
                </Button>
                <Button onClick={() => {props.onConfirm(); props.onClose()}} style={{backgroundColor:"#ff8282",}} className={classes.buttons}>
                    Confirm
                </Button>
            </div>
        </Dialog>
    )
}

const useStyles = makeStyles(() => ({
    confirmTitle: {
        marginLeft: "30px",
        marginTop: "22px",
        marginBottom: "22px",
        fontSize: "21px",
        fontWeight: "500"
    },  
    confirmText: {
        marginLeft: "20px",
        marginRight: "20px",
        marginTop: "calc(10px + 2vh)",
        fontSize: "19px",
        fontWeight: "400",
        textAlign: "center",
    },
    confirmButtonWrapper: {
        display: "flex", 
        justifyContent: "center",
        marginTop: "calc(10px + 2vh)",
        paddingBottom: "10px",
    },
    buttons: {
        marginRight:"20px", 
        marginLeft:"20px", 
        paddingLeft:"10px", 
        paddingRight:"10px",
        textTransform:"none", 
        fontSize:"17px",
    },
  })
);
  

export default ConfirmDialog;