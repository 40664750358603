import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import STRINGS from '../../constants/Strings.js';

const VerifyPostsEmptyList = () => {
    /*-------------------- Props for VerifyPostsEmptyList ---------------------
     PROPS:            none               
     */

    const classes = useStyles();

    return (
        <div className={classes.noPosts}>
            {STRINGS.verifyPosts.noPostsToVerify}
        </div>
    )
}

const useStyles = makeStyles(() => ({
    noPosts: {
        display:"flex", 
        justifyContent:"center", 
        padding:"50px", 
        margin: "50px auto 0px", 
        backgroundColor:"white", 
        fontSize:"30px",
        textAlign: "center", 
    },
}))

export default VerifyPostsEmptyList