import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ChatMenu from '../pages/chatmenu';
import ChatMessaging from '../pages/chatmessaging'
import useFetch from '../hooks/useFetch';

const Chat = (props) => {
    /* -------------------------------------------------------- Props for Chat -----------------------------------------------------------------  
    */

    const [messageRecipient, setMessageRecipient] = useState({});

    return (
        <div>  
            {Object.keys(messageRecipient).length === 0 
            ?   <ChatMenu
                    user={props.user}
                    messageRecipient={messageRecipient}
                    setMessageRecipient={setMessageRecipient}
                /> 
            :   <ChatMessaging
                    user={props.user}
                    messageRecipient={messageRecipient}
                    setMessageRecipient={setMessageRecipient}
                />            
            }
        </div>
    )
};

export default withRouter(Chat);