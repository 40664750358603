import React, { useState, useEffect } from 'react'

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CreateForumPost from '../Organism/CreateForumPost';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Fade from '@material-ui/core/Fade';

import ConfirmDialog from './ConfirmDialog';
import PostChangeButton from '../Atom/PostChangeButton';

// Styling for components
const useStyles = () => {
    const constantStyles = ({
        expandIcon: {
            position: "absolute",
            right: "15px",
            minWidth: "0px",
            minHeight: "0px",
            padding: "5px",
        },
        changePostButton: {
            padding: "0px",
            height: "35px",
            width: "35px",
            minWidth: "0px",
            minHeight: "0px",
            borderStyle: "none",
            marginLeft: "7px",
            marginRight: "7px",
            borderRadius: "5px",
            backgroundColor: "rgba(0, 0, 0, 0.05)",
        },
    })
    return constantStyles;
};

const ChangePost = (props) => {

    /* -------------------------------------------------------- Props for ChangePost -----------------------------------------------------------------

    PROPS:                                                            backgroundColor, docID, canEdit, canDelete, canVerify,
    EDIT OBJECT IF canEdit === true:                                  edit = {type, dialogTitle, title, content, anonymous, department,}
    IF canVerify === true                                             verifyPost()
    IF canDelete === true                                             deletePost(), 
    BASE PROPS:                                                       mobileStatus, navStatus, user,
    */

    // Booleans for edit, delete  dialog respectively
    const [editDialog, setEditDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);

    // Dialog for verifying posts
    const [verifyDialog, setVerifyDialog] = useState(false);

    // Dialog for mobile edit dialog
    const [mobileEditMenu, setMobileEditMenu] = useState(false);

    // Button to display menu for edit/delete features while on mobile
    const [anchorElement, setAnchorElement] = useState(null);

    const classes = useStyles(props.mobileStatus);

    // Changing post component
    const changePost = () => {
        // Array for components used for changing post (edit button, delete button, etc
        // Only the user who published post can edit/delete a post, and only an admin can verify posts
        let changePostOptions = [];

        // if not in mobile view, display the buttons in the changePost Options
        // if in mobile view, display a button which opens a menu showing the change post options
        if (!props.mobileStatus) {

            // if props.canEdit is true, then add the edit component to the change post array (same for delete, & verify)
            if (props.canEdit) {
                const editComponent = (
                    <PostChangeButton
                        type={'edit'}
                        key={'edit'}
                        onClick={() => setEditDialog(true)}
                    />
                )
                changePostOptions.push(editComponent);
            }

            if (props.canDelete) {
                const deleteComponent = (
                    <PostChangeButton
                        type={'delete'}
                        key={'delete'}
                        onClick={() => setDeleteDialog(true)}
                    />
                )
                changePostOptions.push(deleteComponent);
            }

            if (props.canVerify) {
                const verifyComponent = (
                    <PostChangeButton
                        type={'verify'}
                        key={'verify'}
                        onClick={() => setVerifyDialog(true)}
                    />
                )
                changePostOptions.push(verifyComponent);
            }

            return (
                <>
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                        {changePostOptions}
                    </ButtonGroup>
                    <ConfirmDialog open={deleteDialog} onClose={() => setDeleteDialog(false)} title="Delete Post" content="Are you sure you want to delete this post?"
                        onConfirm={() => props.deletePost()}
                    />
                    <CreateForumPost
                        user={props.user}
                        type={props.edit.createForumPostType}
                        dialogTitle={props.edit.dialogTitle}
                        title={props.edit.title}
                        content={props.edit.content}
                        anonymous={props.edit.anonymous}
                        department={props.edit.department}
                        open={editDialog}
                        mobileStatus={props.mobileStatus}
                        setClosed={() => { setEditDialog(false); }}
                        editDocID={props.docID}
                    />
                    <ConfirmDialog open={verifyDialog} onClose={() => setVerifyDialog(false)} title="Verify Post" content="Are you sure you want to verify this post?"
                        onConfirm={() => props.verifyPost()}
                    />
                </>
            )
        } else {
            // user is in mobile view
            // if props.canEdit is true, then add the edit component to the change post array (same for delete, & verify)
            if (props.canEdit) {
                const editComponent = (
                    <MenuItem key="edit" onClick={() => setEditDialog(true)}>
                        Edit
                    </MenuItem>
                )
                changePostOptions.push(editComponent);
            }

            if (props.canDelete) {
                const deleteComponent = (
                    <MenuItem key="del" onClick={() => setDeleteDialog(true)}>
                        Delete
                    </MenuItem>
                )
                changePostOptions.push(deleteComponent);
            }

            if (props.canVerify) {
                const verifyComponent = (
                    <MenuItem key="ver" onClick={() => setVerifyDialog(true)}>
                        Verify
                    </MenuItem>
                )
                changePostOptions.push(verifyComponent);
            }

            // if menu button is clicked, it expands into a menu with change post options
            return (
                <React.Fragment>
                    <Button onClick={(e) => { setAnchorElement(e.currentTarget); setMobileEditMenu(true) }}
                        style={classes.expandIcon}>
                        <MoreVertIcon />
                    </Button>
                    <Menu
                        style={{ marginRight: "20px", marginLeft: "-10px", }}
                        id="fade-menu"
                        keepMounted
                        open={mobileEditMenu}
                        onClose={() => setMobileEditMenu(false)}
                        TransitionComponent={Fade}
                        anchorEl={anchorElement}
                        PaperProps={{
                            style: {
                                marginTop: "53px",
                            }
                        }}
                    >
                        {changePostOptions}
                    </Menu>
                    <CreateForumPost
                        user={props.user}
                        type={props.edit.createForumPostType}
                        dialogTitle={props.edit.dialogTitle}
                        title={props.edit.title}
                        content={props.edit.content}
                        anonymous={props.edit.anonymous}
                        department={props.edit.department}
                        open={editDialog}
                        mobileStatus={props.mobileStatus}
                        setClosed={() => { setEditDialog(false); }}
                        editDocID={props.docID}
                    />
                    <ConfirmDialog open={deleteDialog} onClose={() => setDeleteDialog(false)} title="Delete Post" content="Are you sure you want to delete this post?"
                        onConfirm={() => props.deletePost()}
                    />
                    <ConfirmDialog open={verifyDialog} onClose={() => setVerifyDialog(false)} title="Verify Post" content="Are you sure you want to verify this post?"
                        onConfirm={() => props.verifyPost()}
                    />
                </React.Fragment>
            )
        }
    }

    useEffect(() => {
    }, [editDialog])

    return (
        <React.Fragment>
            {changePost()}
        </React.Fragment>
    )
}

export default ChangePost;