import React, {useEffect} from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(() => ({ 
    dialog: {
        marginLeft:"auto", marginRight:"auto", marginBottom:"0%",
    },
    button: {
        position:"absolute", right:"15px", top:"15px", padding:"10px", minWidth:"20px", zIndex:"1"
    }
}));

const Calendly = (props) => {
        /* -------------------------------------------------------- Props for Calendly -----------------------------------------------------------------
    PROPS:    
    open  
    mobileStatus
    calendlyLink
    closeDialog
    */
    const classes = useStyles();

    // fetch calendly information
    useEffect(() => {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute(
        'src',
        'https://assets.calendly.com/assets/external/widget.js'
        );
        head.appendChild(script);
    }, [props.open, props.mobileStatus])

    // styling for the dialog
    const getStyle = () => {
        if(!props.mobileStatus) {
            return {
                minWidth:"58vw", height:"95vh", marginTop:"0px", 
            }
        } else {
            return {
                minWidth:"85vw", height:"95vh",
            }
        }
    }

    // paper styling for the dialog
    const getPaperStyle = () => {
        if(!props.mobileStatus) {
            return {
                width:"62vw",
                minWidth:"62vw",
                minHeight:"85vh",
            }
        } else {
            return {
                width:"100vw",
                minWidth:"100vw",
                minHeight:"100vh",
            }
        }
    }
    
    // React Component for the Calendly Widget
    return (
        <Dialog
            maxWidth="sm"
            minWidth="sm"
            PaperProps={{
                style: getPaperStyle()
            }}
            onClose={props.closeDialog}
            className={classes.dialog}
            open={props.open}
            aria-labelledby="max-width-dialog-title">
                <div>
                <Button onClick={props.closeDialog} className={classes.button}>
                    <CloseIcon style={{fontSize:"20px"}}/>
                </Button>
                <div className="calendly-inline-widget" data-url={props.calendlyLink} style={getStyle()}/>
                </div>
        </Dialog>
    )
}

export default Calendly;