import React, { useState, useContext } from 'react';
import { COLORS } from '../../constants/Colors';
import { makeStyles } from '@material-ui/core/styles';
import { backendApi } from '../../constants/Constants.js';
import { SchoolContext } from '../../contexts/CurrentSchoolContext';
import app from "../../firebase/Firebase"
import TextField from '@material-ui/core/TextField';

const LoginForm = (props) => {
	/* -------------------------------------------------------- Props for SignupForm -----------------------------------------------------------------
		setEmailMessage: fxn, str
		setIsEmailConfirmationOpen: fxn, bool
		handleForgotPass: fxn
	*/
	const classes = useStyles();
	const [formEmail, setFormEmail] = useState('');
	const [password, setPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const currentSchoolCont = useContext(SchoolContext);

	const handleEmailChange = (e) => {
		setFormEmail(e.target.value);
	};
	const handlePasswordChange = (e) => {
		setPassword(e.target.value);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const lowerCaseEmail = formEmail.toLowerCase();
		if (lowerCaseEmail !== '' && password.length > 0) {
			const response = await fetch(`${backendApi[currentSchoolCont.devStage]}/auth/emailregisteredcheck`, {
				method: 'POST',
				body: JSON.stringify({ email: lowerCaseEmail }),
				headers: {
					'Content-Type': 'application/json'
				}
			});
			if (response.status === 200) {
				try {
					const userCredential = await app.auth().signInWithEmailAndPassword(lowerCaseEmail, password);

					if (userCredential.user.emailVerified) {
						const idToken = await userCredential.user.getIdToken();
						const secondaryFirebaseId = userCredential.user.uid;
						const checkAuthRowRes = await fetch(`${backendApi[currentSchoolCont.devStage]}/auth/getauthrow`, {
							method: 'POST',
							body: JSON.stringify({
								email: formEmail,
								secondaryId: secondaryFirebaseId
							}),
							headers: {
								'Content-Type': 'application/json',
							},
						});
						if (checkAuthRowRes.status === 200 || checkAuthRowRes.status === 201) {
							// backend successful, either an auth row exists or was just created
							// request a cookie with the refresh token.
							let getRefreshTokenRes = await fetch(`${backendApi[currentSchoolCont.devStage]}/auth/firebase/${idToken}`, {
								method: 'GET',
								mode: 'cors',
								credentials: 'include'
							});
							if (getRefreshTokenRes.status === 200) {
								const refreshTokenData = await getRefreshTokenRes.json()
								window.localStorage.setItem('refreshToken', refreshTokenData.body.refreshToken);
								window.localStorage.setItem('rftCreateDate', new Date());
								// by directly changing window location, the app is 'reloaded' and App.js will check the backend again and create a user object.
								window.location.replace("/signin");
							} else {
								throw new Error('Database Error, please retry registration/login')
							}
						} else {
							throw new Error("Database Error, please retry registration/login")
						}
						await app.auth().signOut();
					} else {
						// if the user has not followed the validation link from their email.
						setErrorMessage('You have not verified your email address.');
					}

				} catch (err) {
					// failed login (wrong password, etc)
					console.log(err);
					if (err.code === 'auth/user-not-found') {
						props.setEmailMessage("errorNotRegistered");
						props.setIsEmailConfirmationOpen(true);
					} else if (err.code === "auth/wrong-password") {
						setErrorMessage('The email/password combination is invalid.');
					}
				}
			} else if (response.status === 204) {
				props.setEmailMessage("errorNotRegistered");
				props.setIsEmailConfirmationOpen(true);
			} else {
				props.setEmailMessage("errorOther")
				props.setIsEmailConfirmationOpen(true);
			}
		} else {
			setErrorMessage('The email and password fields cannot be blank.');
		}
	};

	return (
		<form onSubmit={handleSubmit} className={classes.loginForm}>
			<div
				style={{ color: 'red' }}
			>
				{errorMessage}
			</div>
			<TextField
				variant="outlined"
				type="email"
				placeholder={"Email"}
				onChange={handleEmailChange}
				value={formEmail}
				style={{ backgroundColor: 'white' }}
			/>
			<TextField
				variant="outlined"
				type="password"
				placeholder='Password'
				onChange={handlePasswordChange}
				value={password}
				style={{ backgroundColor: 'white' }}
			/>
			<button
				className={classes.loginButton}
				type="submit"
			>
				Submit
            </button>
			<button
				className={classes.loginButton}
				type='button'
				onClick={props.handleForgotPass}
			>
				Forgot password?
            </button>
		</form>
	)
}

const useStyles = makeStyles(() => ({
	loginButton: {
		padding: '10px 20px',
		width: '100%',
		border: '0px',
		borderRadius: '10px',
		backgroundColor: COLORS.landingBackground,
		boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
		outline: 'none',
		fontSize: '20px',
		'&:hover': {
			color: "white",
			backgroundColor: COLORS.hiPandaHeader,
			cursor: 'pointer'
		}
	},
	loginForm: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		gap: "20px",
	},
}));

export default LoginForm;