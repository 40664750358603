import React from 'react';
import CONFIG from '../../constants/Config';
import { COLORS } from '../../constants/Colors';
import { makeStyles } from '@material-ui/core/styles';

// contains sorting features AND create post features
const PageButtons = (props) => {
    /* -------------------------------------------------------- Props for PageButtons -----------------------------------------------------------------
    PROPS:      
        currentPage: num
        setCurrentPage: fxn, sets state of currentPage
        totalPostCount: total number of posts in the query
    */

    const classes = useStyles()
    const maximumPage = Math.ceil(props.totalPostCount/CONFIG.POSTS_PER_PAGE);

    // buttons enabled by default
    let prevDisabled = false;
    let nextDisabled = false;
    // if no pages needed
    if (props.totalPostCount <= CONFIG.POSTS_PER_PAGE) {
        prevDisabled = true;
        nextDisabled = true;
    // if first page
    } else if (props.currentPage === 1) {
        prevDisabled = true;
    // if last page
    } else if (props.currentPage === maximumPage) {
        nextDisabled = true;
    } 

    const incrementForumPage = (pageDelta) => {
        const newPage = props.currentPage + pageDelta;
        if (newPage <= 0) {
            props.setCurrentPage(1);
        } else if (newPage > maximumPage) {
            props.setCurrentPage(maximumPage);
        } else {
            props.setCurrentPage(newPage);
        }
    }

    return (
        <div className={classes.pageChangeButtonWrapper}>
            <button disabled={prevDisabled} 
                onClick={() => {incrementForumPage(-1)}} 
                className={classes.pageChangeButton}
            >
                Prev
            </button>
            <div>
                {props.currentPage} / {maximumPage}
            </div> 
            <button disabled={nextDisabled} 
                onClick={() => {incrementForumPage(1)}} 
                className={classes.pageChangeButton}
            >
                Next
            </button>                 
        </div>
    );
    

}

const useStyles = makeStyles(() => ({
    pageChangeButtonWrapper: {
        display: "flex", 
        justifyContent: "space-between",
        alignItems: "center",
        margin: "0px auto", 
        width: "300px", 
    },
    pageChangeButton: {
        padding: "20px 40px", 
        backgroundColor: COLORS.pageButtons, 
        border: "0px none", 
        borderRadius: "40px", 
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
        "&:focus": {
            outline: "none"
        }
    }
}))

export default PageButtons