import React, { useState, useEffect } from 'react';
import useFetch from '../../hooks/useFetch.js';
import ForumReply from './ForumReply';

const ForumReplies = (props) => {
    /* -------------------------------------------------------- Props for ForumReplies -----------------------------------------------------------------
    user: obj
    mobileStatus: bool
    refreshPagE: bool
    setRefreshPage: fxn, bool
    topReplyUUID: string
    setParentReplyUUID: fxn, str
    setReplyDialog: fxn, bool
    isPost: bool. if true, top level is a post, otherwise is a reply
    */

    // replies from coordinators
    const [replies, setReplies] = useState([]);
    const [fetchArgs, setFetchArgs] = useState({});
    const { status: DBStatus, data: DBData } = useFetch(fetchArgs);

    const shouldReplyBeRendered = (replyData, parentUUID, layer) => {
        // depending on whether ForumReplies is in the forumpostpage or forumreplypage,
        // the condition for rendering a reply is different.
        if (props.isPost) {
            if (replyData.parentReplyUuid === parentUUID ||
                (replyData.parentReplyUuid === 'null' && layer === 0)) {
                return true
            }
        } else {
            if (replyData.parentReplyUuid === parentUUID) {
                return true
            }
        }
        return false
    };

    const renderReplies = (replyData, parentUUID, layer) => {
        const replyComponentArray = [];
        // look for the items in data that have parent_reply_uuid == parentUUID of this component
        // render them on this level.
        replyData.forEach((element, i) => {
            if (shouldReplyBeRendered(element, parentUUID, layer)) {
                const replyComponent =
                    <div key={i}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: layer * 15 }} />
                            <ForumReply
                                user={props.user}
                                mobileStatus={props.mobileStatus}
                                replyData={element}
                                layer={layer}
                                setParentReplyUUID={props.setParentReplyUUID}
                                setReplyDialog={props.setReplyDialog}
                                refreshPage={props.refreshPage}
                                setRefreshPage={props.setRefreshPage}
                            />
                        </div>
                        {renderReplies(replyData, element.uniqueId, layer + 1)}
                    </div>
                replyComponentArray.push(replyComponent);
            }
        });
        return replyComponentArray;
    };

    // get the data as soon as the component mounts
    useEffect(() => {
        if (props.isPost) {
            setFetchArgs({
                URLquery: '/complexdb/getreplytree',
                body: {
                    parentReplyUUID: null,
                    parentPost: props.topReplyUUID,
                    isTopLevelPost: true
                }
            });
        } else {
            // else if props.topReplyUUID belongs to a reply
            setFetchArgs({
                URLquery: '/complexdb/getreplytree',
                body: {
                    parentReplyUUID: props.topReplyUUID,
                    parentPost: null,
                    isTopLevelPost: false
                }
            });
        }
    }, []);
    useEffect(() => {
        if (DBStatus === 'completed') {
            setReplies(DBData.data);
            setFetchArgs({});
        }
    }, [DBStatus]);

    return (
        <>
            {renderReplies(replies, props.topReplyUUID, props.isPost ? 0 : 1)}
        </>
    )
};

export default ForumReplies;