import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({ 
    coordinatorContainer: {
        display:'flex', 
        flexWrap:"wrap", 
        margin: "20px auto",
        paddingBottom: "60px",
        justifyContent:"center",
    },
}))

export default useStyles;
