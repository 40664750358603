import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SchoolContext } from '../../contexts/CurrentSchoolContext.js';
import { schools } from '../../constants/Constants.js';

const useStyles = makeStyles(() => ({
    // z-index of 21 will prevent the upvote icon with z-index of 20 from forumItems going on top.
    privacyPolicyContainer: {
        textAlign: "center"
    },
}));

const PrivacyPolicy = () => {
    /* -------------------------------------------------------- Props for PrivacyPolicy -----------------------------------------------------------------
    PROPS: none
    */

    const classes = useStyles();
    const schoolCont = useContext(SchoolContext)

    const privacyPolicyURL = schools[schoolCont.school].privacyLink

    return (
        <div className={classes.privacyPolicyContainer}>
            <a href={privacyPolicyURL}>
                Privacy Policy
            </a>
        </div>
    )
}

export default PrivacyPolicy;