import React from 'react';
import { schools } from '../../constants/Constants.js';
import { SchoolContext } from '../../contexts/CurrentSchoolContext.js';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/Colors.js';

import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';

import Hangout from '../../assets/hangout.png';
import STRINGS from '../../constants/Strings.js';

const DashboardLinkButtons = (props) => {

    /* -------------------------------------------------------- Props for AdminSetCalendlyLink -----------------------------------------------------------------
    PROPS:      
    user: obj
    calendlylink: str
    */

    const classes = useStyles();

    // Route changes 
    const goToCalendar = () => {
        window.location = props.calendlyLink;
    }

    const schoolCont = React.useContext(SchoolContext);
    const helplineURL = schools[schoolCont.school].helplineLink;

    const getButtons = (userOrAdmin) => {
        let buttonsArray = [];
        // depending on if we pass "user" or "admin", we will select a different key 
        // in the strings import 
        const stringsKey = `${userOrAdmin}Dashboard`;

        STRINGS[stringsKey].buttonLinks.forEach((item) => {
            const IconTag = item.icon
            const button =                 
            <Link to={item.link} className={classes.boxButton} key={item.label}>
                <Button>
                    <div className={classes.box}>
                        <IconTag className={classes.buttonLinks}/>
                        <p className={classes.boxLabel}>
                            {item.label}
                        </p>
                    </div>
                </Button>
            </Link>

            buttonsArray.push(button);
        });
        return buttonsArray
    }

    // but the user and admin dashboard have one item each that doesn't fit cleanly in the getButtons function,
    // so weh ave it hardcoded for now.
    if (props.userType ==="student") {
        return (
            <div className={classes.buttonContainer}> 
                {getButtons("user")}
                <Link to="" onClick={()=>{window.location = helplineURL}} className={classes.boxButton}>
                    <Button>
                        <div className={classes.box}>
                            <img alt="loading..." src={Hangout} style={{height:"145px", marginTop:"35px", marginBottom:"-15px"}}/>
                            <p className={classes.boxLabel}>
                                Mental Health Resources
                            </p>
                        </div>
                    </Button>
                </Link>
            </div>
        )
    } else {
        return (
            <div className={classes.buttonContainer}> 
                {getButtons("admin")}
                <Button onClick={goToCalendar} className={classes.boxButton}>
                    <div className={classes.box}>
                        <InsertInvitationIcon className={classes.buttonLinks}/>
                        <p className={classes.boxLabel}>
                            View Calendly
                        </p>
                    </div>
                </Button>
            </div>
        )
    }
}

const useStyles = makeStyles(() => ({ 
    buttonContainer: {
        display:"flex", 
        justifyContent:"center", 
        flexWrap:"wrap", 
        marginTop:"35px", 
        marginBottom:"200px",
        width:"calc(1300px)", 
    },
    boxButton: {
        display:"flex", 
        justifyContent:"center", 
        flexWrap:"wrap",
        margin:"calc(10px + 2%)", 
        marginBottom:"30px", 
        marginTop:"40px", 
        padding:"0px", 
        borderRadius:"20px",
        textDecoration: "none",
    },
    box: {
        display:"flex", 
        justifyContent:"center", 
        flexWrap:"wrap",
        backgroundColor:"white", 
        width:"280px", 
        height:"280px", 
        borderRadius:"20px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", 
    },
    buttonLinks: {
        fontSize:"130px", 
        marginTop:"35px", 
        flexBasis:"100%", 
        marginBottom:"0px", 
        color: COLORS.hiPandaHeader
    },
    boxLabel: {
        fontSize:"24px", 
        marginTop:"15px", 
        textTransform:"none", 
        fontWeight:"400", 
        color: COLORS.hiPandaHeader, 
        width:"80%",
    },
}));

export default DashboardLinkButtons;