import React, {useState, useEffect, } from 'react'

import PersonIcon from '@material-ui/icons/Person';
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Calendly from '../Molecule/Calendly';
import CreateForumPost from './CreateForumPost';
import MessageIcon from '@material-ui/icons/Message';


const CoordinatorItem = (props) => {

    /* -------------------------------------------------------- Props for CoordinatorItem -----------------------------------------------------------------

    PROPS:      
    user: Object type from routes.js. containts the authenticated user's info from the db                                                      
    mobileStatus: bool from mainpage.js. true if screensize is less than 'sm'
    coordinatorData: obj with user data for the coordinator
    */

    const classes = useStyles();

    // dialog for calendly widget
    const [calendlyDialogOpen, setCalendlyDialogOpen] = useState(false);
    // dialog for creating a private post
    const [privatePostDialog, setPrivatePostDialog] = useState(false);

    const fullName= `${props.coordinatorData.firstName} ${props.coordinatorData.lastName}`


    const openCalendlyButton = () => {
        // disables button if no link setup
        let button;
        if (props.coordinatorData.calendlyLink === "" || props.coordinatorData.calendlyLink === null || props.coordinatorData.calendlyLink === undefined) {
            button = <></>
        } else {
            button = (
                <Button className={classes.coordinatorSchedule} onClick={() => setCalendlyDialogOpen(true)}>
                    <DateRangeIcon className={classes.scheduleIcon}/>
                </Button>
            );
        }
        return button
    }

    useEffect(() => {
    }, [calendlyDialogOpen, props.mobileStatus])

    // React component for CoordinatorItem
    return (
        <React.Fragment>
            <div key={props.coordinatorData.userID} className={classes.coordinatorDiv}>
                <Button className={classes.coordinatorButton}>
                    <PersonIcon className={classes.coordinatorProfileIcon}/>
                    <p className={classes.coordinatorName}>{fullName}</p>
                </Button>
                <Button className={classes.coordinatorMessage} onClick={() => setPrivatePostDialog(true)}>
                    <MessageIcon style={{fontSize:"28px", color:"gray",}}/>
                </Button>
                <p className={classes.coordinatorDepartment}> 
                    Department: {props.coordinatorData.department} 
                </p>
                {openCalendlyButton()}
            </div>
            <CreateForumPost
                user={props.user}
                type="private-posts"
                dialogTitle={"Ask " + props.coordinatorData.firstName + " a private Question!"} 
                open={privatePostDialog} 
                mobileStatus={props.mobileStatus} 
                setClosed={() => setPrivatePostDialog(false)} 
                caseAdminId={props.coordinatorData.userId} 
            />
            <Calendly 
                open={calendlyDialogOpen} 
                closeDialog={() => setCalendlyDialogOpen(false)} 
                mobileStatus={props.mobileStatus} 
                calendlyLink={props.coordinatorData.calendlyLink}
            />
        </React.Fragment>
    )
}

const useStyles = makeStyles(() => ({ 
    coordinatorDiv: {
        position:"relative",
        display:"flex",
        justifyContent:"center",
        margin:"20px",
        width:"260px", 
        height:"280px", 
        borderRadius:"20px", 
        backgroundColor:"white",  
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    coordinatorProfileIcon: {
        position:"absolute", 
        top:"10px", 
        fontSize:"110px",
    },
    coordinatorDepartment: {
        fontSize:"14.5px", 
        margin: "auto auto 15px",
    },
    coordinatorButton: {
        position:"absolute", 
        top:"30px",
        margin: "5px auto 0px",
        paddingTop:"50px",
        paddingBottom:"30px", 
        width:"180px", 
        minHeight:"160px", 
        borderRadius:"25px", 
    },
    coordinatorSchedule: {
        position:"absolute", 
        right:"10px", 
        bottom:"10px", 
        width:"0px", 
        padding:"0px", 
        minHeight:"35px", 
        minWidth:"35px"
    },
    coordinatorMessage: {
        position:"absolute", 
        left:"13px", 
        bottom:"9px",
        width:"0px", 
        padding:"2px", 
        minHeight:"35px", 
        minWidth:"35px"
    },
    coordinatorName: {
        fontSize:"20px", 
        position:"relative", 
        top:"40px", 
        textTransform:"none", 
        marginTop:"25px",
    },
    scheduleIcon: {
        fontSize:"30px", 
        color:"gray"
    },
}))

export default CoordinatorItem;