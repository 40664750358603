import React from 'react';
import useStyles from './styles';
import Panda from '../../assets/panda.png';
import STRINGS from '../../constants/Strings';

const FourZeroFour = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <img className={classes.panda} alt="" src={Panda}/>
            <div className={classes.text}>
                {STRINGS.pageNotFound.mainText}
            </div>
        </div>
    )
}

export default FourZeroFour