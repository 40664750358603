import React from 'react';
import useStyles from './styles';
import STRINGS from '../../constants/Strings';
import CopyButton from '../../components/Atom/CopyButton';

const Feedback = () => {
    const classes = useStyles();

    const copyEmail = async () => {
        await navigator.clipboard.writeText("support@hiride.ca");
    };

    return (
        <div className={classes.container}>
            <div>
                {STRINGS.feedback.mainText}
                <a href="mailto:support@hiride.ca">
                    {STRINGS.feedback.emailAddress}
                </a>
            </div>
            <CopyButton
                text='Copy Email'
                clickFunction={copyEmail}
            />
        </div>
    );
};

export default Feedback;