import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/Colors';

const useStyles = makeStyles(() => ({
	appBar: {
		position: 'relative',
	},
	backButton: {
		backgroundColor: COLORS.landingBackground,
		opacity: "0.75",
		borderRadius: "10px",
		border: "none",
		boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
		outline: "none",
		"&:hover": {
			backgroundColor: COLORS.hiPandaHeader,
			color: "white"
		}
	},
	bottomLinks: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		gap: "10px",
	},
	container: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		gap: "20px",
		height: "100vh",
		width: "100vw",
		backgroundColor: COLORS.signUpHover,
	},
	title: {
		margin: '15px 0px',
		color: COLORS.hiPandaHeader,
		fontSize: "clamp(36px, 4vw, 48px)"
	},
}));

export default useStyles;
