import React, { useState, useEffect } from 'react'
import useFetch from '../../hooks/useFetch.js';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/Colors';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import AddHyperlink from '../Molecule/AddHyperlink.js';


const CreateReply = (props) => {

    /* -------------------- Props for CreateReply ---------------------
    type: str
    user: obj
    parentPostID: str
    parentReplyUUID: str
    open: bool, if true, this component is displayed
    setClosed: fxn, changes props.open
    setRefreshPage: fxn
    refreshPage: bool
    */
    const classes = useStyles();

    const [newReply, setNewReply] = useState('');
    const [replyConfirmationDialog, setReplyConfirmationDialog] = useState(false);

    // error notifcation for when the submitting a reply without content
    const [noReplyContent, setNoReplyContent] = useState(false);
    const [addHyperlinkOpen, setAddHyperlinkOpen] = useState(false);
    const [isAnon, setIsAnon] = useState(false);

    const [fetchArgs, setFetchArgs] = useState({});
    const { status: dbCallStatus } = useFetch(fetchArgs);

    const toggleAnonymous = () => {
        setIsAnon(!isAnon);
    };

    const writeReplyToDb = () => {
        const replyType = props.type === 'public-replies' ? 'public' : 'private';
        let verified = true;

        // we need replies to be verified if they are made by a student towards a public post
        // since only these types of replies need verification, only they need the confirmation dialog
        if (props.user.userType === 'student' && replyType === 'public') {
            verified = false;
            setReplyConfirmationDialog(true);
        };
        setFetchArgs({
            URLquery: '/db/replies/create/single/',
            body: {
                publisherId: props.user.userId,
                content: newReply,
                datePublished: new Date(),
                postId: props.parentPostId,
                anonymous: isAnon,
                type: replyType,
                verified: verified,
                parentReplyUUID: props.parentReplyUUID
            }
        });
        setNewReply('');
    };

    // function for submitting a reply to firebase
    const submitReply = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        // check if submitting an emply reply
        if (newReply === "" || !newReply) {
            setNoReplyContent(true);
        } else {
            writeReplyToDb()
            props.setClosed();
        }
    };

    // React component for making a reply 
    const replyTextField = () => {
        return (
            <>
                <Dialog
                    PaperProps={{
                        style: {
                            width: "80vw",
                            minWidth: "300px",
                        }
                    }}
                    onClick={(e) => {
                        if (e.target.id !== "replyInput") {
                            setNoReplyContent(false);
                        }
                    }}
                    maxWidth="sm"
                    minWidth="sm"
                    style={{ marginLeft: "auto", marginRight: "auto", marginTop: "-12vh", marginBottom: "0%", }}
                    open={props.open}
                    aria-labelledby="max-width-dialog-title">
                    <DialogTitle className={classes.dialogTitle}>
                        Submit a Reply
                    <Button onClick={props.setClosed} className={classes.closeButton}>
                            <CloseIcon />
                        </Button>
                    </DialogTitle>
                    <form
                        id="create-public-reply"
                        onSubmit={submitReply}
                        className={classes.replyForm}
                    >
                        <TextField
                            id="replyInput"
                            error={noReplyContent}
                            variant="outlined"
                            label="Write a reply!"
                            onChange={e => setNewReply(e.target.value)}
                            value={newReply}
                            multiline rows={5}
                            helperText={noReplyContent ? "This field is required." : ""}
                            className={classes.replyFormTextField}
                        />
                        <div className={classes.buttonContainer}>
                            {props.type === "public-replies" && props.user.userType !== 'student' ?
                                <Button
                                    variant="outlined"
                                    onClick={() => { setAddHyperlinkOpen(true) }}
                                >
                                    Add Link
                                </Button>
                                :
                                <></>
                            }
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch checked={isAnon} onChange={toggleAnonymous} color="primary" />}
                                    label="Hide Name"
                                />
                            </FormGroup>
                            <Button type="submit" className={classes.submitButton}>
                                Submit
                            </Button>
                        </div>
                        <AddHyperlink
                            open={addHyperlinkOpen}
                            setOpen={setAddHyperlinkOpen}
                            newReply={newReply}
                            setNewReply={setNewReply}
                        />
                    </form>
                </Dialog>
                <Dialog
                    PaperProps={{
                        style: { width: "82vw", minWidth: "300px", }
                    }}
                    onClick={(e) => {
                        console.log('hi');
                    }}
                    maxWidth="xl"
                    minWidth="sm"
                    style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "0%", }}
                    open={replyConfirmationDialog}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle>
                        <p className={classes.postDialogTitle}>
                            Thank you for replying. Your reply will be verified by our coordinators before being published.
                        </p>
                    </DialogTitle>
                    <Button onClick={() => setReplyConfirmationDialog(false)} className={classes.postConfirmDialogContent}>
                        OK
                    </Button>
                </Dialog>
            </>
        )
    }

    useEffect(() => {
        if (dbCallStatus === 'error') {
            console.log('error')
        } else if (dbCallStatus === 'completed') {
            // change the refreshPage prop so that the parent component will rerender 
            props.setRefreshPage(!props.refreshPage);
            setFetchArgs({});
        }
    }, [dbCallStatus])

    return (
        <>
            {replyTextField()}
        </>
    )
}

const useStyles = makeStyles(() => ({
    dialogTitle: {
        marginTop: "20px",
        textAlign: "center",
    },
    closeButton: {
        position: "absolute",
        top: "33.5px",
        right: "25px",
        minWidth: "25px",
    },
    postDialogTitle: {
        margin: '0px',
        textAlign: 'center'
    },
    postConfirmDialogContent: {
        margin: "0px auto 10px",
        width: "fit-content",
        backgroundColor: COLORS.postButton,
    },
    replyForm: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    replyFormTextField: {
        position: "relative",
        margin: "20px",
        marginTop: "15px",
        marginBottom: "25px",
        borderRadius: "10px"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-evenly",
        paddingBottom: "20px"
    },
    submitButton: {
        paddingLeft: "10px",
        paddingRight: "10px",
        width: "30px",
    }
}))

export default CreateReply;