const MILLISECS_IN_DAY = 1000 * 60 * 60 * 24;
export const dateFilterToMillisecsMap = { 
    'all': Date.now(),
    'day': MILLISECS_IN_DAY,
    'week': MILLISECS_IN_DAY * 7,
    'month': MILLISECS_IN_DAY * 30,
    'year': MILLISECS_IN_DAY * 365
};

export const defaultTags = {
    'wellness': false,
    'tManagement': false,
    'stressPanic': false,
    'productivity': false,
    'social': false
};

export const backendApi = {
    development: "http://localhost:4000",
    staging: 'https://hipanda-stg.hiride.net',    //AWS backend server.
    production: 'https://hipanda-prod.hiride.net'  //AWS backend server
};

export const validEmailDomains = [
    'facedrive.com', 
    'hiride.ca', 
    'uwaterloo.ca'
];

export const schools = {
    // the key name of each object needs to be the same as the first term in subdomain eg: uwaterloo.hipanda.ca, staging.hipanda.ca, etc. 
    // localhost:3000 works because localhost is the first term in the url
    localhost: {
        name: "University of Waterloo",
        emailDomain: "@uwaterloo.ca",
        privacyLink: "https://uwaterloo-legal.hipanda.ca",
        helplineLink: "https://uwaterloo.ca/campus-wellness/urgent-help-and-emergency-contacts",
        departments: {
            'architecturalEngineering': 'test',
            'architecture': 'testabc',
            'biomedicalEngineering': 'test Engineering',
            'systemsDesignEngineering': 'Systems test Engineering',
        }
    },
    staging: {
        name: "Staging Server",
        emailDomain: "@uwaterloo.ca",
        privacyLink: "https://uwaterloo-legal.hipanda.ca",
        helplineLink: "https://uwaterloo.ca/campus-wellness/urgent-help-and-emergency-contacts",
        departments: {
            'architecturalEngineering': 'Architectural Engineering',
            'architecture': 'Architecture',
            'biomedicalEngineering': 'Biomedical Engineering',
            'chemicalEngineering': 'Chemical Engineering',
            'civilEngineering': 'Civil Engineering',
            'computerEngineering': 'Computer Engineering',
            'electricalEngineering': 'Electrical Engineering',
            'environmentalEngineering': 'Environmental Engineering',
            'geologicalEngineering': 'Geological Engineering',
            'managementEngineering': 'Management Engineering',
            'mechanicalEngineering': 'Mechanical Engineering',
            'mechatronicsEngineering': 'Mechatronics Engineering',
            'nanotechnologyEngineering': 'Nanotechnology Engineering',
            'softwareEngineering': 'Software Engineering',
            'systemsDesignEngineering': 'Systems Design Engineering',
        },
    },
    mcmasterUniversity: {
        name: "McMaster University",
        emailDomain: "",
        privacyLink: "",
        helplineLink: ""
    },
    ryersonUniversity: {
        name: "Ryerson University",
        emailDomain: "",
        privacyLink: "",
        helplineLink: ""
    },
    universityOfToronto: {
        name: "University of Toronto",
        emailDomain: "@utoronto.ca",
        privacyLink: "",
        helplineLink: ""
    },
    uwaterloo: {
        name: "University of Waterloo",
        emailDomain: "@uwaterloo.ca",
        privacyLink: "https://uwaterloo-legal.hipanda.ca",
        helplineLink: "https://uwaterloo.ca/campus-wellness/urgent-help-and-emergency-contacts",
        departments: {
            'architecturalEngineering': 'Architectural Engineering',
            'architecture': 'Architecture',
            'biomedicalEngineering': 'Biomedical Engineering',
            'chemicalEngineering': 'Chemical Engineering',
            'civilEngineering': 'Civil Engineering',
            'computerEngineering': 'Computer Engineering',
            'electricalEngineering': 'Electrical Engineering',
            'environmentalEngineering': 'Environmental Engineering',
            'geologicalEngineering': 'Geological Engineering',
            'managementEngineering': 'Management Engineering',
            'mechanicalEngineering': 'Mechanical Engineering',
            'mechatronicsEngineering': 'Mechatronics Engineering',
            'nanotechnologyEngineering': 'Nanotechnology Engineering',
            'softwareEngineering': 'Software Engineering',
            'systemsDesignEngineering': 'Systems Design Engineering',
        }
    },
    westernUniversity: {
        name: "Western University",
        emailDomain: "",
        privacyLink: "",
        helplineLink: ""
    },
    wilfridLaurierUniversity: {
        name: "Wilfrid Laurier University",
        emailDomain: "",
        privacyLink: "",
        helplineLink: ""
    },
    yorkUniversity: {
        name: "York University",
        emailDomain: "",
        privacyLink: "",
        helplineLink: ""
    },
};