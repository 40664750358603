import React, { useEffect, useState } from 'react'
import { withRouter, useLocation } from 'react-router-dom'
import STRINGS from '../../constants/Strings';
import { makeStyles } from '@material-ui/core/styles';

import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import Drawer from '@material-ui/core/Drawer';

const useStyles = makeStyles(() => ({
    navLabel: {
        fontSize: "13px",
        width: "100%",
        marginLeft: "0px",
    },
    mobileDrawerPaper: {
        height: "75px",
        display: "flex",
        alignItems: "center",
        width: "100%",
    },
}))

// bottom navigation for when users are in mobile view
const MobileNav = (props) => {
    const classes = useStyles();

    // determines the current page which provides the value for the bottom navigation menu when user switches into mobile view
    const [currentPage, setCurrentPage] = useState(useLocation().pathname);

    // function for changing routes
    const setPage = (route) => {
        props.history.push(route);
    }

    // bottom navigation React component
    const getNavItems = () => {
        let navItemsComponent = [];
        let key;
        if (props.user.userType === 'student') {
            key = 'student';
        } else {
            key = 'admin'
        }
        STRINGS.navbar.mobile[key].forEach(item => {
            navItemsComponent.push(
                <BottomNavigationAction
                    style={{ width: `calc(100vw / ${STRINGS.navbar.mobile.length})`, minWidth: "18vw", height: "70px" }}
                    label={<span className={classes.navLabel}>{item.label}</span>}
                    value={item.route}
                    key={item.route}
                    icon={item.icon} 
                />
            )
        })
        return navItemsComponent;
    }

    // changes currentPage value and current route when user reroutes using mobile navigation menu
    const handleChange = (event, newValue) => {
        setCurrentPage(newValue);
        setPage(newValue);
    }

    useEffect(() => { }, [currentPage])

    // React Component for MobileNav
    const mobileNav = () => {
        if (props.mobileStatus === true) {
            return (
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: classes.mobileDrawerPaper,
                    }}
                    anchor="bottom"
                >
                    <BottomNavigation style={{ margin: "0px", padding: "0px", width: "95%", }} value={currentPage} onChange={handleChange}>
                        {getNavItems()}
                    </BottomNavigation>
                </Drawer>
            )
        }
    }

    useEffect(() => { }, [props.mobileStatus])

    return (
        <React.Fragment>
            {mobileNav()}
        </React.Fragment>
    )
}

export default withRouter(MobileNav);