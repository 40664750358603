import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import UserList from '../../components/Organism/UserList';

const Messaging = (props) => {
    /* -------------------------------------------------------- Props for Messaging -----------------------------------------------------------------
    user: userObject
    messageRecipient: Obj
    setMessageRecipient: fxn      
    */


    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{fontSize: '20px', textAlign: 'center'}}> 
                {props.user.userType === 'student' ? 
                'Pick a coordinator to message' 
                : 'Message a student'
                }
            </div>
            <UserList
                user={props.user}
                setMessageRecipient={props.setMessageRecipient}
            />
        </div>
    )
};

export default withRouter(Messaging);