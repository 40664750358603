import React, { useEffect } from 'react'
import { COLORS } from '../../constants/Colors';
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';

const InboxItem = (props) => {
    /* -------------------------------------------------------- Props for InboxItem -----------------------------------------------------------------
    PROPS:      
        
    user: obj, contains user data
    docdata: contains data for the post
    mobileStatus: bool 
    */

    const classes = useStyles();
    const doc = props.docdata;

    // we want to get the most recent reply in the conversation and show it as a preview
    const getInboxItem = () => {
        if (doc) { // check to see if doc is retrieved
            // information from doc
            const title = (doc.title)
            const userName = doc.firstName;

            // retrieve replies for the post so we can show the most recent one for a preview
            let reply = (doc.content)

            return (
                <div className={classes.inboxItemWrapper} onClick={(e) => changeRoute(e)}>
                    <div className={classes.header}>
                        <Avatar className={classes.avatar} alt={userName} src="/static/images/avatar/1.jpg" />
                        <div className={classes.inboxItemTitleWrapper}>
                            <div className={classes.inboxItemTitleAuthor}>
                                {userName}
                            </div>
                            <div className={classes.date}>
                                {new Date(doc.datePublished).toString().slice(4, 15)}
                            </div>
                            <span className={classes.inboxItemTitleAskResponse}>
                                Topic:
                                <span style={{ fontWeight: "500" }}>{` "` + title + `"`}</span>
                            </span>
                        </div>
                    </div>
                    <div className={classes.inboxItemContent}>
                        {reply}
                    </div>
                </div>
            );
        }
    }

    // change route when user clicks on an InboxItem to InboxItemPage
    const changeRoute = (e) => {
        if (window.getSelection().toString().length === 0) {
            if (props.user.userType !== 'student') {
                props.history.push(`/coordinator_inbox/post/${doc.postUrlPath}`)
            } else {
                props.history.push(`/inbox/post/${doc.postUrlPath}`)
            }
        }
    }
    useEffect(() => {
        getInboxItem();
    }, [doc, props.mobileStatus])

    return (
        <React.Fragment>
            {getInboxItem()}
        </React.Fragment>
    )
}


const useStyles = makeStyles(() => ({
    inboxItemWrapper: {
        display: "flex",
        flexDirection: "column",
        minHeight: "120px",
        padding: "15px",
        margin: "15px auto",
        cursor: "pointer",
        backgroundColor: COLORS.postBackground,
        boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19)"
    },
    header: {
        display: "flex",
        gap: "10px"
    },
    date: {
        fontSize: "clamp(14px, 4vw, 20px)",
    },
    avatar: {
        width: "calc(44px + 1vw)",
        height: "calc(44px + 1vw)",
        backgroundColor: "white",
        color: "black",
    },
    inboxItemContent: {
        maxHeight: "100px",
        margin: "20px 0",
        fontSize: "clamp(12px, 2vw, 14px)",
        overflowY: "hidden",
        textOverflow: "ellipsis",
    },
    inboxItemTitleWrapper: {
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
    },
    inboxItemTitleAuthor: {
        fontSize: "clamp(16px, 2vw, 22px)",
        fontWeight: "500",
    },
    inboxItemTitleAskResponse: {
        fontSize: "clamp(16px, 2vw, 22px)",
        fontWeight: "100",
        textOverflow: "ellipsis",
    },
}))

export default withRouter(InboxItem)