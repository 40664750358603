import React, { useState, useEffect } from 'react'
import useFetch from '../../hooks/useFetch.js';

import { withRouter, useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ReplyToPublicPostButton from '../Atom/ReplyToPublicPostButton.js';
import UpvoteButton from '../Atom/UpvoteButton.js';
import ChangePost from '../Molecule/ChangePost';

const ForumParentPost = (props) => {
    /* -------------------------------------------------------- Props for ForumParentPost -----------------------------------------------------------------

    PROPS:      
        user: obj,
        docdata: obj, contains data about the post from the database
        setReplyDialog: fxn, changes the state of reply dialog in parent component
        mobileStatus, bool
        navStatus: bool
        setParentReplyUUID: fxn
    */
    const classes = useStyles(props.mobileStatus);
    const history = useHistory();

    // doc acquired from prop
    const doc = props.docdata.body;

    // information about the post 
    const [upvote, setUpvote] = useState(null);
    const [upvoteCount, setUpvoteCount] = useState(null);

    const [fetchArgs, setFetchArgs] = useState({});
    const { status: DBStatus } = useFetch(fetchArgs);

    const changeUpvote = async (e) => {
        e.stopPropagation();
        if (upvote) {
            // if the user has already upvoted, then undo the upvote
            setFetchArgs({
                URLquery: '/db/upvotes/delete/deleteByPostIdUserId/',
                body: {
                    userId: props.user.userId,
                    postId: doc.id
                }
            })
            // update the upvote & upvoteCount states
            setUpvote(!upvote);
            setUpvoteCount(Number(upvoteCount) - 1);
        } else {
            // if the user hasn't upvoted, then add the upvote
            setFetchArgs({
                URLquery: '/db/upvotes/create/single/',
                body: {
                    userId: props.user.userId,
                    postId: doc.id
                }
            })
            // update the upvote & upvoteCount states
            setUpvote(!upvote);
            setUpvoteCount(Number(upvoteCount) + 1);
        }
    };

    // Change post functions (passes the information for editing and the function for deleting to the ChangePost component)
    const changePost = () => {
        if (props.user.userId === doc.publisherId || props.user.userType === 'admin' || props.user.userType === 'coordinator') { // if the user is the publisher of the post, then provide edit/delete options

            // function for deleting a post and its replies in firebase
            const deletePost = () => {
                setFetchArgs({
                    URLquery: '/db/posts/delete/single', body: {
                        id: doc.id
                    }
                });
                history.goBack();
            }
            
            return (
                <ChangePost 
                    user={props.user} 
                    docID={doc.id} edit={{}}
                    deletePost={deletePost} 
                    mobileStatus={props.mobileStatus} 
                    navStatus={props.navStatus}
                    canEdit={false} 
                    canDelete={true} 
                    canVerify={false}
                />
            )
        } else {
            // if user is not the publisher, don't allow anyhting
            return (
                <></>
            )
        }
    };

    const handleReplyButton = () => {
        props.setParentReplyUUID(null);
        props.setReplyDialog(true);
    };

    // refresh the component when these particular states are changed
    useEffect(() => {
        setUpvote(doc.likedByUser);
        setUpvoteCount(doc.totalLikes);
    }, [])

    return (
        <div className={classes.postContainer}>
            <div className={classes.postHeader}>
                <Avatar className={classes.avatar} alt={doc.firstName} src="/static/images/avatar/1.jpg" />
                <div className={classes.content}>
                    <div className={classes.name}>
                        {`${doc.firstName} ${doc.lastName}`}
                    </div>
                    <div className={classes.date}>
                        {new Date(doc.datePublished).toString().slice(4, 15)}
                    </div>
                </div>
            </div>
            <div className={classes.postBody}>
                <div className={classes.title}>{doc.title}</div>
                <div className={classes.postContent}>
                    {doc.content}
                </div>
            </div>
            <div className={classes.postFooter}>
                <UpvoteButton
                    mobileStatus={props.mobileStatus}
                    upvoteCount={upvoteCount}
                    changeUpvote={changeUpvote}
                    upvote={upvote}
                />
                <ReplyToPublicPostButton
                    mobileStatus={props.mobileStatus}
                    onClick={handleReplyButton}
                />
                <div style={!props.mobileStatus ? { marginBottom: "11px", marginLeft: "auto", marginRight: "20px", } : {}}>
                    {changePost()}
                </div>
            </div>
        </div>
    )
}


const useStyles = makeStyles(() => ({
    avatar: {
        width: "calc(45px + 1vw)",
        height: "calc(45px + 1vw)",
        backgroundColor: "white",
        color: "black",
    },
    content: {
        display: "flex",
        flexDirection: 'column'
    },
    name: {
        fontWeight: "600",
        fontSize: "20px",
    },
    date: {
        flexBasis: "100%",
        fontSize: "15px",
    },
    postBody: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
    },
    postContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        padding: "15px",
        width: "100%",
    },
    postContent: {
        fontSize: "clamp(12px, 2vw, 14px)",
    },
    postHeader: {
        display: "flex",
        gap: "10px",
    },
    title: {
        fontSize: "clamp(16px, 4vw, 26px)"
    },
    postFooter: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },
}
))


export default withRouter(ForumParentPost)