import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import PhoneHelpLine from "../../components/Atom/phonehelpline";
import EmailConfirmation from '../../components/Atom/emailconfirmation';
import SignupForm from '../../components/Organism/SignupForm';
import PrivacyPolicy from '../../components/Atom/PrivacyPolicy';

import { withRouter } from 'react-router-dom'
import Panda from '../../assets/panda.png';
import withWidth from '@material-ui/core/withWidth';

const Landing = (props) => {
	const classes = useStyles();

	const [isEmailMessageOpen, setIsEmailMessageOpen] = useState(false);
	const [mobileStatus, setMobileStatus] = useState(false);

	const [emailMessage, setEmailMessage] = useState("accountCreateSuccess")
	const { width } = props;

	const handleClose = () => {
		setIsEmailMessageOpen(false);
	};
	const changeRoute = (e) => {
		e.preventDefault();
		props.history.push("/signin")
	};



	useEffect(() => {
		if (width === 'sm' || width === 'xs') {
			setMobileStatus(true);
		} else {
			setMobileStatus(false);
		}
	}, [mobileStatus, width])

	return (
		<div className={classes.container}>
			<div className={classes.header}>
				<img className={classes.panda} alt="" src={Panda} />
				<h1 className={classes.title}>
					Welcome to HiPanda
				</h1>
			</div>
			<div className={classes.content}>
				<SignupForm
					setEmailMessage={setEmailMessage}
					setIsEmailMessageOpen={setIsEmailMessageOpen}
				/>
				<a href={'/login'} onClick={changeRoute}>
					Already registered? Login here
				</a>
				<PrivacyPolicy
					currentSchool={props.currentSchool}
				/>
				<PhoneHelpLine />
			</div>
			<EmailConfirmation
				open={isEmailMessageOpen}
				handleClose={handleClose}
				classes={classes.appBar}
				message={emailMessage}
			/>
		</div>
	)
}

export default withWidth()(withRouter(Landing));