import React from 'react'
import { COLORS } from '../../constants/Colors';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';


const UpvoteButton = (props) => {
    /* -------------------------------------------------------- Props for UpvoteButton -----------------------------------------------------------------

    PROPS:      
        upvoteCount: num
        upvote: bool, if true it means the user has upvoted the parent component
        changeUpvote: changes state of upvote
    */
    const classes = useStyles();

    const getButtonStyle = (upvote) => {
        if(upvote) {
            return {
                color:"#426ff5", 
            }
        } else {
            return {
                color:"#ffffff",
            };
        }
    }

    return (
        <Button 
            id="upvoteButton" 
            className={classes.upvoteButton} 
            onClick={props.changeUpvote}
        >
            <ThumbUpIcon style={getButtonStyle(props.upvote)} className={classes.thumbUpIcon}/>
            <div className={classes.upvoteCount}>
                {props.upvoteCount}
            </div>
        </Button>
    ) 
}

const useStyles = makeStyles(() => {
    return {
        upvoteButton: {
            display:"flex", 
            justifyContent:"left", 
            padding:"10px",
            marginRight:"10px", 
            minWidth:"0px", 
            height:"35px", 
            minHeight:"35px",
            backgroundColor: COLORS.postButtons,
            borderRadius:"20px",
        },
        thumbUpIcon: {
            zIndex:"20",
            fontSize:"20px",
            marginLeft:"5px",
        },
        upvoteCount: {
            marginLeft:"5px",
            marginTop:"2px", 
            padding:"8px",
            fontSize:"17px", 
        },
    }
})

export default UpvoteButton;