import React, { createContext, useState} from 'react';

const SchoolContext = createContext({});

const SchoolContextProvider = (props) => {

    const [school, setSchool] = useState("");
    // dev stage is used to determine the api address in constants/backendApi
    const [devStage, setDevStage] = useState("");
    const [schoolId, setSchoolId] = useState();
    const schoolObj = {
        devStage: devStage,
        school: school,
        schoolId: schoolId,
        setDevStage: setDevStage,
        setSchool: setSchool,
        setSchoolId: setSchoolId,
    };

    return (
        <SchoolContext.Provider value={schoolObj}>
            {props.children}
        </SchoolContext.Provider>
    )
}

export { SchoolContext, SchoolContextProvider }