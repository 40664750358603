import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/Colors';
import { backendApi, schools, validEmailDomains } from '../../constants/Constants';
import { SchoolContext } from '../../contexts/CurrentSchoolContext';
import SelectInput from './SelectInput';

import app from "../../firebase/Firebase";
import TextField from '@material-ui/core/TextField';

const SignupForm = (props) => {
    /* -------------------------------------------------------- Props for SignupForm -----------------------------------------------------------------
  currentschool: str
  setEmailMessage: fxn, string
  setIsEmailMessageOpen: fxn, bool
  */
    const classes = useStyles();

    const currentSchoolCont = useContext(SchoolContext);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [departmentSelection, setDepartmentSelection] = useState('');

    const capitalizeFirstLetter = (name) => {
        let formattedName = name[0].toUpperCase() + name.substr(1, name.length).toLowerCase();
        return formattedName
    };

    const handleErrorCode = (errorCode) => {
        const errorMsgs = {
            'auth/email-already-in-use': 'Account already exists',
            'auth/invalid-email': 'Invalid email',
        };
        setErrorMessage(errorMsgs[errorCode]);
    };

    const isEmailInvalid = (email, validDomains) => {
        const emailDomain = email.split('@')[1];
        return !validDomains.includes(emailDomain);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isEmailInvalid(email, validEmailDomains)) {
            setErrorMessage('Invalid School Email');
            return
        };
        if (departmentSelection === '') {
            setErrorMessage('Department Selection Required');
            return
        }

        if (password === passwordConfirm && firstName.length > 0 && lastName.length > 0) {
            if (password.length < 6) {
                // firebase has a 6 character minimum for password
                setErrorMessage('Password too short');
                return
            }
            try {
                const userCredential = await app.auth().createUserWithEmailAndPassword(email, password);
                const actionCodeSettings = {
                    url: window.location.href
                };
                await userCredential.user.sendEmailVerification(actionCodeSettings);

                const organizationRes = await fetch(`${backendApi[currentSchoolCont.devStage]}/auth/getschoolid`, {
                    method: 'POST',
                    body: JSON.stringify({ name: currentSchoolCont.school }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (organizationRes.status === 200) {
                    const orgData = await organizationRes.json();
                    const orgId = orgData.body[0].id;
                    // create entry in SQL, in the auth and user tables
                    const newAccountRes = await fetch(`${backendApi[currentSchoolCont.devStage]}/auth/createaccount`, {
                        method: 'POST',
                        body: JSON.stringify({
                            firstName: capitalizeFirstLetter(firstName),
                            lastName: capitalizeFirstLetter(lastName),
                            email: email,
                            organizationId: orgId,
                            dateRegistered: new Date(),
                            blacklist: false,
                            department: departmentSelection
                        }),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    if (newAccountRes.status === 200) {
                        props.setEmailMessage('accountCreateSuccess');
                        props.setIsEmailMessageOpen(true);
                    } else if (newAccountRes.status === 409) {
                        //409 is status code for existing resource
                        props.setEmailMessage("errorExistingEmail");
                    } else {
                        throw new Error('Database Error, unable to create account');
                    }
                } else {
                    throw new Error('Database Error, unable to verify organization');
                }
            } catch (err) {
                console.log(err);
                if (err.code) {
                    handleErrorCode(err.code);
                }
            }
        } else if (firstName.length === 0 || lastName.length === 0) {
            setErrorMessage('Name cannot be blank');
        } else {
            setErrorMessage('Passwords don\'t match');
        }
    };
    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };
    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };
    const handleEmailChange = (e) => {
        setEmail(e.target.value.toLowerCase());
    };
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };
    const handlePasswordConfirmChange = (e) => {
        setPasswordConfirm(e.target.value);
    };

    return (
        <form
            onSubmit={handleSubmit}
            className={classes.signupForm}
        >
            <div
                style={{ color: 'red' }}
            >
                {errorMessage}
            </div>
            <TextField
                variant="outlined"
                type='text'
                placeholder='First name'
                value={firstName}
                onChange={handleFirstNameChange}
            />
            <TextField
                variant="outlined"
                type='text'
                placeholder='Last name'
                value={lastName}
                onChange={handleLastNameChange}
            />
            <SelectInput
                selectionValue={departmentSelection}
                setSelectionValue={setDepartmentSelection}
                selectionItems={schools[currentSchoolCont.school].departments}
                placeholder={'Department'}
            />
            <TextField
                variant="outlined"
                type='email'
                placeholder='Email'
                value={email}
                onChange={handleEmailChange}
            />
            <TextField
                variant="outlined"
                type='password'
                placeholder='Password'
                value={password}
                onChange={handlePasswordChange}
            />
            <TextField
                variant="outlined"
                type='password'
                placeholder='Confirm Password'
                value={passwordConfirm}
                onChange={handlePasswordConfirmChange}
            />
            <button
                type="submit"
                className={classes.signupButton}
            >
                Submit
			</button>
        </form>
    )
};

const useStyles = makeStyles(() => ({
    entryField: {
        paddingLeft: '15px',
        height: "55px",
        fontSize: "22px",
        borderRadius: "12px",
        borderWidth: "5px",
        borderColor: COLORS.hiPandaHeader,
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        "&:hover": {
            backgroundColor: COLORS.signUpHover
        }
    },
    signupForm: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "15px",
        padding: "0px 5px",
        marginBottom: "10px",
        textAlign: "center",
    },
    signupButton: {
        width: "100%",
        height: "40px",
        border: "0px",
        borderRadius: "10px",
        backgroundColor: COLORS.landingBackground,
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        outline: "none",
        fontSize: "24px",
        "&:hover": {
            color: "white",
            backgroundColor: COLORS.hiPandaHeader
        },
    },
}))

export default SignupForm;