import { backendApi } from '../constants/Constants.js';

export const setUserObject = async (setUser, devStage) => {
    // check if refresh token exists and is valid. if yes, create a user object and setUser to it.
    // otherwise, set user object to null so that the user is redirected to the signin page.
    try {
        const isLoggedIn = await fetch(`${backendApi[devStage]}/auth/refresh`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                refreshToken: window.localStorage.getItem('refreshToken'),
                rftCreateDate: window.localStorage.getItem('rftCreateDate'),
            })
        })
        if (isLoggedIn.status === 200) {
            const isLoggedInRes = await isLoggedIn.json();
            const secondaryId = isLoggedInRes.body.secondaryId;
            const userDataRes = await fetch(`${backendApi[devStage]}/auth/getUserBySecondaryId`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({secondaryId: secondaryId})
            })
            if (userDataRes.status === 200) {
                const userData = await userDataRes.json();
                let userObject = {
                    firstName: userData.body[0].firstName,
                    lastName: userData.body[0].lastName,
                    email: userData.body[0].email,
                    userId: userData.body[0].userId,
                    userType: userData.body[0].userType,
                    registrationDate: userData.body[0].dateRegistered,
                    organizationId: userData.body[0].organizationId,
                    department: userData.body[0].department
                };
                setUser(userObject);
            }
        } else {
            // if refresh token doesnt exist, prompt user to go back to sign in to get it again
            // null user will tell the app to route to login page
            window.localStorage.clear();
            setUser(null); 
        }
    
    } catch(err) {
        throw new Error(err);
    }
} 
