import React, { useContext, useState, useEffect } from 'react';
import useStyles from './styles';
import useFetch from '../../hooks/useFetch.js';
import CoordinatorItem from '../../components/Organism/CoordinatorItem';
import { SchoolContext } from '../../contexts/CurrentSchoolContext.js';

const Coordinators = (props) => {
    const classes = useStyles();
    const schoolCont = useContext(SchoolContext);

    // array containing coordinator items
    const [coordinators, setCoordinators] = useState([]);

    const [fetchArgs, setFetchArgs] = useState({});
    const { status: dbCallStatus, data: dbCallData, error: dbCallError } = useFetch(fetchArgs);

    // function to fetch coordinators from Firebase then produce an array of CoordinatorItems
    const getCoordinators = () => {
        let coordinatorArray = [];

        if (dbCallStatus === 'completed') {
            dbCallData.body.forEach((coordinator) => {
                coordinatorArray.push(
                    <CoordinatorItem
                        key={coordinator.userId}
                        user={props.user}
                        mobileStatus={props.mobileStatus}
                        coordinatorData={coordinator}
                    />
                );
            });
            setCoordinators(coordinatorArray)

        } else if (dbCallStatus === 'error') {
            console.log(dbCallError)
            console.log(dbCallData)
        } else {
            return;
        }
    }

    useEffect(() => {
        setFetchArgs({
            URLquery: '/db/coordinator/get/selectCoordinators/',
            body: {
                organizationId: schoolCont.schoolId
            }
        });
    }, [])

    useEffect(() => {
        if (dbCallStatus === 'completed') {
            getCoordinators();
        }
    }, [dbCallStatus])

    // React component for Coordinators (Forms, filters, search bar, {coordinators} array)
    return (
        <div className={classes.coordinatorContainer}>
            {coordinators}
        </div>
    )
}

export default Coordinators
