import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/Colors';

const useStyles = makeStyles(() => (
    {
        container: {
            position: "relative",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: "200px",
        },
        componentContainer: {
            position: "relative",
            display: "flex",
            flexDirection: 'column',
            flexWrap: "wrap",
            minHeight: "45px",
            width: "100%",
            marginBottom: "calc(15px + 1vh)",
            backgroundColor: COLORS.postBackground,
            boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19)",
        },
    }
));

export default useStyles;