import React, { useState, useEffect, useContext } from 'react';
import './App.css';
import Routes from './routing/Routes';
import { BrowserRouter as Router,  } from 'react-router-dom';

import { setUserObject } from './utilities/SetUserObject.js';
import { currentSchool, setTitle } from './utilities/GetHostname.js';
import { LoadContextProvider } from './contexts/LoadingContext.js';
import { SchoolContext } from './contexts/CurrentSchoolContext.js';
import { backendApi, schools } from './constants/Constants.js';

const App = () => {
      
    const [user, setUser] = useState();

    const currentSchoolContext = useContext(SchoolContext)
    
    useEffect(() => {
        setTitle(currentSchool);
        // if we haven't set up a configuration for a curent domain, let it default to development
        let devStage;
        if (currentSchool in schools) {
            currentSchoolContext.setSchool(currentSchool);
            if (currentSchool === 'localhost') {
                devStage = 'development';
            } else if (currentSchool === 'staging') {
                devStage = 'staging';
            } else {
                devStage = 'production';
            } 
        } else {
            currentSchoolContext.setSchool("localhost");
            devStage = 'development';
        }
        currentSchoolContext.setDevStage(devStage);

        //set user object on app load/refresh
        setUserObject(setUser, devStage);
        fetch(`${backendApi[devStage]}/auth/getschoolid`, {
            method: 'POST',
            body: JSON.stringify({ name: currentSchool }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(organizationData => {
            organizationData.json().then(json => {
                json.body.forEach((organization) => {
                    if (organization.name === currentSchool) {
                        currentSchoolContext.setSchoolId(organization.id);
                    }
                });
            })
        })
        .catch(err => {
            throw new Error(err)
        });
    },[]);

    return (
        <Router>
            <LoadContextProvider>
                <Routes currentSchool={currentSchool} user={user} setUser={setUser}/>
            </LoadContextProvider>
        </Router>
    )   
}

export default App