import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

const TagSelect = (props) => {
    /* -------------------------------------------------------- Props for TagSelect -----------------------------------------------------------------
    PROPS:    
        tagsSelected: object of bools, status of checked  
        setTagsSelected: function to change tagsSelectedObj
        mobileStatus: bool
    */

    const handleChange = (key) => {
        const oldBool = props.tagsSelected[key];
        const newState = { ...props.tagsSelected };
        newState[key] = !oldBool;
        props.setTagsSelected(newState)
    };

    return (
        <FormGroup
            row={!props.mobileStatus}
            style={{ padding: '0px 10px' }}
        >
            <FormControlLabel
                control={<Checkbox
                    checked={props.tagsSelected['wellness']}
                    onChange={() => handleChange('wellness')}
                />}
                label={'General Wellness'}
            />
            <FormControlLabel
                control={<Checkbox
                    checked={props.tagsSelected['tManagement']}
                    onChange={() => handleChange('tManagement')}
                />}
                label={'Time Management'}
            />
            <FormControlLabel
                control={<Checkbox
                    checked={props.tagsSelected['stressPanic']}
                    onChange={() => handleChange('stressPanic')}
                />}
                label={'Stress/Panic'}
            />
            <FormControlLabel
                control={<Checkbox
                    checked={props.tagsSelected['productivity']}
                    onChange={() => handleChange('productivity')}
                />}
                label={'Productivity/Motivation'}
            />
            <FormControlLabel
                control={<Checkbox
                    checked={props.tagsSelected['social']}
                    onChange={() => handleChange('social')}
                />}
                label={'Social Connections'}
            />
        </FormGroup>
    )
};

export default TagSelect;