import { useEffect, useContext, useState } from 'react';
import { backendApi } from '../constants/Constants.js';
import { LoadContext } from '../contexts/LoadingContext.js';
import { SchoolContext } from '../contexts/CurrentSchoolContext';
import { AccessTokenContext } from '../contexts/AccessToken.js';

const useFetch = (args) => {
    /* args is an obj that contains the arguments for making the firestore call
    URLquery: the db query 
    body: variables we pass into the query eg ID for get, data for create.
    */
    const loadingContext = useContext(LoadContext);
    const accessContext = useContext(AccessTokenContext);
    const schoolContext = useContext(SchoolContext);

    const domain = backendApi[schoolContext.devStage];

    const [isTimedOut, setIsTimedOut] = useState(false);
    const [fetchState, setFetchState] = useState({
        error: null,
        status: null,
        data: null
    });

    let timeoutRef;

    const getNewAccessToken = async () => {
        const newAccessTokenFetch = await fetch(`${domain}/auth/refresh`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                refreshToken: window.localStorage.getItem('refreshToken'),
                rftCreateDate: window.localStorage.getItem('rftCreateDate'),
            })
        });

        if (newAccessTokenFetch.status === 401) {
            // 401 means refresh token expired. redirecting the user afterwards
            // will then cause the app to check refresh token again, and prompt a log in when it isnt found.
            console.log("re log in to get a new refresh token cookie");
            window.localStorage.clear();
            window.location.replace("/signin");

        } else if (newAccessTokenFetch.status === 200) {
            const newAccessToken = await newAccessTokenFetch.json();

            // set access token in context
            accessContext.setAccessToken(newAccessToken.body.token);
            accessDb(newAccessToken.body.token, false);
        }
    };

    const accessDb = async (accessToken, firstAttempt) => {
        // check if access token exists, if yes then make the DB call
        if (accessToken) {
            const response = await fetch(domain + args.URLquery, {
                body: JSON.stringify(args.body),
                headers: {
                    authorization: accessToken,
                    'Content-Type': 'application/json'
                },
                method: 'POST',
            });
            if (response.status === 200) {
                const data = await response.json();
                setFetchState({
                    error: null,
                    status: 'completed',
                    data: data
                });
            } else if (response.status === 204) {
                setFetchState({
                    error: null,
                    status: 'empty',
                    data: null
                });
            } else if (response.status === 401 && firstAttempt) {
                //if Accesstoken is invalid/expired
                getNewAccessToken();
            } else if (response.status === 403) {
                setFetchState({
                    error: 'unauthorized',
                    status: 'error',
                    data: null
                });
            } else {
                setFetchState({
                    error: 'database error',
                    status: 'error',
                    data: null
                });
            }
            // if the access token doesn't exist, get one by calling auth/refresh api
        } else if (firstAttempt) {
            getNewAccessToken();
        } else {
            setFetchState({
                error: 'database error',
                status: 'error',
                data: null
            });
        }
        loadingContext.setIsLoading(false);
        clearTimeout(timeoutRef);
    };


    useEffect(() => {
        timeoutRef = setTimeout(() => {
            setIsTimedOut(true);
        }, 5000);
        if (!args.loadingDisabled) {
            loadingContext.setIsLoading(true);
        };

        //execute firebase
        setFetchState({
            error: null,
            status: 'downloading',
            data: null
        })

        if (args.URLquery) {
            accessDb(accessContext.accessToken, true);
        } else {
            // if the arguments have no relevant data, just cancel the timeout and do nothing
            clearTimeout(timeoutRef);
            setFetchState({
                error: null,
                status: null,
                data: null
            })
            loadingContext.setIsLoading(false);
        }
    }, [args]);

    // handling timeeouts
    useEffect(() => {
        if (isTimedOut === true) {
            setFetchState({
                error: 'timeout',
                status: 'error',
                data: null
            })
        }
    }, [isTimedOut]);

    return fetchState;

};

export default useFetch;