import React from 'react';
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const SelectInput = (props) => {
    /* -------------------------------------------------------- Props for SelectInput -----------------------------------------------------------------
        selectionValue: str
        setSelectionValue: fxn, str
        selectionItems: obj
        placeholder: str
    */

    const renderMenuItems = (items) => {
        const menuItemArray = [];
        if (items) {
            const itemsKeys = Object.keys(items);
            itemsKeys.forEach((element, i) => {
                const component =
                    <MenuItem
                        key={i}
                        value={element}
                    >
                        {items[element]}
                    </MenuItem>

                menuItemArray.push(component);
            });
        }
        return menuItemArray
    };

    return (
        <FormControl variant='outlined' style={{ width: '100%' }}>
            <Select
                value={props.selectionValue}
                displayEmpty
                onChange={e => props.setSelectionValue(e.target.value)}
            >
                <MenuItem value='' disabled>
                    {props.placeholder}
                </MenuItem>
                {renderMenuItems(props.selectionItems)}
            </Select>
        </FormControl>
    )
};

export default SelectInput;