import React from 'react';

const Pushpin = () => {
    return(
        <svg style={{width: "30px", height: "30px", transform: "rotate(45deg)"}}>
            <path d="M16 5h.99L17 3H7v2h1v7l-2 2v2h5v6l1 1 1-1v-6h5v-2l-2-2V5z">
            </path>
        </svg>
    )
};

export default Pushpin;