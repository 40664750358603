import React from 'react';

import ForumItem from '../Molecule/ForumItem';
import InboxItem from '../Molecule/InboxItem.js';

const MainPostList = (props) => {
    /* -------------------------------------------------------- Props for MainPostList -----------------------------------------------------------------
    PROPS:      
        user: obj
        mobileStatus
        topPost: object containing data for the top post
        arrayOfPostData: array, containing docs from the database.
    */

    const convertDocArrayToComponentArray = (arrayOfDocs) => {
        let componentArray = [];
        arrayOfDocs.forEach((doc) => {
            if (doc) {
                let postItem = <React.Fragment key={'asdasd'}></React.Fragment>;
                if (props.forumType === "public-posts") {
                    if (doc.id !== props.topPost.id) {
                        postItem = 
                        <ForumItem 
                            user={props.user} 
                            docdata={doc} 
                            mobileStatus={props.mobileStatus} 
                            topPost={props.topPost}
                            key={doc.id}
                        />
                    } 
                } else {
                    postItem = 
                    <InboxItem 
                        user={props.user} 
                        docdata={doc} 
                        key={doc.postId} 
                        mobileStatus={props.mobileStatus}
                    />
                }
                componentArray.push(postItem);
            }
        });
        return componentArray;
    }
    
    return (
        <>
            {convertDocArrayToComponentArray(props.arrayOfPostData)}
        </>
    )
}

export default MainPostList;