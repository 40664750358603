import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/Colors'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import CONFIG from '../../constants/Config.js';

import { LoadContext } from '../../contexts/LoadingContext.js';

const Loading = () => {
    /* -------------------------------------------------------- Props for AdminSetCalendlyLink -----------------------------------------------------------------
    PROPS:      none
    */

    // This component renders while the website is still fetching from the database. 
    // if it takes too long, render a timeout error message.
    const classes = useStyles();

    const [loadScreenOpen, setLoadScreenOpen] = useState(false);

    const loadingContext = useContext(LoadContext);

    const loadingMessage = {
        default: <Backdrop open={true} className={classes.circularLoad}>
            <CircularProgress color="inherit" />
        </Backdrop>,
    };

    const renderLoad = () => {
        let loadScreen;
        if (loadScreenOpen) {
            loadScreen = (
                <div className={classes.container}>
                    {loadingMessage[loadingContext.loadingMessage]}
                </div>
            );
        } else {
            loadScreen = (<></>);
        }

        return loadScreen
    }

    useEffect(() => {
        // when context becomes false, we want to set a delay so that the 
        // loading screen shows up for a minimum amount of time.
        // otherwise if its too quick it flashes too much
        let timeoutRef;

        if (loadingContext.isLoading) {
            setLoadScreenOpen(true);
        } else {
            timeoutRef = setTimeout(() => {
                setLoadScreenOpen(false)
            }, CONFIG.LOADING_BACKDROP_DELAY_TIME)
        }
        // if we leave the page, clear the timeout ref to avoid memory leak.
        return () => { clearTimeout(timeoutRef) };
    }, [loadingContext.isLoading])

    return (
        <>
            {renderLoad()}
        </>
    )
}

const useStyles = makeStyles(() => ({
    // for CircularProgress component to inherit
    circularLoad: {
        color: "white",
    },
    coordinatorLoad: {
        backgroundColor: COLORS.loadingScreenBackground,
    },
    container: {
        zIndex: "99999",
    },
    text: {
        width: "75%",
        fontSize: "2em"
    },
}));

export default Loading;