import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/Colors';
import { schools } from '../../constants/Constants';
import { SchoolContext } from '../../contexts/CurrentSchoolContext';
import SelectInput from './SelectInput';
import useFetch from '../../hooks/useFetch';

const DepartmentPrompt = (props) => {
    // props: setPromptForDept: fxn, bool. if set to true, this component will render
    const classes = useStyles();
    const currentSchoolCont = useContext(SchoolContext);
    const [departmentSelection, setDepartmentSelection] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [fetchArgs, setFetchArgs] = useState({});
    const { status } = useFetch(fetchArgs);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if a proper department has been selected, update the database as well as the local userObject
        if (departmentSelection === '') {
            setErrorMessage('Department Selection Required');
        } else {
            setErrorMessage('');
            setFetchArgs({
                URLquery: '/db/users/update/departmentOnly/',
                body: {
                    userId: props.user.userId,
                    department: departmentSelection,
                }
            });
        }
    };

    useEffect(() => {
        if (status === 'completed') {
            const tempUserObj = {...props.user};
            tempUserObj.department = departmentSelection;
            props.setUser(tempUserObj);
            props.setPromptForDept(false);
        } 
    }, [status]);


    return (
        <div className={classes.container}>
            You need select a department to continue using HiPanda.
            <form
                onSubmit={handleSubmit}
                className={classes.signupForm}
            >
                <div
                    style={{ color: 'red' }}
                >
                    {errorMessage}
                </div>
                <SelectInput
                    selectionValue={departmentSelection}
                    setSelectionValue={setDepartmentSelection}
                    selectionItems={schools[currentSchoolCont.school].departments}
                    placeholder={'Department'}
                />
                <button
                    type="submit"
                    className={classes.signupButton}
                >
                    Submit
			</button>
            </form>
        </div>
    )
};

const useStyles = makeStyles(() => ({
    container: { 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        height: '70vh', 
        textAlign: 'center' 
    },
    signupForm: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "15px",
        padding: "0px 5px",
        marginBottom: "10px",
        textAlign: "center",
    },
    signupButton: {
        width: "100%",
        height: "40px",
        border: "0px",
        borderRadius: "10px",
        backgroundColor: COLORS.landingBackground,
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        outline: "none",
        fontSize: "24px",
        "&:hover": {
            color: "white",
            backgroundColor: COLORS.hiPandaHeader
        },
    },
}))

export default DepartmentPrompt;