import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/Colors.js';
import STRINGS from '../../constants/Strings.js';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';

import EmailSent from '../../assets/emailLogo.png';

const EmailConfirmation = (props) => {
    /* -------------------------------------------------------- Props for EmailConfirmation -----------------------------------------------------------------
    PROPS:      
    open: bool, if true this will render
    handleClose: function, sets open to false
    transition: component needed for transition for the material ui library
    message: string, determines which message to display, in case of errors
    */
    const classes = useStyles();

    return (
        <Dialog fullScreen open={props.open} onClose={props.handleClose} TransitionComponent={props.Transition}>
            <AppBar className={props.classes}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div className={classes.popupMessage}>
                <div>
                    {STRINGS.emailConfirmation[props.message]}
                </div>
                {props.message.substring(0, 5) === "error" ? <></> : <img className={classes.emailLogo} alt="" src={EmailSent} />}
            </div>
        </Dialog>
    );
}

const useStyles = makeStyles(() => ({
    popupMessage: {
        paddingTop: "100px",
        color: COLORS.hiPandaHeader,
        fontSize: "clamp(35px, 3vw, 50px)",
        textAlign: "center",
        zIndex: 2,
    },
    emailLogo: {
        height: "200px",
        zIndex: 1,
    }
}))

export default EmailConfirmation;