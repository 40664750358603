import React from 'react'
import { Link } from 'react-router-dom';
import { COLORS } from '../../constants/Colors';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button'
import UpvoteButton from '../Atom/UpvoteButton.js';


// Each invidual forum post under /forums
const ForumItemButtons = (props) => {
    /* -------------------------------------------------------- Props for ForumItemButtons -----------------------------------------------------------------

    PROPS:      
        user: obj
        mobileStatus: bool
        doc: obj, the document obtained from the database
        changeUpvote: fxn
        upvote: bool, if true it means the current user has upvoted the parent component
        upvoteCount: num
    */
    const classes = useStyles(props.mobileStatus);

    // function executed when user clicks on a post (redirects to individual post page)
    const changeRoute = () => {
        if(window.getSelection().toString().length === 0) {
            if(props.user.userType !== 'student') {
                return (`/coordinator_forums/post/${props.doc.id}`)
            } else {
                return (`/forums/post/${props.doc.id}`)
            }
        }
    };

    return (
        <div className={classes.buttons}>
            <UpvoteButton
                mobileStatus={props.mobileStatus}
                upvoteCount={props.upvoteCount}
                changeUpvote={props.changeUpvote}
                upvote={props.upvote}
            />
            <Link to={changeRoute()}>
                <Button 
                    className={classes.contentButton} 
                >
                    Content & Replies
                </Button>
            </Link>
        </div>
    ) 
}

const useStyles = makeStyles(() => {
    return {
        buttons: {
            display:"flex", 
            alignItems:"center", 
            width:"100%",
        },
        contentButton: {
            display:"flex", 
            alignItems:"center",
            marginLeft:"10px", 
            marginRight:"10px", 
            paddingLeft:"15px", 
            paddingRight:"15px", 
            height:"35px",  
            lineHeight: "normal",
            borderRadius:"20px", 
            textTransform:"none", 
            fontWeight:"400",
            backgroundColor: COLORS.postButtons,
            color:"#426ff5", 
        },
    }
})

export default ForumItemButtons