import React, { useState } from 'react'
import useFetch from '../../hooks/useFetch';

import {withRouter} from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import ChangePost from './ChangePost';
import Avatar from '@material-ui/core/Avatar';

const InboxParentPost = (props) => {
    // This component is the main component of InboxRepliesList. it contians the parent post and the replies after
    /* -------------------------------------------------------- Props for InboxRepliesList -----------------------------------------------------------------

    PROPS:      
        mainPostDoc
        user
        mobileStatus
        navStatus
    */
    const classes = useStyles();

    const [fetchArgs, setFetchArgs] = useState({});
    const {status: dbCallStatus, data: dbCallData, error: dbCallError} = useFetch(fetchArgs);

    const deletePost = () => {
        setFetchArgs({URLquery: '/db/posts/delete/single', body: {id: props.mainPostDoc.postId}}); 
    };


    let parentPost;

    if (props.mainPostDoc) {
        let userName = props.mainPostDoc.firstName;
        parentPost = (
            <div className={classes.inboxParentPostWrapper}>
                <div className={classes.header}>
                    <Avatar className={classes.avatar} alt={userName} src="/static/images/avatar/1.jpg"/>
                    <div className={classes.titleDiv}>
                        <div className={classes.userName}>{userName}</div>
                        <div className={classes.title}>{props.mainPostDoc.title}</div>
                    </div>
                    <div className={classes.changePostWrapper}>
                        <ChangePost 
                            user={props.user} 
                            docID={props.mainPostDoc.postId} 
                            edit={{}} 
                            mobileStatus={props.mobileStatus} 
                            navStatus={props.navStatus}
                            canEdit={false} 
                            canDelete={true} 
                            canVerify={false}
                            deletePost={deletePost} 
                        />
                    </div>
                </div>
                <div className={classes.body}>
                    {props.mainPostDoc.content}
                </div>
            </div>
        )
    } else {
        parentPost = <></>
    }

    return parentPost
}

const useStyles = makeStyles(() => ({
    body: {
        backgroundColor: "white",
        padding: "10px",
        fontSize:"clamp(12px, 2vw, 14px)",
    },
    titleDiv: {
        display:"flex", 
        flexWrap:"wrap", 
        flexDirection: "column",
        width:"100%",
    },
    header: {
        display: "flex",
        gap: "10px",
    },
    inboxParentPostWrapper: {
        display:"flex", 
        flexDirection: "column",
        marginTop:"5px", 
        padding:"10px", 
        gap: "10px"
    },
    userName: {
        fontSize:"clamp(16px, 4vw, 22px)", 
        fontWeight:"500"
    },
    title: { 
        fontSize:"clamp(17px, 4vw, 24px)", 
    },
    changePostWrapper: {
        marginBottom:"auto", 
        marginLeft:"auto", 
        marginTop:"10px",
    },
    adminName: {
        marginBottom:"0px", 
        marginLeft:"10px", 
        marginTop:"10px",
        fontSize:"17px",
        fontWeight:"500",
    },
    avatar: {
        width:"calc(40px + 1vw)", 
        height:"calc(40px + 1vw)", 
        backgroundColor:"#a8d5f7",
        color:"black", 
    },
}))

export default withRouter(InboxParentPost);