import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/Colors';

const useStyles = makeStyles(() => ({
	appBar: {
		position: 'relative',
	},
	container: {
		display: "flex",
		flexDirection: "column",
		alignItems:"center",
		gap: "5px",
	},
	content: {
		position: "relative",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		gap: "10px",
		width: "50%",
		minWidth: "300px",
	},
	header: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: "0px 5px 10px",
		width: "100vw",
		backgroundColor: COLORS.hiPandaHeader,
		color: "white",
		zIndex: "-1",
	},
	loginButton: {
		height: "40px",
		fontSize: "24px",
		backgroundColor: COLORS.landingBackground,
		boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
		borderWidth: "0",
		borderRadius: "10px",
		outline: "none",
		"&:hover": {
			color: "white",
			backgroundColor: COLORS.hiPandaHeader,
			boxShadow: "0px 4px 4px #6C63FF",
			borderWidth: "0"
		}
	},
	panda: {
		position: "relative",
		top: '5px',
		height: "30px",
		width: "30px",
	},
	title: {
		margin: "0px",
		textAlign: "center",
		lineHeight: "1.1",
	},
}));

export default useStyles;
