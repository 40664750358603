import React from 'react'
import { COLORS } from '../../constants/Colors';

const ChatMessage = (props) => {
    /* -------------------------------------------------------- Props for ChatMessage -----------------------------------------------------------------
    msgObj
    user
    */

    const msgStyle = { 
        display: 'flex', 
        flexDirection: 'column', 
        padding: '5px 10px', 
        borderRadius: '10px', 
        backgroundColor: 'white' ,
    };

    if (props.user.userId === props.msgObj.senderId) {
        msgStyle.backgroundColor = COLORS.postBackground;
    };

    return (
        <div style={msgStyle}>
            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <div>
                    {`${props.msgObj.firstName} ${props.msgObj.lastName} `}
                </div>
                <div style={{paddingLeft: '10px', fontSize: '12px'}}>
                    {new Date(props.msgObj.datePublished).toLocaleString()}:
                </div>
            </div>
            <div>
                {props.msgObj.content}
            </div>
        </div>);
};

export default ChatMessage;