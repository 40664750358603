import React from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';

import Login from '../pages/login';
import Landing from '../pages/landing';
import Mainpage from '../pages/mainpage/Mainpage';
import FourZeroFour from '../pages/pagenotfound';
import SignedOutRoute from './SignedOutRoute';
import AdminProtectedRoute from './AdminProtectedRoute';
import UserProtectedRoute from './UserProtectedRoute';
import { withRouter } from 'react-router-dom';

const Routes = (props) => {

    const mainPage = (() =>
        <Mainpage setUser={props.setUser} user={props.user} />
    );

    // Admin/User/SignedOut routes
    const getRoutes = () => {
        if (props.user === null || props.user) {
            return (
                <Router>
                    <Switch>
                        <SignedOutRoute user={props.user} exact path="/welcome" component={() => <Landing currentSchool={props.currentSchool} />} />
                        <SignedOutRoute user={props.user} exact path="/signin" component={() => <Login currentSchool={props.currentSchool} />} />
                        <UserProtectedRoute user={props.user} setUser={props.setUser} exact path="/(|home|forums|inbox|coordinators|profile|dailylogs|feedback|chat)/" component={mainPage} />
                        <UserProtectedRoute user={props.user} exact path="/forums/post/:id" component={mainPage} />
                        <UserProtectedRoute user={props.user} exact path="/forums/replies/:id" component={mainPage} />
                        <UserProtectedRoute user={props.user} exact path="/inbox/post/:id" component={mainPage} />
                        <AdminProtectedRoute user={props.user} exact path="/(coordinator_forums|private_posts|coordinator_inbox|coordinator_dashboard|coordinator_profile|verify_posts|verify_replies|coordinator_chat)" component={mainPage} />
                        <AdminProtectedRoute user={props.user} exact path="/coordinator_inbox/post/:id" component={mainPage} />
                        <AdminProtectedRoute user={props.user} exact path="/coordinator_forums/post/:id" component={mainPage} />
                        <AdminProtectedRoute user={props.user} exact path="/coordinator_forums/replies/:id" component={mainPage} />
                        <Mainpage user={props.user} exact path="/howto" />
                        <Route path="*"><FourZeroFour /></Route>
                    </Switch>
                </Router>
            )
        } else {
            return (
                <React.Fragment />
            )
        }
    }

    return (
        <React.Fragment>
            {getRoutes()}
        </React.Fragment>
    );
};

export default withRouter(Routes);