import React, { useEffect, useState, useContext } from 'react'
import useFetch from '../../hooks/useFetch.js';
import { withRouter } from 'react-router-dom';
import CONFIG from '../../constants/Config';
import { defaultTags } from '../../constants/Constants';

import SortForums from '../../components/Organism/SortForums.js';
import SearchForums from '../../components/Atom/SearchForums.js';
import PageButtons from '../../components/Molecule/PageButtons.js';
import CreateForumPostButton from '../../components/Atom/CreateForumPostButton.js';
import MainPostList from '../../components/Organism/MainPostList.js';
import ForumsDisclaimer from '../../components/Atom/ForumsDisclaimer.js';
import ForumItem from '../../components/Molecule/ForumItem';
import useStyles from './styles';
import { dateFilterToMillisecsMap } from '../../constants/Constants.js';

import { SchoolContext } from '../../contexts/CurrentSchoolContext.js';

const Forums = (props) => {
    /* -------------------------------------------------------- Props for Forums -----------------------------------------------------------------

    PROPS:      
    user: Object type from routes.js. containts the authenticated user's info from the db                                                      
    forumType: string that can be one of three values: "public-posts", "admin-inbox" and "user-inbox"
    mobileStatus: bool from mainpage.js. true if screensize is less than 'sm'
    navStatus:bool from adminMainPAge.js and UserMainPAge.js. 
    */

    // forums is an array of forumItem/inboxItem components that will be rendered
    const [forums, setForums] = useState([]);
    const [topPost, setTopPost] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPostCount, setTotalPostCount] = useState(0);
    const [searchFilter, setSearchFilter] = useState("");
    const [sortPostsBy, setSortPostsBy] = useState("date");
    const [dateFilter, setDateFilter] = useState("all");
    const [tagsSelected, setTagsSelected] = useState(defaultTags);
    const [filterButton, setFilterButton] = useState(0);

    const [createPostDialog, setCreatePostDialog] = useState(false);

    const classes = useStyles();

    const [fetchArgs, setFetchArgs] = useState({});
    const { status: dbCallStatus, data: dbCallData } = useFetch(fetchArgs);

    const [topPostFetchArgs, setTopPostFetchArgs] = useState({});
    const { status: topPostDbStatus, data: topPostDbData } = useFetch(topPostFetchArgs);

    const schoolCont = useContext(SchoolContext);


    // width of component changes depending on if user has navbar expanded or is in mobile view
    const getWidth = () => {
        if (!props.mobileStatus) {
            if (props.navStatus) {
                return "calc(400px + 30vw)";
            } else {
                return "calc(400px + 38vw)";
            }
        } else {
            return "100%";
        }
    }

    const downloadPosts = () => {
        let tempArrayOfDocs = [];
        for (let doc of dbCallData.body) {
            tempArrayOfDocs.push(doc);
        }
        return (tempArrayOfDocs)
    };

    const searchPosts = () => {
        if (schoolCont.schoolId) {
            setFetchArgs({
                URLquery: '/db/posts/get/bySearchTerm',
                body: {
                    organizationId: schoolCont.schoolId,
                    userId: props.user.userId,
                    searchTerm: `%${searchFilter}%`,
                    dateVerified: new Date(new Date() - dateFilterToMillisecsMap[dateFilter]),
                    limit: CONFIG.POSTS_PER_PAGE,
                    offset: (currentPage - 1) * CONFIG.POSTS_PER_PAGE
                }
            })
        }
    };

    const tagsSelectedToArray = () => {
        const tagArray = [];
        Object.keys(tagsSelected).forEach(key => {
            if (tagsSelected[key]) {
                tagArray.push(key);
            }
        });
        return tagArray;
    };

    useEffect(() => {
        if (schoolCont.schoolId) {
            const tagArray = tagsSelectedToArray();
            let route;
            if (sortPostsBy === 'date') {
                if (tagArray.length === 0) {
                    route = '/db/posts/get/verifiedPublicPosts';
                } else {
                    route = '/db/posts/get/verifiedPublicPostsTagFilter';
                }
            } else {
                if (tagArray.length === 0) {
                    route = '/db/posts/get/verifiedPublicPostsByVotes';
                } else {
                    route = '/db/posts/get/verifiedPublicPostsTagFilterByVotes';
                }
            }
            setFetchArgs({
                URLquery: route,
                body: {
                    organizationId: schoolCont.schoolId,
                    userId: props.user.userId,
                    dateVerified: new Date(new Date() - dateFilterToMillisecsMap[dateFilter]),
                    limit: CONFIG.POSTS_PER_PAGE,
                    offset: (currentPage - 1) * CONFIG.POSTS_PER_PAGE,
                    tagArray: tagArray
                }
            })
        }
    }, [schoolCont.schoolId, currentPage, filterButton])

    useEffect(() => {
        if (dbCallStatus === "completed") {
            let arrayOfDocs = downloadPosts();
            setForums(arrayOfDocs);
            setTotalPostCount(dbCallData.body[0].totalCount)
            setFetchArgs({});
        } else if (dbCallStatus === 'empty') {
            setForums([]);
        }
    }, [dbCallStatus]);


    useEffect(() => {
        setTopPostFetchArgs({
            URLquery: '/db/posts/get/topVotedPost',
            body: {
                organizationId: schoolCont.schoolId,
                userId: props.user.userId,
            }
        })
    }, [])

    useEffect(() => {
        if (topPostDbStatus === 'completed') {
            setTopPost(topPostDbData.body);
            setTopPostFetchArgs({});
        } else if (topPostDbData === 'empty') {
            setTopPost([]);
        }
    }, [topPostDbStatus])

    return (
        <div className={classes.container}>
            <div style={{ width: getWidth() }}>
                <ForumsDisclaimer />
                <SearchForums
                    searchFilter={searchFilter}
                    setSearchFilter={setSearchFilter}
                    searchPosts={searchPosts}
                />
                <SortForums
                    user={props.user}
                    mobileStatus={props.mobileStatus}
                    forumType={"public-posts"}
                    sortPostsBy={sortPostsBy}
                    dateSort={dateFilter}
                    tagsSelected={tagsSelected}
                    setSort={setSortPostsBy}
                    setDateSort={setDateFilter}
                    setTagsSelected={setTagsSelected}
                    setFilterButton={setFilterButton}
                />
                <CreateForumPostButton
                    user={props.user}
                    mobileStatus={props.mobileStatus}
                    createPostDialog={createPostDialog}
                    setCreatePostDialog={setCreatePostDialog}
                />
                {/* We want the top rated post pinned on top */}
                {topPost[0] ?
                    <>
                        <ForumItem
                            user={props.user}
                            docdata={topPost[0]}
                            mobileStatus={props.mobileStatus}
                            pinned={true}
                        />
                        <MainPostList
                            user={props.user}
                            forumType={"public-posts"}
                            mobileStatus={props.mobileStatus}
                            arrayOfPostData={forums}
                            topPost={topPost[0]}
                        />
                    </>
                    :
                    <></>
                }
                <PageButtons
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPostCount={totalPostCount}
                />
            </div>
        </div>
    )
}

export default withRouter(Forums)