import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({ 
    container: {
        display:'flex', 
        justifyContent:"center", 
        flexWrap:'wrap',
        margin: "0px auto", 
        width:"calc(400px + 30vw)", 
        maxWidth: "100%", 
    },
}));

export default useStyles;