import React, { useEffect } from 'react'

import { Redirect, Route } from 'react-router-dom';
//import app from '../firebase/Firebase'

const AdminProtectedRoute = ({ component: Component, user, ...rest }) => {
    useEffect(() => {
    }, [user])

    // if the user prop has been passed, then check if props.user.userType === 'admin' is true. 
    // if true, then proceed to the path, else redirect to /home
    return (
        <Route
            {...rest}
            render={() => {
                if (user !== null) {
                    if (user.userType !== 'student') {
                        return (<Component />)
                    } else {
                        return <Redirect to={
                            {
                                pathname: "/home"
                            }
                        } />
                    }
                } else {
                    return <Redirect to={
                        {
                            pathname: "/signin"
                        }
                    } />
                }
            }} />
    )
}

export default AdminProtectedRoute