import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';


const InboxReply = (props) => {
    /* -------------------------------------------------------- Props for InboxReply -----------------------------------------------------------------

    PROPS:      
    replyType: str
    replyDoc: obj, the doc obtained from the database containing the dat afor this reply
    authorName: str
    */
    const classes = useStyles();

    const backgroundColorMap = {
        "student": { backgroundColor:"#bfffff" },
        "admin": { backgroundColor:"#dbe6ff" }
    }

    return (
        <div className={classes.replyContainer} style={backgroundColorMap[props.replyType]}>
            <div className={classes.header}>
                <Avatar className={classes.avatar} alt={props.authorName} src="/static/images/avatar/1.jpg"/>
                <div className={classes.headerContent}>
                    <div className={classes.userName}>
                        {props.authorName}
                    </div>
                    <div className={classes.postDate}>
                        {new Date(props.replyDoc.datePublished).toString().slice(4, 15)}
                    </div>
                </div>
            </div>
            <div className={classes.body}>
                {props.replyDoc.content}
            </div>
        </div>  
    )
}


const useStyles = makeStyles(() => ({
    header: {
        display: "flex",
        gap: "10px"
    },
    headerContent: {
        display: "flex",
        flexDirection: "column",
        gap: "5px"
    },
    replyContainer: {
        display:"flex", 
        flexDirection: "column",
        gap: "10px",
        position:"relative", 
        padding:"14px", 
        borderRadius:"5px", 
    },
    body: {
        width:"100%", 
        padding: "10px",
        backgroundColor: "white",
        borderRadius: "5px",
        fontSize:"clamp(12px, 2vw, 14px)",
    },
    postDate: {
        fontSize:"clamp(14px, 4vw, 20px)", 
    },
    userName: { 
        fontSize:"clamp(16px, 4vw, 22px)", 
        fontWeight:"500",
    },
    avatar: {
        width:"calc(40px + 1vw)", 
        height:"calc(40px + 1vw)", 
        backgroundColor:"#a8d5f7",
        color:"black", 
    },
}))

export default InboxReply;