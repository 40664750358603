import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useFetch from '../../hooks/useFetch.js';

import ChangePost from '../Molecule/ChangePost';
import Avatar from '@material-ui/core/Avatar';
import { COLORS } from '../../constants/Colors.js';

const VerifyList = (props) => {
    /*-------------------- Props for VerifyPost ---------------------
     PROPS:                           
        downloadedDocs={downloadedDocs}
        setDownloadedDocs={setDownloadedDocs}
        type: string. can be posts, edits, or replies
     */

    const classes = useStyles(props.mobileStatus);
    const [fetchArgs, setFetchArgs] = useState({});
    const { status: DBStatus } = useFetch(fetchArgs);

    const renderUnverified = (downloadedDocs) => {
        let unverifiedArray = [];
        if (downloadedDocs.body) {
            for (let doc of downloadedDocs.body) {

                // make a new array without the current doc, and set the props downloaded posts to the new array
                const removeDocFromList = () => {
                    let tempDownloadedDocs = {};
                    tempDownloadedDocs.body = props.downloadedDocs.body.filter(element => element !== doc);
                    props.setDownloadedDocs(tempDownloadedDocs);
                };

                let verifyDoc;
                let deleteDocNamespace;
                if (props.type === 'posts') {
                    verifyDoc = () => {
                        setFetchArgs({
                            URLquery: '/db/posts/update/verify/',
                            body: {
                                id: doc.id,
                                verified: true,
                                dateVerified: new Date(),
                            }
                        })
                        removeDocFromList();
                    };
                    deleteDocNamespace = 'posts';
                } else if (props.type === 'edits') {
                    verifyDoc = () => {
                        setFetchArgs({
                            URLquery: '/complexdb/verifyedit/',
                            body: {
                                id: doc.id,
                                postId: doc.postId,
                                dateMostRecentlyEdited: doc.timeEdited,
                                title: doc.title,
                                content: doc.content,
                                anonymous: doc.anonymousEdit
                            }
                        });
                        removeDocFromList();
                    };
                    deleteDocNamespace = 'edits';
                } else if (props.type === 'replies') {
                    verifyDoc = () => {
                        setFetchArgs({
                            URLquery: '/db/replies/update/verify',
                            body: {
                                id: doc.id,
                            }
                        });
                        removeDocFromList();
                    };
                    deleteDocNamespace = 'replies';
                }

                const deleteDoc = () => {
                    setFetchArgs({
                        URLquery: `/db/${deleteDocNamespace}/delete/single/`,
                        body: {
                            id: doc.id,
                        }
                    })
                    removeDocFromList();
                };

                const getDateString = () => {
                    let dateString;
                    if (props.type === 'edits') {
                        dateString = new Date(doc.timeEdited).toString().slice(4, 15);
                    } else {
                        dateString = new Date(doc.datePublished).toString().slice(4, 15);
                    }
                    return dateString;
                };

                const linkToPostToBeEdited = () => {
                    return (
                        <div style={{ textAlign: "center" }}>
                            ***This user wants to edit
                            <a href={"/coordinator_forums/post/" + doc.postId}>
                                &nbsp;one of their published posts
                            </a>***
                        </div>
                    )
                };

                const linkToPostBeingRepliedTo = () => {
                    return (
                        <div style={{ textAlign: "center" }}>
                            In reply to 
                            <a href={"/coordinator_forums/post/" + doc.postUrlPath}>
                                &nbsp;this post.
                            </a>
                        </div>
                    )
                };

                const verifyPostItem = (
                    <div id={doc.id} key={doc.id} className={classes.container}>
                        <div className={classes.header}>
                            <Avatar className={classes.avatar} alt={doc.firstName} src="/static/images/avatar/1.jpg" />
                            <div className={classes.headerContent}>
                                <div className={classes.name}>
                                    {`${doc.firstName} ${doc.lastName}`}
                                </div>
                                <div className={classes.date}>
                                    {getDateString()}
                                </div>
                            </div>
                        </div>
                        <div className={classes.body}>
                            <div className={classes.title}>
                                {doc.title}
                            </div>
                            <div className={classes.content}>
                                {doc.content}
                            </div>
                        </div>
                        {props.type === 'edits' ? linkToPostToBeEdited() : <></>}
                        {props.type === 'replies' ? linkToPostBeingRepliedTo() : <></>}
                        <div className={classes.footer}>
                            <ChangePost
                                user={props.user}
                                docID={doc.id}
                                edit={{}}
                                mobileStatus={props.mobileStatus}
                                navStatus={props.navStatus}
                                canEdit={false}
                                canDelete={true}
                                canVerify={true}
                                verifyPost={verifyDoc}
                                deletePost={deleteDoc}
                            />
                        </div>
                    </div>
                )
                unverifiedArray.push(verifyPostItem)
            }
        }
        return unverifiedArray
    };

    return (
        <>
            {renderUnverified(props.downloadedDocs)}
        </>
    )
}

const useStyles = makeStyles(() => {
    return {
        container: {
            display: "flex",
            flexDirection: "column",
            position: "relative",
            padding: "15px",
            backgroundColor: COLORS.postBackground,
            borderRadius: "5px",
            boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19)",
        },
        avatar: {
            width: "calc(45px + 1vw)",
            height: "calc(45px + 1vw)",
            backgroundColor: "white",
            color: "black",
        },
        header: {
            display: "flex",
            gap: "10px"
        },
        headerContent: {
            display: "flex",
            flexDirection: "column"
        },
        name: {
            fontWeight: "600",
            fontSize: "20px",
        },
        date: {
            fontSize: "15px",
        },
        body: {
            display: "flex",
            flexDirection: "column"
        },
        title: {
            fontSize: "clamp(16px, 2vw, 20px)"
        },
        content: {
            fontSize: "clamp(12px, 2vw, 14px)"
        },
        footer: {
            display: "flex",
            justifyContent: "flex-end"
        },
    }
})

export default VerifyList;