import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import STRINGS from '../../constants/Strings.js';


const ForumsDisclaimer = () => {
    /* -------------------------------------------------------- Props for ForumsDisclaimer -----------------------------------------------------------------
    PROPS:      none     
    */

    const classes = useStyles();

    return (
        <div className={classes.container}>
            {STRINGS.forumsDisclaimer.mainText}
            <a href={STRINGS.forumsDisclaimer.helpLink}>{STRINGS.forumsDisclaimer.helpLinkText}</a>
        </div>
    )
}

const useStyles = makeStyles(() => ({
    container: {
        padding: "24px"
    },

  }));
  
export default ForumsDisclaimer