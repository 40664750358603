import React from 'react'
import { COLORS } from '../../constants/Colors.js';
import { makeStyles } from '@material-ui/core/styles';
/* we shall add notifications later
import Button from '@material-ui/core/Button';
import NotificationsIcon from '@material-ui/icons/Notifications'; */
import Panda from '../../assets/panda.png';


/* This is the disabled notifications button. add it later.            
            <Button className={classes.notifications} smDown>
                <NotificationsIcon className={classes.notificationsIcon}/>
            </Button>*/ 

const Header = () => {
    const classes = useStyles();

    // Main HiPanda banner (contains Hipanda name + logo)
    return (
        <div className={classes.header}>
            <h1 className={classes.title}>
                HiPanda
                <img src={Panda} alt="HiPanda" className={classes.pandaImg}/>
            </h1>    
        </div>
    )
}

const useStyles = makeStyles(() => ({
    header: {
        display: "flex",
        justifyContent:"center",
        position: "relative",
        paddingTop:"20px",
        paddingBottom:"20px",
        backgroundColor: COLORS.hiPandaHeader,
        color:"white",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "18px",
    },
    title: {
        textAlign:"center", 
        margin:"0px", 
        fontSize:"45px",
    },
    pandaImg: {
        height: "34px",
        color: "white"
    }
}))

export default Header;