import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        width: "95%",
        margin: "20px auto",
        paddingBottom: "60px",
    }
}));

export default useStyles;