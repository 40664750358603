import React from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles';
import ReplyIcon from '@material-ui/icons/Reply';
import Button from '@material-ui/core/Button';

// contains sorting features AND create post features
const ReturnButton = (props) => {
    /* -------------------------------------------------------- Props for ReturnButton -----------------------------------------------------------------
    PROPS:      
    user: 
    forumType: str
    */
    const classes = useStyles();

    // return to the forums page
    const goBack = () => {
        if(props.user.userType !== 'student') {
            if(props.forumType === "public-posts") {
                return("/coordinator_forums")
            } else {
                return("/private_posts")
            }
        } else {
            return("/forums")
        }
    };

    return (
        <Link to={goBack()}>
            <Button className={classes.returnButton}>
                <ReplyIcon style={{marginBottom:"5px", marginRight:"10px",}} />
                Return
            </Button>
        </Link>
    )

}

const useStyles = makeStyles(() => ({
    returnButton: {
        display:"flex", 
        alignItems:"center",
        height:"50px",
        marginRight:"20px", 
        padding: "0px 20px", 
        backgroundColor:"white", 
        textTransform:"none", 
        boxShadow:"0 2px 4px 0 rgba(0, 0, 0, 0.1)",
    },
}));

export default ReturnButton 