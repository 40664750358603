import React, { useState, useEffect } from 'react';
import useFetch from '../../hooks/useFetch';
import TagSelect from './TagSelect';
import { COLORS } from '../../constants/Colors';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import STRINGS from '../../constants/Strings';
import { defaultTags } from '../../constants/Constants';

import { SchoolContext } from '../../contexts/CurrentSchoolContext.js';

const CreateForumPost = (props) => {
    /* --------------------------------------------- Props for CreateForumPost -----------------------------------------

    type: string
    dialogTitle, 
    title: str
    content: str, the text
    anonymous: bool, if true, makes  email and username anon
    open: bool, true means dialog is open
    mobileStatus: bool
    setClosed(): fxn, closes the dialog
    // IF CREATING PRIVATE POST:                    caseAdminID
    // IF EDITING A POST:                           editDocID
    // BASE PROPS:                                  mobileStatus, navStatus, user
*/
    const classes = useStyles();

    const [anonymousSetting, setAnonymousSetting] = useState(true);
    const [isfinishedDialogOpen, setFinishedDialogState] = useState(false);
    // for error notifications, in case a post is made without a title/content
    const [noTitle, setNoTitle] = useState(false);
    const [noContent, setNoContent] = useState(false);

    // if creating a new post, props.title & props.content will be set to empty string ""
    // if editing a post, then the title and content will be that of the original post
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [tags, setTags] = useState(defaultTags);

    const [fetchArgs, setFetchArgs] = useState({});
    const { status: DBStatus } = useFetch(fetchArgs);

    // function for creating a public post
    const createNewPost = async (e) => {
        e.preventDefault();


        const tagsSelectedToArray = () => {
            const tagArray = [];
            Object.keys(tags).forEach(key => {
                if (tags[key]) {
                    tagArray.push(key);
                }
            });
            return tagArray;
        };

        // checking to see if not just a blank post
        if (title === "" || content === "") {
            if (title === "") {
                setNoTitle(true);
            }
            if (content === "") {
                setNoContent(true);
            }
        } else {
            // adding post to firebase
            if (props.type === "public-posts") {
                setFetchArgs({
                    URLquery: '/complexdb/createpublicpost',
                    body: {
                        datePublished: new Date(),
                        dateVerified: new Date(),
                        publisherId: props.user.userId,
                        content: content,
                        title: title,
                        anonymous: anonymousSetting,
                        verified: false,
                        type: 'public',
                        tagArray: tagsSelectedToArray(),
                    }
                });
                setFinishedDialogState(true);

            } else if (props.type === "private-posts") {
                setFetchArgs({
                    URLquery: '/complexdb/createprivatepost',
                    body: {
                        datePublished: new Date(),
                        dateVerified: new Date(),
                        publisherId: props.user.userId,
                        content: content,
                        title: title,
                        anonymous: anonymousSetting,
                        verified: true,
                        type: 'private',
                        coordinatorId: props.caseAdminId
                    }
                });
                setFinishedDialogState(true);
            } else {
                console.log("Wrong forum type provided")
            }
            props.setClosed();
            setContent("");
        }
    }

    // option for hiding name
    const toggleAnonymous = () => {
        setAnonymousSetting(!anonymousSetting)
    };

    // React component for the create post dialog (for mobile + non mobile views)
    const getPostComponent = () => {
        return (
            <Dialog
                onClick={(e) => {
                    if (e.target.id !== "titleInput" && e.target.id !== "contentInput") {
                        setNoTitle(false);
                        setNoContent(false);
                    }
                }}
                maxWidth="xl"
                style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "0%", }}
                open={props.open}
                onClose={e => { props.setClosed() }}
                aria-labelledby="max-width-dialog-title"
            >
                <Button onClick={() => { props.setClosed() }} style={{ position: "absolute", top: "1vh", left: "1vw", minWidth: "25px", }}>
                    <CloseIcon />
                </Button>
                <DialogTitle style={{ margin: "none", marginTop: "20px" }}>
                    <p className={classes.postDialogTitle}>
                        {props.dialogTitle}
                    </p>
                </DialogTitle>
                <form id="create-public-post" onSubmit={createNewPost} noValidate autoComplete="off">
                    <TextField id="titleInput" error={noTitle} required variant="outlined" label="Title" onChange={e => setTitle(e.target.value)}
                        helperText={noTitle ? "This field is required." : ""}
                        defaultValue={props.title}
                        className={classes.postTitleTextField} />
                    <TextField id="contentInput" error={noContent} required variant="outlined" multiline rows={5} onChange={e => setContent(e.target.value)}
                        label="Your question" helperText={noContent ? "This field is required." : ""}
                        defaultValue={props.content}
                        className={classes.postContextTextField} />
                    <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", }}>
                        <FormGroup
                            style={{ flexDirection: 'row' }}
                        >
                            <FormControlLabel
                                style={{ marginLeft: "30px", }}
                                control={<Switch checked={anonymousSetting} onChange={toggleAnonymous} color="primary" />}
                                label="Hide Name"
                            />
                            <TagSelect
                                tagsSelected={tags}
                                setTagsSelected={setTags}
                            />
                        </FormGroup>
                        <Button type="submit" className={classes.postButton}>
                            {props.type === "edit" ? "Save Changes" : "Post"}
                        </Button>
                    </div>
                </form>
            </Dialog>
        )
    }

    // refresh when the Create Forum Post is closed/opened (props.open) and noContent & noTitle are changed for error warnings
    useEffect(() => {
    }, [props.mobileStatus, props.open, noContent, noTitle])

    return (
        <React.Fragment>
            {getPostComponent()}
            <Dialog
                PaperProps={{
                    style: { width: "82vw", minWidth: "300px", }
                }}
                onClick={(e) => {
                    if (e.target.id !== "titleInput" && e.target.id !== "contentInput") {
                        setNoTitle(false);
                        setNoContent(false);
                    }
                }}
                maxWidth="xl"
                minWidth="sm"
                style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "0%", }}
                open={isfinishedDialogOpen}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle>
                    <p className={classes.postDialogTitle}>
                        {props.type === "public-posts" ? STRINGS.createForumPost.publicPost : STRINGS.createForumPost.privateMessage}
                    </p>
                </DialogTitle>
                <Button onClick={() => setFinishedDialogState(false)} className={classes.postConfirmDialogContent}>
                    OK
                </Button>
            </Dialog>
        </React.Fragment>
    )
}

const useStyles = makeStyles(() => ({
    select: {
        width: "115px",
        fontSize: "16px",
    },
    labelRoot: {
        fontSize: "14px",
    },
    labelFocused: {
        fontSize: "14px",
    },
    postTitleTextField: {
        width: "calc(100% - 70px)",
        position: "relative",
        backgroundColor: "white",
        margin: "15px 35px 25px",
        borderWidth: "0p",
        borderRadius: "10px"
    },
    postContextTextField: {
        width: "calc(100% - 70px)",
        position: "relative",
        backgroundColor: "white",
        margin: "35px",
        marginTop: "0px",
        borderWidth: "0p",
        borderRadius: "10px"
    },
    postButton: {
        marginLeft: "auto",
        marginRight: "18px",
        marginBottom: "40px",
        padding: "10px 20px",
        borderRadius: "5px",
        backgroundColor: COLORS.postButton
    },
    postDialogTitle: {
        width: "100%",
        textAlign: "center",
        margin: "0px",
    },
    postConfirmDialogContent: {
        margin: "0px auto 10px",
        width: "fit-content",
        backgroundColor: COLORS.postButton,
    },
}));


export default CreateForumPost;