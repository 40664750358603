import React from 'react'

import { makeStyles } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import CreateForumPost from '../Organism/CreateForumPost'


const CreateForumPostButton = (props) => {
    /* -------------------------------------------------------- Props for CreateForumPostButton -----------------------------------------------------------------

    PROPS:      
        mobileStatus: bool
        createPostDialog: bool. if true, the create post dialog/modal opens up
        setCreatePostDialog: fxn that sets state of createPostDialog
        user: obj, represents users info  
    */

    const classes = useStyles();
    
    const createPostButton = () => {
        // determines which button style to draw based on mobileStatus.
        if(props.mobileStatus) {
            return (
                <div className={classes.container}>
                    <Button onClick={() => {props.setCreatePostDialog(true)}} className={classes.createPostButtonMobile}>
                        Create a new Post
                    </Button>
                </div>
            )
        } else {
            return (
                <div className={classes.container}>
                    <Fab onClick={() => {props.setCreatePostDialog(true);}} variant="extended" className={classes.createPostButton}>
                        <AddIcon style={{marginRight:"10px",}}/>
                        Create Post
                    </Fab>
                </div>
            )
        }
    }
    
    //CreateForumPost is a modal that only shows up when the button is pressed
  
    return (
        <>
            {createPostButton()}
            <CreateForumPost
                user={props.user} 
                type="public-posts"
                dialogTitle="Create a Public Post!"
                mobileStatus={props.mobileStatus} 
                open={props.createPostDialog} 
                setClosed={() => props.setCreatePostDialog(false)}
            />
        </>
    )
}

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        justifyContent: "center"
    },
    createPostButton: {
        textTransform:"none", 
        fontSize:"15px", 
        fontWeight:"400", 
        backgroundColor:"#9487FA", 
        color: "white",
    },
    createPostButtonMobile: {
        padding: 15,
        maxWidth: 200,
        backgroundColor:"#9487FA", 
        textTransform:"none", 
        color: "white",
        fontWeight:"400"
    },
  }));
  
export default CreateForumPostButton