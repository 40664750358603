import React from 'react';
import { COLORS } from '../../constants/Colors';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const ReplyToPublicPostButton = (props) => {
    /* -------------------------------------------------------- Props for ReplyToPublicPostButton -----------------------------------------------------------------
    PROPS:      
        mobileStatus: bool
        onClick: fxn
    */

    const classes = useStyles(props.mobileStatus);

    return (
        <Button
            id="replyButton"
            onClick={props.onClick}
            className={classes.replyButton}
        >
            {props.mobileStatus ? "Reply" : "Submit a Reply"}
        </Button>
    )
};

const useStyles = makeStyles(() => {
    return {
        replyButton: {
            height: "35px",
            display: "flex",
            alignItems: "center",
            padding: "0px 15px",
            borderRadius: "20px",
            backgroundColor: COLORS.postButtons,
            textTransform: "none",
            color: "#426ff5",
        },
    }
})

export default ReplyToPublicPostButton;