import React, {useState, useEffect} from 'react';

import withWidth from '@material-ui/core/withWidth';
import AdminMainpage from './AdminMainpage';
import UserMainpage from './UserMainpage';

const Mainpage = (props) => {
    // what to render determined by whether the user is an Admin
    const [render, setRender] = useState();

    // whether the user is in mobile view
    const [mobileStatus, setMobileStatus] = useState(false);

    const { width } = props; // width prop (material ui withWidth)

    // provides either admin or user mainpage
    const handleRender = async () => {
        const user = props.user;
        if (props.user) {
            if(props.user.userType !== 'student') {
                setRender(<AdminMainpage mobileStatus={mobileStatus} setUser={props.setUser} user={user}/>)
            } else {
                setRender(<UserMainpage mobileStatus={mobileStatus} setUser={props.setUser} user={user}/>)
            }
        } else {
            window.location.replace("/signin");
        }

    }

    // update the component whenever the size of the display changes, in order to detect when the user changes from destkop to mobile views
    useEffect(() => {
        if(width === 'sm' || width === 'xs') {
            setMobileStatus(true);
        } else {
            setMobileStatus(false);
        }
        handleRender();
    }, [width, mobileStatus])

    return (
        <>
            {render}
        </>
    )
}

export default withWidth()(Mainpage)
