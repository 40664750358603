import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import { COLORS } from '../../constants/Colors';
import useFetch from '../../hooks/useFetch';
import ReactMarkdown from 'react-markdown';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import ReplyToPublicPostButton from '../Atom/ReplyToPublicPostButton';
import PostChangeButton from '../Atom/PostChangeButton';

const ForumReply = (props) => {
    /* -------------------------------------------------------- Props for ForumReply -----------------------------------------------------------------
    replyData: obj
    layer: number
    props.mobileStatus: bool
    user: obj
    setParentReplyUUID: fxn
    setRefreshPage: fxn: bool
    refreshPage: bool
    */

    const classes = useStyles();
    const [deleteFetchArgs, setDeleteFetchArgs] = useState({});
    const { status: deleteDBStatus } = useFetch(deleteFetchArgs);


    const coordinatorTag = (userType) => {
        let element = <></>;
        if (userType === 'coordinator') {
            element =
                <div className={classes.coordTag}>
                    Coordinator
                </div>
        }
        return element;
    };

    const deleteButton = (userType, replyId) => {
        let element = <></>;
        if (userType === 'coordinator' || userType === 'admin') {
            const deleteReply = () => {
                setDeleteFetchArgs({
                    URLquery: '/db/replies/delete/byUniqueId/',
                    body: {
                        uniqueId: replyId
                    }
                });
                props.setRefreshPage(!props.refreshPage);
            };
            element =
                <PostChangeButton
                    type={'delete'}
                    onClick={deleteReply}
                />
        }
        return element;
    };

    const handleReplyButton = () => {
        props.setParentReplyUUID(props.replyData.uniqueId);
        props.setReplyDialog(true);
    };

    const handleNavigateToReply = () => {
        const currentURLOrigin = window.location.origin;
        if (props.user.userType !== 'student') {
            return `/coordinator_forums/replies/${props.replyData.uniqueId}`;
        } else {
            return `/forums/replies/${props.replyData.uniqueId}`;
        }
    };

    return (
        <div style={{ flexGrow: 1, border: '1px solid grey' }}>
            <Divider className={classes.divider} />
            <div className={classes.container}>
                <div className={classes.header}>
                    <Avatar className={classes.avatar}>
                        {props.replyData.firstName[0]}
                    </Avatar>
                    <div className={classes.nameDate}>
                        <div className={classes.replyName}>
                            {`${props.replyData.firstName} ${props.replyData.lastName}`}
                        </div>
                        <div>
                            {new Date(props.replyData.datePublished).toString().slice(4, 15)}
                        </div>
                    </div>
                    {coordinatorTag(props.replyData.userType)}
                </div>
                <div className={classes.body}>
                    <ReactMarkdown
                        source={props.replyData.content}
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', gap: 10 }}>
                        <ReplyToPublicPostButton
                            mobileStatus={props.mobileStatus}
                            onClick={handleReplyButton}
                        />
                        <Link to={handleNavigateToReply}>
                            View comment thread    
                        </Link>
                    </div>
                    {deleteButton(props.user.userType, props.replyData.uniqueId)}
                </div>
            </div>
        </div>
    )
};

const useStyles = makeStyles(() => (
    {
        avatar: {
            backgroundColor: "white",
            color: "black",
        },
        avatarCoordinator: {
            backgroundColor: "white",
            color: "black",
        },
        body: {
            display: "flex",
            flexWrap: "wrap",
            flexBasis: "calc(100% - 120px - 1.5vw)",
            alignItems: "center",
            fontSize: "clamp(12px, 2vw, 14px)",
            lineHeight: "23px",
            fontWeight: "400",
        },
        checkCircleIcon: {
            marginTop: "15px",
            color: "#42c2f5",
            marginLeft: "10px"
        },
        container: {
            display: "flex",
            flexDirection: "column",
            padding: "15px",
            gap: "10px",
            width: '100%'
        },
        coordTag: {
            padding: '4px 8px',
            backgroundColor: COLORS.hiPandaHeader,
            borderRadius: '10px',
            color: 'white'
        },
        divider: {
            width: "calc(100%)", marginLeft: "auto", marginRight: "auto",
        },
        header: {
            display: "flex",
            alignItems: 'flex-start',
            gap: "10px",
        },
        nameDate: {
            display: 'flex',
            flexDirection: 'column'
        },
        replyName: {
            fontWeight: "600",
            fontSize: "20px",
        },
    })
);

export default withRouter(ForumReply);