import React, { useState, useEffect, useContext } from 'react';
import { schools } from '../../constants/Constants.js';
import STRINGS from '../../constants/Strings';
import { SchoolContext } from '../../contexts/CurrentSchoolContext.js';
import { COLORS } from '../../constants/Colors.js';
import ConfirmDialog from '../Molecule/ConfirmDialog';

import { withRouter, Link as RouterLink } from "react-router-dom";
import clsx from 'clsx';
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, } from '@material-ui/core/styles';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SecurityIcon from '@material-ui/icons/Security';

const drawerWidth = 260;


const Nav = (props) => {
    const classes = useStyles();

    // user's name display at the top left of the navbar
    const name = props.user.firstName + " " + props.user.lastName;

    // dialogue that appears when clicking sign out
    const [signOutDialogIsOpen, setSignOutDialogIsOpen] = useState(false);

    // expand/collapsed nav
    const navStatus = props.navStatus;

    const schoolCont = useContext(SchoolContext);

    const getNavSize = () => {
        if (props.navStatus) {
            return { width: "260px" }
        } else {
            return { width: "85px" }
        }
    }

    // button for expanding/collapsing navbar
    const getButton = (expanded) => (
        expanded ?
            <Button onClick={props.setNavStatus}>
                <KeyboardArrowLeftIcon />
            </Button>
            :
            <Button onClick={props.setNavStatus} className={classes.expandButton}>
                <KeyboardArrowRightIcon />
            </Button>
    )

    // React component for upper nav Items
    const getUpperNav = () => {
        let upperNavArray = [];
        let key;
        if (props.user.userType === 'student') {
            key = 'student';
        } else {
            key = 'admin'
        }
        STRINGS.navbar.upper[key].forEach(item => {
            upperNavArray.push(
                <ListItem button component={RouterLink} key={item.label} to={item.route} className={clsx({ [classes.menuItemHidden]: !navStatus })}>
                    <ListItemIcon> {item.icon} </ListItemIcon>
                    <ListItemText primary={item.label} />
                </ListItem>
            )
        });
        return upperNavArray;
    };

    const openSignOutConfirm = () => {
        setSignOutDialogIsOpen(true);
    };

    const getLowerNav = () => {
        const privacyPolicyURL = schools[schoolCont.school].privacyLink;
        let lowerNav =
            <>
                <ListItem button onClick={openSignOutConfirm} className={clsx({ [classes.menuItemHidden]: !navStatus })}>
                    <ListItemIcon><ExitToAppIcon style={{ color: COLORS.navBarButtons }} /></ListItemIcon>
                    <ListItemText primary="Sign Out" />
                </ListItem>
                <ListItem button onClick={() => { window.location = privacyPolicyURL }} className={clsx({ [classes.menuItemHidden]: !navStatus })}>
                    <ListItemIcon><SecurityIcon style={{ color: COLORS.navBarButtons }} /></ListItemIcon>
                    <ListItemText primary="Privacy Policy" />
                </ListItem>
            </>;
        return lowerNav
    };

    // function for signing user out of firebase auth
    const handleSignOut = async () => {
        window.localStorage.clear();
        // redirecting the user afterwards will then cause the app to check refresh token again, and prompt a log in when it isnt found.
        window.location.replace("/signin");
    };

    // React component for navbar (ChangePhoto, upperNav, lowerNav)
    const drawer = (
        <>
            <div className={classes.drawerButtonContainer}>
                {getButton(navStatus)}
            </div>
            <div className={classes.profile}>
                <div className={clsx(classes.profileButtonDiv, { [classes.profileButtonDivHidden]: !navStatus })}>
                    <Button className={clsx(classes.profileButton, { [classes.profileButtonHidden]: !navStatus })}>
                        <Avatar alt={props.user.firstName} src="/static/images/avatar/1.jpg" />
                    </Button>
                </div>
                <div className={clsx(classes.userName, { [classes.userNameHidden]: !navStatus })}>
                    {name}
                </div>
            </div>
            <Divider />
            <List>
                {getUpperNav()}
            </List>
            <Divider />
            <List className={clsx({ [classes.lowerNav]: navStatus })}>
                {getLowerNav()}
            </List>
        </>
    );

    useEffect(() => { }, [props.mobileStatus, props.navStatus])

    // React component for Nav with ChangePhoto dialog
    return (
        <React.Fragment>
            <ConfirmDialog open={signOutDialogIsOpen} onClose={() => { setSignOutDialogIsOpen(false) }} onConfirm={handleSignOut}
                title="Sign Out?" content="Are you sure you want to sign out?" />
            <Hidden smDown implementation="css">
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: navStatus,
                            [classes.drawerClose]: !navStatus,
                        }),
                    }}
                    style={getNavSize()}
                >
                    {drawer}
                </Drawer>
            </Hidden>
        </React.Fragment>
    )
};

const useStyles = makeStyles((theme) => ({
    drawerOpen: {
        width: drawerWidth,
        minHeight: "600px",
        height: "100vh",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
        overflowY: 'scroll',
    },
    drawerClose: {
        width: "85px",
        height: "100vh",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
    },
    drawerButtonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "10px 0",
    },
    menuItemHidden: {
        overflowX: "hidden",
        whiteSpace: "nowrap",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "30px",
    },
    expandButton: {
        width: "100%",
        padding: "10px",
    },
    lowerNav: {
        position: "absolute",
        bottom: "20px",
    },
    profile: {
        height: "70px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
    },
    profileButtonHidden: {
        width: "100%"
    },
    profileButtonDivHidden: {
        width: "100%",
    },
    userName: {
        fontSize: "20px",
        color: COLORS.hiPandaHeader,
        overflowX: "hidden",
        whiteSpace: "nowrap",
    },
    userNameHidden: {
        display: "none",
    },
}));

export default withRouter(Nav);