import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        alignItems: "center",
        maxWidth: "700px",
        margin: "40px auto",
        paddingBottom: "50px",
        textAlign: "center",
    },
}));

export default useStyles;