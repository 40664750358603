import firebase from 'firebase/app'
import "firebase/auth";
import "firebase/firestore"

const DEVELOPMENT_CONFIG = {
    apiKey: "AIzaSyCyDlqSrIm32VnQwGYxM2XpByzCPYyLfw0",
    authDomain: "hipanda-development.firebaseapp.com",
    databaseURL: "https://hipanda-development.firebaseio.com",
    projectId: "hipanda-development",
    storageBucket: "hipanda-development.appspot.com",
    messagingSenderId: "177525071456",
    appId: "1:177525071456:web:c84583f25e3a666f0fad05",
    measurementId: "G-KWE7FZQK4R"
  };

const PROD_CONFIG = {
    apiKey: "AIzaSyCBFMewoJHWya377m2cf1XNfTG9u9wZvdI",
    authDomain: "hiridehelp-authentication.firebaseapp.com",
    databaseURL: "https://hiridehelp-authentication.firebaseio.com",
    projectId: "hiridehelp-authentication",
    storageBucket: "hiridehelp-authentication.appspot.com",
    messagingSenderId: "388676664190",
    appId: "1:388676664190:web:138f66432d399f6075d12f"
};

// copied from serviceworker.js
const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

let app;

// connect to hipanda-development if in development or staging environment 
if (isLocalhost || window.location.hostname.split(".")[0] === 'staging') {
    app = firebase.initializeApp(DEVELOPMENT_CONFIG);
    console.log('in development/staging mode');
} else {
    app = firebase.initializeApp(PROD_CONFIG);
}

export default app
