import React, { useState } from 'react';
import { COLORS } from '../../constants/Colors';
import useFetch from '../../hooks/useFetch';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';



// Each invidual forum post under /forums
const AdminSetCalendlyLink = (props) => {
    /* -------------------------------------------------------- Props for AdminSetCalendlyLink -----------------------------------------------------------------
    PROPS:      
    user
    mobileStatus
    setUser
    */
    const classes = useStyles(props.mobileStatus);

    const [calendlyInputValue, setCalendlyInputValue] = useState(props.user.calendlyLink);
    const [copyToolTip, setCopyToolTip] = useState(false);

    const [fetchArgs, setFetchArgs] = React.useState({});
    const {status: DBStatus} = useFetch(fetchArgs);

    // close tooltip
    const handleTooltipClose = () => {
        setCopyToolTip(false);
    };
    
    // copy calendly link to clipboard
    const copyLink = (e) => {
        let copyText = document.getElementById("calendly-link");
        copyText.select();
        e.target.focus();
        document.execCommand("copy");
        setCopyToolTip(true);
        window.getSelection().removeAllRanges();
    }

    // function for setting new calendlyLink
    const setNewCalendlyLink = (e) => {
        e.preventDefault();

        // first change calendlyLink in props.user Object
        let tempUser = props.user;
        tempUser["calendlyLink"] = calendlyInputValue;
        
        props.setUser(tempUser);
            
        setFetchArgs({URLquery: '/db/coordinator/update/single', 
            body: {
                calendlyLink: calendlyInputValue,
                userId: props.user.userId
            }
        })
    }

    return (
        <div className={classes.calendlyLink}>
            <PermContactCalendarIcon className={classes.calendlyIcon}/>
            <form className={classes.form}>
                <FormControl className={classes.formControl} style={{height:"50px"}}>
                    <InputLabel className={classes.inputLabel} style={{marginTop:"-10px",}}>
                        Calendly Link
                    </InputLabel>
                    <Input
                        id="calendly-link"
                        type='text'
                        label="Number"
                        defaultValue={props.user.calendlyLink}
                        className={classes.calendlyInput}
                        onChange={(e) => setCalendlyInputValue(e.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <ClickAwayListener onClickAway={handleTooltipClose}>
                                    <div>
                                        <Tooltip
                                            PopperProps={{
                                                disablePortal: true,
                                            }}
                                            onClose={handleTooltipClose}
                                            placement="left"
                                            open={copyToolTip}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            title={<span className={classes.copiedTooltip}>Copied to Clipboard!</span>}
                                        >
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                className={classes.copyCalendlyIcon}
                                                onClick={copyLink}
                                                >
                                                <FileCopyIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </ClickAwayListener>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <Button onClick={(e) => setNewCalendlyLink(e)} className={classes.saveCalendlyLink}>
                    Save
                </Button>
            </form>
        </div>
    ) 
}


const useStyles = makeStyles(() => {
    return {
        buttons: {
            display:"flex", 
            alignItems:"center", 
            justifyContent:"center", 
            width:"100%",
            marginTop:"5px", 
            marginBottom:"20px", 
        },
        calendlyIcon: {
            marginLeft:"5.5%", 
            marginRight:"-20px",
            fontSize:"calc(50px + 3vw)", 
        },
        calendlyLink: { 
            position:'relative', 
            display:"flex", 
            alignItems:"center", 
            margin: "35px 25px 500px",
            width:"calc(940px)", 
            minWidth:"200px", 
            maxWidth:"100vw", 
            height:"200px",
            backgroundColor:"white", 
            borderRadius:"30px", 
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",          
        },   
        calendlyInput: {
            width:"calc(100%)", 
            marginTop:"30px", 
            fontSize:"20px",
        },
        form: {
            display:"flex",
            flexWrap: "wrap", 
            justifyContent: "center",
            width:"100%", 
        },
        formControl: {
            display:"flex",
            marginTop:"-3px", 
            marginLeft:"30px",
            width:"80%", 
            height:"40px",
        },
        inputLabel: {
            fontSize:"27px", 
            width:"100%"
        },
        copiedTooltip: {
            marginTop:"10px",
            fontSize:"14px", 
            fontWeight:"400", 
        },
        copyCalendlyIcon: {
            marginBottom:"10px",
        },
        saveCalendlyLink: {
            marginLeft:"20px", 
            marginTop:"5px",
            marginRight:"20px",
            padding:"12px", 
            paddingTop:"4px", 
            paddingBottom:"4px", 
            borderRadius:"10px",
            backgroundColor:"#dee0ff",
            fontSize:"20px", 
            textTransform:"none",  
            fontWeight:"200",  
            color: COLORS.hiPandaHeader, 
        },
    }
})


export default AdminSetCalendlyLink;