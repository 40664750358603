import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/Colors'

const useStyles = makeStyles(() => ({
    howToContainer: {
        maxWidth: "700px",
        margin: "0 auto",
        paddingBottom: "50px",
    },
    welcomeHeader: {
        textAlign: "center",
    },
    stepSection: {
        marginBottom: "20px",
        padding: "20px",
        backgroundColor: "white",
        borderRadius: "20px",
    },
    stepIcons: {
        fontSize: "40px", marginRight: "10px"
    },
    stepTitle: {
        color: COLORS.hiPandaHeader,
    },
    checkMarkButton: {
        display: "block",
        margin: "0 auto",
        width: "82px",
        borderRadius: "100%",
        backgroundColor: "white",
        fontSize: "40px",
        fontWeight: "bold",
    }
}));

export default useStyles;
