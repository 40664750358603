import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import useFetch from '../../hooks/useFetch.js';
import useStyles from './styles';
import VerifyList from '../../components/Organism/VerifyList';
import VerifyPostsEmptyList from '../../components/Atom/VerifyPostsEmptyList.js';
import { SchoolContext } from '../../contexts/CurrentSchoolContext.js';

// This is effectively a copy of Forums and ForumItems (certain are allowed to access /verify_posts and )
const VerifyPost = (props) => {
    /*-------------------- Props for VerifyPost ---------------------
     PROPS:                           
     user: user Obj from routes.js
     */

    const classes = useStyles();

    const [downloadedPubPosts, setDownloadedPubPosts] = useState([]);
    const [downloadedPubEdits, setDownloadedPubEdits] = useState([]);

    const [fetchArgsPosts, setFetchArgsPosts] = useState({});
    const { status: dbCallStatusPosts, data: dbCallDataPosts } = useFetch(fetchArgsPosts);
    const [fetchArgsEdits, setFetchArgsEdits] = useState({});
    const { status: dbCallStatusEdits, data: dbCallDataEdits } = useFetch(fetchArgsEdits);

    const schoolCont = useContext(SchoolContext);

    // kick out the non admins
    if (props.user.userType === 'student') {
        props.history.push("/home");
    };

    useEffect(() => {
        if (schoolCont.schoolId) {
            setFetchArgsPosts({
                URLquery: '/db/posts/get/unverified/',
                body: {
                    organizationId: schoolCont.schoolId
                },
            });
            setFetchArgsEdits({
                URLquery: '/db/edits/get/unverified/', 
                body: {
                    organizationId: schoolCont.schoolId
                },
            });
        };
    }, [schoolCont.schoolId]);

    useEffect(() => {
        if (dbCallStatusPosts === 'completed') {
            setDownloadedPubPosts(dbCallDataPosts);
        };
        if (dbCallStatusEdits === 'completed') {
            setDownloadedPubEdits(dbCallDataEdits);
        };
    }, [dbCallStatusPosts, dbCallStatusEdits]);

    return (
        <div className={classes.container}>
            {(downloadedPubPosts.body || downloadedPubEdits.body) ?
                <>
                    <VerifyList
                        downloadedDocs={downloadedPubPosts}
                        setDownloadedDocs={setDownloadedPubPosts}
                        type='posts'
                    />
                    <VerifyList
                        downloadedDocs={downloadedPubEdits}
                        setDownloadedDocs={setDownloadedPubEdits}
                        type='edits'
                    />
                </>
                :
                <VerifyPostsEmptyList />
            }
        </div>
    );
};

export default withRouter(VerifyPost);