import React, {useEffect } from 'react'

import { Redirect, Route } from 'react-router-dom';
//import app from '../firebase/Firebase'

const UserProtectedRoute = ({component: Component, user, ...rest}) => {
    useEffect(() => {
    }, [user])

    // if the user prop has been passed, then check if props.user.userType === 'admin' is false. 
    // if false, then proceed to the path, else redirect to /home
    return (
        <Route 
            {...rest}
            render= { props => {
                if(user !== null) {
                    if(user) {
                        if(user.userType === 'student') {
                            return (<Component/>)
                        } else {
                            return <Redirect to ={
                                {
                                    pathname:"/coordinator_dashboard"
                                }
                            }/>
                        }
                    } else {
                        return (
                            <div>
                            </div>
                        )
                    }
                } else {
                    return <Redirect to={
                            {
                                pathname:"/signin"
                            }
                        }/>
                }
    }}/>)
}

export default UserProtectedRoute
