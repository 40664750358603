import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/Colors.js';

const useStyles = makeStyles(() => ({
    container: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        gap: "10px",
        margin: "0px auto 100px",
        maxWidth: "100%",
        width: "calc(400px + 30vw)",
    },
    createReplyButton: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },
    inboxItemsWrapper: {
        width: "100%",
        backgroundColor: "white",
        marginTop: "50px",
    },
    replyButton: {
        padding: "10px",
        backgroundColor: COLORS.hiPandaHeader,
        textTransform: "none",
        fontSize: "20px",
        color: "white"
    },
}));

export default useStyles;
