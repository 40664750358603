import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


const AddHyperlink = (props) => {
    /* -------------------- Props for AddHyperlink ---------------------
    PROPS:                  
    open: bool, if true then this renders
    setOpen: fxn that sets the state of open
    newReply: string, the text that the user is entering in the new post
    setNewReply fxn, sets the state of newReply
    */

    const classes = useStyles();
    const [hyperlinkLabel, setHyperlinkLabel] = useState("");
    const [hyperlinkURL, setHyperlinkURL] = useState("");

    const addLink = () => {
        // if label is blank eg "" or "   ", etc, set it to URL
        const URL = `(${hyperlinkURL})`;
        let label;

        if (hyperlinkLabel.trim() === "") {
            label = URL;
        } else {
            label = `[${hyperlinkLabel}]`;
        }

        props.setNewReply(`${props.newReply}${label}${URL}`);
        setHyperlinkLabel("");
        setHyperlinkURL("");
        props.setOpen(false);
    }

    const handleLabelChange = (e) => {
        setHyperlinkLabel(e.target.value)
    }
    const handleURLChange = (e) => {
        setHyperlinkURL(e.target.value)
    }

    if (props.open) {
        return (
            <div className={classes.container}>
                <div className={classes.inputWrapper}>
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        label="Enter text" 
                        className={classes.textInput}
                        onChange={handleLabelChange}
                        value={hyperlinkLabel}
                    />
                    <TextField 
                        variant="outlined" 
                        size="small" 
                        label="Enter URL" 
                        className={classes.textInput}
                        onChange={handleURLChange}
                        value={hyperlinkURL}
                    />
                </div>
                <div className={classes.addLinkButton}>
                    <Button onClick={() => {addLink()}}>
                        Apply
                    </Button>
                </div>
            </div>    
        )    
    } else {
        return <></>
    }
}

const useStyles = makeStyles(() => ({ 
    addLinkButton: {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        flex: 1
    },
    container: {
        display: "flex",
        padding: "20px",
        paddingTop: "0px",
    },
    inputWrapper: {
        display: "flex",
        flexDirection: "column",
        flex: 2,
        gap: "10px",
    },
}));

export default AddHyperlink;