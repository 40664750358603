import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    table: {
      maxWidth: "calc(600px + 30vw)", margin: "0px auto"
    },
    dailyLogsWrapper: {
        margin: "0px auto",
    },
  });

  // Date.getMonth() returns an int, so pass it to this array to get the name of the month.
const MONTH_NUM_TO_NAME = ['January', 'February', 'March', 'April', 'May', 'June', 'July',
                            'August', 'September', 'October', 'November', 'December'];

const MILLISECONDS_IN_WEEK = 1000 * 60 * 60 * 24 * 7;

const DailyLogs = (props) => {
    const classes = useStyles();
    const currentUserObj = props.user;

    const [dailyScores, setDailyScores] = React.useState([]);

    const getAllUserDailyLogs = async () => {
        let tempArray = []; 

        // await app.firestore().collection("daily-logs")
        // .orderBy("timestamp").get()
        // .then((querySnapShot) => {
        //     querySnapShot.docs.forEach((doc) => {
        //         tempArray.push(doc.data())
        //     })
        // })
        // .catch((err) => {
        //     console.log(err)
        // });
        // setDailyScores(tempArray);
    }

    const getCurrentUserDailyLogs = async () => {
        let tempArray = [];

    // gathers ALL daily logs from the user. redo this in future to reduce # of doc reads.
        // await app.firestore().collection("daily-logs").where("userID", "==", currentUserObj.userID)
        // .orderBy("timestamp").get()
        // .then((querySnapShot) => {
        //     querySnapShot.docs.forEach((doc) => {
        //         tempArray.push(doc.data())
        //     })
        // })
        // .catch((err) => {
        //     console.log(err)
        // });
        // setDailyScores(tempArray);
    }

    const organizeDataByMonth = (dataArray) => {
        // returns an object formatted { year: { month: [dataObj, ..], .. }, year: { month: [dataObj, ..], ..}, .. }
        // the objects in the child-most array are the objects returned from doc.data()
        let result = {};
        console.log(dataArray)
        dataArray.forEach((dataObj) => {
            let dataYear = dataObj.timestamp.toDate().getFullYear();
            let dataMonth = MONTH_NUM_TO_NAME[dataObj.timestamp.toDate().getMonth()];

            // if the key in the object does not yet exist, create a blank entry.
            if (!result[dataYear]) {
                result[dataYear] = {}
            }
            if (!result[dataYear][dataMonth]) {
                result[dataYear][dataMonth] = [];
            }
            result[dataYear][dataMonth].push(dataObj);
        })
        return result;
    }

    const getAverageOfAMonth = (month) => {
        // find the first day of month, then last day
        // get all entries on and between those days
        // average them.
        let runningSum = 0;
        let numberOfAdditions = 0;
        month.forEach((dataObj) => {
            runningSum += dataObj.dailyStatus;
            numberOfAdditions += 1;
        })
        let monthlyAverage = runningSum / numberOfAdditions;
        if (isNaN(monthlyAverage)) {
            return "No entries this month!"
        } else {
            return monthlyAverage.toFixed(2);
        }
    }

    const getSevenDayAverage = (scores) => {
        let cumulativeScore = 0;
        let numberOfEntries = 0;
        scores.forEach((dailyScoreObj) => {
            if (new Date() - MILLISECONDS_IN_WEEK < dailyScoreObj.timestamp.seconds * 1000) {
                cumulativeScore += Number(dailyScoreObj.dailyStatus); 
                numberOfEntries += 1;
            }
        })
        let average = cumulativeScore / numberOfEntries;
        // average is NaN if there are o recent entries, because 0/0 is NaN
        if (isNaN(average)) {
            return "No recent entries!"
        } else {
            return average.toFixed(2);
        }
    };

    const scoreByMonth = (organizedData) => {
        let elementArray = [];
        for (const [yearKey, value] of Object.entries(organizedData)) {
            let arrayOfMonths = [];
            for (const [monthKey, monthValue] of Object.entries(value)) {
                const monthlyAverage = getAverageOfAMonth(monthValue)
                const monthEle = <div key={monthKey}>{monthKey}: {monthlyAverage}</div>
                arrayOfMonths.push(monthEle);
            }

            const yearDataElement = <div key={yearKey}>
                <h2>{yearKey}</h2>
                {arrayOfMonths}
            </div>

            elementArray.push(yearDataElement);
        }
        return elementArray
    }

    React.useEffect(() => {
        getCurrentUserDailyLogs();
    }, [])

    React.useEffect(() => {
        console.log(JSON.stringify(organizeDataByMonth(dailyScores), null, 2));
    }, [dailyScores])

    return(
        <section className={classes.dailyLogsWrapper}>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Date of Log</TableCell>
                        <TableCell>Score</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {dailyScores.map((row, index) => (
                        <TableRow key={index}>
                        <TableCell component="th" scope="row">
                            {new Date(row.timestamp.seconds * 1000).toString()}
                        </TableCell>
                        <TableCell>{row.dailyStatus}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div>
                Seven day average score: {getSevenDayAverage(dailyScores)}
            </div>
            {scoreByMonth(organizeDataByMonth(dailyScores))}
        </section>

    );
}

export default DailyLogs;