import React, { useState, useEffect } from 'react';
import useFetch from '../../hooks/useFetch.js';
import useStyles from './styles';
import Button from '@material-ui/core/Button';
import { useLocation, withRouter } from 'react-router-dom';
import CreateReply from '../../components/Organism/CreateReply';
import InboxParentPost from '../../components/Molecule/InboxParentPost.js';
import InboxRepliesList from '../../components/Molecule/InboxRepliesList.js';

const InboxItemPage = (props) => {
    const classes = useStyles()

    const [privateRepliesData, setPrivateRepliesData] = useState([]);
    const [doc, setDoc] = useState();
    const [replyDialog, setReplyDialog] = useState(false);

    const postUrlPath = useLocation().pathname.replace("/inbox", "")
        .replace("/coordinator_inbox", "").replace("/post/", "");
    // when a reply is created, we change the state so that useEffect will trigger and rerender
    const [refreshPage, setRefreshPage] = useState(false);

    const [fetchPostArgs, setPostFetchArgs] = useState({});
    const { status: dbPostsStatus, data: dbPostsData } = useFetch(fetchPostArgs);

    const [fetchRepliesArgs, setRepliesFetchArgs] = useState({});
    const { status: dbRepliesStatus, data: dbRepliesData } = useFetch(fetchRepliesArgs);

    // function for retriving private post doc from firebase
    const getDoc = () => {
        if (!doc && dbPostsStatus === "completed") {
            // if you go to a url where a post doesn't exist, send you back to the front page.
            if (dbPostsData.body) {
                setDoc(dbPostsData.body);
                setPostFetchArgs({});
            } else {
                props.history.push("/");
            }
        }
    };

    // function for retrieving all private replies for a private post
    const getPrivateReplies = () => {
        if (dbRepliesStatus === "completed") {
            setPrivateRepliesData(dbRepliesData);
            setRepliesFetchArgs({});
        }
    };

    const createReply = () => {
        if (doc) {
            return (
                <div className={classes.createReplyButton}>
                    <Button className={classes.replyButton}
                        onClick={() => { setReplyDialog(true); }}>
                        Write a reply!
                    </Button>
                    <CreateReply
                        user={props.user}
                        type="private-replies"
                        userType={doc.coordinatorId === props.user.userId ? "admin" : "user"}
                        caseAdminID={doc.coordinatorId}
                        open={replyDialog}
                        parentPostId={doc.postId}
                        setClosed={() => { setReplyDialog(false) }}
                        refreshPage={refreshPage}
                        setRefreshPage={setRefreshPage}
                        parentReply={null}
                    />
                </div>
            )
        }
    };

    useEffect(() => {
        setPostFetchArgs({
            URLquery: '/db/privatePosts/get/single',
            body: {
                postUrlPath: postUrlPath,
                userId: props.user.userId
            }
        });
        setRepliesFetchArgs({
            URLquery: '/db/replies/get/privatePostReplies',
            body: {
                postUrlPath: postUrlPath,
                userId: props.user.userId
            }
        });
    }, [refreshPage]);

    useEffect(() => {
        getDoc();
        getPrivateReplies();
    }, [dbPostsStatus, dbRepliesStatus]);

    return (
        <div className={classes.container}>
            <div className={classes.inboxItemsWrapper}>
                <InboxParentPost
                    user={props.user}
                    mainPostDoc={doc}
                    navStatus={props.navStatus}
                    mobileStatus={props.mobileStatus}
                />
                <InboxRepliesList
                    user={props.user}
                    mainPostDoc={doc}
                    privateRepliesData={privateRepliesData}
                />
            </div>
            {createReply()}
        </div>
    )
};

export default withRouter(InboxItemPage);